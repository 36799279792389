import { useContext, useState } from 'react';
import { GlobalContext } from '../../../context/GlobalContext';
import { Campaign } from '../../../data/campaign';
import { MessageStatus } from '../../../data/notify-options';
import { useNotification } from '../../../hooks/use-notification';
import { campaignService } from '../../../services/api-client/campaign-service';
import { ssPartnerService } from '../../../services/api-client/ss-partner-service';

export const useCampaignIntegration = (): [
  boolean,
  () => void,
  boolean,
  (campaign: Campaign) => Promise<void>,
] => {
  const notification = useNotification();
  const {
    selectedOrganization,
    campaignOptionsTrigger,
    setCampaignOptionsTrigger,
    setAddingCampaign,
    selectedCampaignData,
    setSelectedCampaignData,
  } = useContext(GlobalContext);
  const [savingCampaign, setSavingCampaign] = useState<boolean>(false);
  const [addingDraft, setAddingDraft] = useState<boolean>(false);

  const handelAddNewDraft = async () => {
    try {
      setAddingDraft(true);
      setAddingCampaign(true);
      if (!selectedOrganization) {
        return;
      }
      await ssPartnerService.addDraftCampaign(selectedOrganization.partnerSlug);
    } catch (error: any) {
      notification.notifyApiError({
        status: MessageStatus.Error,
        title: 'Error',
        description: 'Error adding new draft',
        response: error.response,
      });
    } finally {
      setAddingDraft(false);
      setAddingCampaign(false);
      setCampaignOptionsTrigger(!campaignOptionsTrigger);
    }
  };

  const handleCampaignSave = async (campaign: Campaign) => {
    if (!selectedCampaignData || !selectedOrganization) {
      return;
    }
    try {
      setSavingCampaign(true);
      await campaignService.edit(campaign);
      notification.notify(
        MessageStatus.Success,
        'Success',
        'Campaign edited successfully'
      );
      setSelectedCampaignData(campaign);
      setCampaignOptionsTrigger(!campaignOptionsTrigger);
    } catch (error: any) {
      notification.notifyApiError({
        status: MessageStatus.Error,
        title: 'Game Error',
        description: 'An error occurred while saving the game',
        response: error.response,
      });
    } finally {
      setSavingCampaign(false);
    }
  };
  return [addingDraft, handelAddNewDraft, savingCampaign, handleCampaignSave];
};

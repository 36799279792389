import { Accordion, Flex, Loader, Switch } from '@mantine/core';
import { FunctionComponent } from 'react';
import { BiDotsHorizontalRounded } from 'react-icons/bi';
import { FaGear } from 'react-icons/fa6';
import { Feature } from '../../../../data/features';
import { FeaturesConfigurationProps } from './data/feature-configuration-props';
import { configurationElementsByFeature } from './data/feature-configuration-registry';

const FeaturesConfiguration: FunctionComponent<
  FeaturesConfigurationProps
> = props => {
  const { featureData, onFeatureActivationChange, featuresLoadingStates } =
    props;

  const getFeatureSwitch = (featureElement: Feature) => {
    const loadingState = featuresLoadingStates?.find(
      state => state.feature === featureElement.feature
    );
    if (loadingState?.loading) {
      return <Loader size={'xs'} />;
    }
    return (
      <Switch
        checked={featureData?.enabled_features.includes(featureElement.feature)}
        onChange={event =>
          onFeatureActivationChange?.(
            featureElement.feature,
            event.currentTarget.checked
          )
        }
      />
    );
  };

  const getAccordionElements = () => {
    if (!featureData) {
      return null;
    }
    return featureData.available_features.map((featureElement, index) => {
      return (
        <Accordion.Item
          key={featureElement.feature}
          value={featureElement.feature}
        >
          <Accordion.Control
            icon={<FaGear />}
            chevron={
              !featureElement.permission.configurable ||
              !configurationElementsByFeature.has(featureElement.feature)
            }
          >
            <Flex direction={'row'} justify={'space-between'} align={'center'}>
              {featureElement.feature}
              <Flex style={{ cursor: 'pointer' }} align={'center'}>
                {getFeatureSwitch(featureElement)}
              </Flex>
            </Flex>
          </Accordion.Control>
          {featureElement.permission.configurable &&
            configurationElementsByFeature.has(featureElement.feature) && (
              <Accordion.Panel>
                {configurationElementsByFeature.get(featureElement.feature)?.(
                  props,
                  featureElement.feature
                )}
              </Accordion.Panel>
            )}
        </Accordion.Item>
      );
    });
  };
  return (
    <Flex gap={'1rem'} p={'1rem'} direction={'column'} w={'100%'}>
      <Accordion
        chevronPosition="right"
        variant="contained"
        chevronSize={30}
        chevron={<BiDotsHorizontalRounded size={30} color="gray" />}
      >
        {getAccordionElements()}
      </Accordion>
    </Flex>
  );
};

export default FeaturesConfiguration;

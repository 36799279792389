import * as Frigade from '@frigade/react';
import { useMediaQuery } from '@mantine/hooks';
import { StrictMode, useContext, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { SideBar } from '../../components/SideBar/SideBar';
import { ToolBar } from '../../components/ToolBar/ToolBar';
import { configuration } from '../../configuration';
import { GlobalContext } from '../../context/GlobalContext';
import { CampaignOption } from '../../data/campaign-option';
import { Path } from '../../data/paths';
import { staffCheck } from '../../helper/feature-flags';
import { getDefaultLinks } from '../../helper/navigation-link';
import { useTier } from '../../hooks/use-tier';
import { authentication } from '../../services/authentication';
import { localStorageService } from '../../services/local-storage-service';
import { Layout } from '../Layout/Layout';
import { useAddDraftCampaignIntegration } from './hooks/use-add-draft-campaign-integration';
import { useCampaignPublishIntegration } from './hooks/use-campaign-publish-integration';
import { useDeleteDraftCampaign } from './hooks/use-delete-draft-campaign-integration';
import { useListStatusChangeIntegration } from './hooks/use-list-status-change-integration';
import { useLiveStatusChanceIntegration } from './hooks/use-live-status-chance-integration';
export const MainLayout = () => {
  const navigate = useNavigate();
  const user = authentication.getUser();
  const [handleOnCampaignPublished, publishDialogOpened] =
    useCampaignPublishIntegration();
  const [handleCampaignDelete, deletingCampaign] = useDeleteDraftCampaign();
  const [handelAddNewDraft, addingDraft, isAddingCampaignModalOpen] =
    useAddDraftCampaignIntegration();
  const [handleOnLiveToggleChange, unPublishing] =
    useLiveStatusChanceIntegration();
  const [handleConfirmStatusChange, changingCampaignStatus] =
    useListStatusChangeIntegration();
  const {
    campaignOptions,
    organizations,
    loadingCampaignsOptions,
    setSelectCampaignOption,
    selectedCampaignOption,
    addingCampaign,
    campaignPublishStatus,
    publishingCampaign,
    selectedOrganization,
    setSelectedOrganization,
    loadingSelectedCampaignData,
    selectedCampaignData,
  } = useContext(GlobalContext);

  const [{ isFreeTier, component, isClose, setIsClose }] = useTier();
  const [localCampaignOptions, setLocalCampaignOptions] = useState<
    CampaignOption[]
  >([]);

  useEffect(() => {
    setLocalCampaignOptions(campaignOptions ?? []);
  }, [campaignOptions]);

  const navigation = useNavigate();
  const location = useLocation();
  const [pathname, setPathname] = useState<string>(
    localStorageService.getActualPage() ?? ''
  );
  const tablet = useMediaQuery('(max-width: 51.25rem)');
  const mobile = useMediaQuery('(max-width: 25.875rem)');
  const [expanded, setExpanded] = useState<boolean>(tablet ? false : true);
  const [hidden, setHidden] = useState<boolean>(mobile ? true : false);
  useEffect(() => {
    setExpanded(!tablet);
    setHidden(!!mobile);
  }, [tablet, mobile]);

  useEffect(() => {
    setPathname(localStorageService.getActualPage() ?? '');
  }, [location]);

  const handleOrganizationChange = (partner: string | null) => {
    if (!partner) {
      return;
    }
    const tempSelectedOrganization = organizations?.find(
      o => o.partner === partner
    );
    if (!tempSelectedOrganization) {
      return;
    }
    setSelectedOrganization(tempSelectedOrganization);
    localStorageService.setSelectedOrganization(tempSelectedOrganization);
  };

  const handleTierClick = () => {
    if (selectedOrganization?.tier === 'free') {
      setIsClose(false);
    }
  };

  return (
    <Frigade.Provider
      groupId={user?.sub as string}
      groupProperties={{
        name: user?.sub as string,
      }}
      apiKey={configuration.frigade.apiKey}
      userId={user?.sub as string}
    >
      <StrictMode>
        <Layout
          variant="FullSidebar"
          sidebarExpanded={expanded}
          sidebarHidden={hidden}
          isControlledSidebar
          sideBar={
            <SideBar
              onLogout={() => authentication.logout('logout')}
              onAvatarClick={() =>
                navigate(`${Path.Game}?section=assets`, { replace: true })
              }
              partnerIcon={selectedCampaignData?.extra_data.icon}
              partnerTitle={selectedOrganization?.partner ?? ''}
              membership={selectedOrganization?.tier ?? ''}
              links={getDefaultLinks()}
              expanded={expanded}
              defaultItemSlug={pathname.split('/')[1]}
              onMenuClick={() => setExpanded(false)}
              onItemSelected={slug => {
                navigation(slug);
              }}
              onTierClick={handleTierClick}
              user={user}
            />
          }
          toolBar={
            <ToolBar
              campaignPublishStatus={campaignPublishStatus}
              selectedOrganization={selectedOrganization}
              organizations={organizations ?? []}
              onOrganizationChange={partnerSlug => {
                handleOrganizationChange(partnerSlug);
              }}
              handleCampaignDelete={handleCampaignDelete}
              deletingCampaign={deletingCampaign}
              expanded={expanded}
              onMenuClick={() => setExpanded(true)}
              name={user?.name}
              profileImage={user?.picture}
              campaignOptions={localCampaignOptions}
              onAddCampaign={cloneOption => handelAddNewDraft(cloneOption)}
              addingCampaign={addingDraft || addingCampaign}
              loadingCampaignsOptions={loadingCampaignsOptions}
              onCampaignSelection={setSelectCampaignOption}
              selectedCampaignOption={selectedCampaignOption}
              publishingCampaign={publishingCampaign}
              onPublish={handleOnCampaignPublished}
              loadingCampaignData={loadingSelectedCampaignData}
              selectedCampaignData={selectedCampaignData}
              publishDialogOpened={publishDialogOpened}
              isAddingCampaignModalOpen={isAddingCampaignModalOpen}
              onClickNavigateToGamePage={() => {
                navigation(Path.Game);
              }}
              campaignEndDatetime={selectedCampaignData?.end_datetime?.toString()}
              handleOnLiveToggleChange={handleOnLiveToggleChange}
              unPublishing={unPublishing}
              onConfirmStatusChange={handleConfirmStatusChange}
              changingCampaignStatus={changingCampaignStatus}
              isStaffUser={staffCheck()}
            />
          }
        >
          {isFreeTier && !isClose && component}
          <Outlet />
        </Layout>
      </StrictMode>
    </Frigade.Provider>
  );
};

import { useContext, useState } from 'react';
import { GlobalContext } from '../../../context/GlobalContext';
import { CampaignAssetModel } from '../../../data/campaign-asset-model';
import { MessageStatus } from '../../../data/notify-options';
import { useNotification } from '../../../hooks/use-notification';
import { usePublishStatus } from '../../../hooks/use-publishing-status';
import { campaignService } from '../../../services/api-client/campaign-service';

export const useAssetsIntegration = (): [
  boolean,
  (assets: CampaignAssetModel) => void,
] => {
  const notification = useNotification();
  const {
    selectedCampaignData,
    campaignOptionsTrigger,
    setCampaignOptionsTrigger,
  } = useContext(GlobalContext);
  const [savingAssets, setSavingAssets] = useState<boolean>(false);
  const [activatePublishIfNoErrors] = usePublishStatus();
  const handelAssetsSave = async (assets: CampaignAssetModel) => {
    if (!selectedCampaignData) {
      return;
    }
    try {
      setSavingAssets(true);
      await saveAssets(assets);
      notification.notify(
        MessageStatus.Success,
        'Success',
        'Assets saved successfully'
      );
      activatePublishIfNoErrors();
      setCampaignOptionsTrigger(!campaignOptionsTrigger);
    } catch (error: any) {
      notification.notifyApiError({
        status: MessageStatus.Error,
        title: 'Assets Error',
        description: 'An error occurred while saving the assets',
        response: error.response,
      });
    } finally {
      setSavingAssets(false);
    }
  };

  const saveAssets = async (assets: CampaignAssetModel) => {
    const assetsPromises = [
      campaignService.saveScreenShotsAssets(
        assets,
        selectedCampaignData!.campaign_id
      ),
      campaignService.saveIconAsset(assets, selectedCampaignData!.campaign_id),
      campaignService.saveBackgroundAsset(
        assets,
        selectedCampaignData!.campaign_id
      ),
      campaignService.savePoster(assets, selectedCampaignData!.campaign_id),
      campaignService.saveBannerAsset(
        assets,
        selectedCampaignData!.campaign_id
      ),
      campaignService.saveWeekQuestsBgAsset(
        assets,
        selectedCampaignData!.campaign_id
      ),
    ];
    await Promise.all(assetsPromises);
  };

  return [savingAssets, handelAssetsSave];
};

import { NotificationService } from '../services/notification-service';
import { MessageStatus } from './notify-options';

export const notImplementedFeature = (notification: NotificationService) => {
  notification.notify(
    MessageStatus.Info,
    'Avatar removed',
    'Feature not implemented yet'
  );
};

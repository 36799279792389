import {
  Card,
  Divider,
  Flex,
  Loader,
  Switch,
  Text,
  Title,
  Tooltip,
} from '@mantine/core';
import { useContext, useState } from 'react';
import { GlobalContext } from '../../context/GlobalContext';
import { MessageStatus } from '../../data/notify-options';
import { useNotification } from '../../hooks/use-notification';
import { ssPartnerService } from '../../services/api-client/ss-partner-service';
import { withAuthProtection } from '../../services/protect-route-element';

const Page = () => {
  const notification = useNotification();
  const {
    selectedCampaignOption,
    selectedOrganization,
    setCampaignOptionsTrigger,
    campaignOptionsTrigger,
  } = useContext(GlobalContext);

  const [handlingActivation, setHandlingActivation] = useState(false);

  const handelActivation = async (active: boolean) => {
    if (!selectedCampaignOption || !selectedOrganization) {
      return;
    }
    try {
      setHandlingActivation(true);
      if (active) {
        await ssPartnerService.markActive(
          selectedOrganization.partnerSlug,
          selectedCampaignOption.draft_campaign_id
        );
      } else {
        await ssPartnerService.markInactive(
          selectedOrganization.partnerSlug,
          selectedCampaignOption.draft_campaign_id
        );
      }
      setCampaignOptionsTrigger(!campaignOptionsTrigger);
    } catch (error: any) {
      notification.notifyApiError({
        status: MessageStatus.Error,
        title: 'Error',
        description: 'Error handling activation',
        response: error.response,
      });
    } finally {
      setHandlingActivation(false);
    }
  };

  return (
    <Flex p={'1rem'} gap={'1rem'} direction={'column'}>
      <Title order={2}>Staff Page</Title>
      <Divider />
      <Flex p={'1rem'} gap={'1rem'} direction={'column'}>
        <Card withBorder={true} shadow="sm">
          <Flex direction={'column'}>
            <Flex p={'0.5rem'} justify={'space-between'} align={'center'}>
              <Text>Game status</Text>

              <Tooltip
                label={selectedCampaignOption?.active ? 'Active' : 'Inactive'}
              >
                <Flex>
                  {handlingActivation ? (
                    <Loader size={'sm'} />
                  ) : (
                    <Switch
                      checked={selectedCampaignOption?.active}
                      onChange={value =>
                        handelActivation(value.currentTarget.checked)
                      }
                    />
                  )}
                </Flex>
              </Tooltip>
            </Flex>
            <Divider />
            <Text p={'0.5rem'} size="xs">
              This option will allow you to activate or inactivate the game.
              Make sure the game is ready before activating it.
            </Text>
          </Flex>
        </Card>
      </Flex>
    </Flex>
  );
};

export const StaffPage = withAuthProtection(Page);

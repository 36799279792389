import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useContext, useState } from 'react';
import { GlobalContext } from '../../../context/GlobalContext';
import { FeatureFlags } from '../../../data/feature-flags';
import { MessageStatus } from '../../../data/notify-options';
import { SocialMultiplier } from '../../../data/social-multiplier';
import { useDataFetcher } from '../../../hooks/use-data-fetcher';
import { useNotification } from '../../../hooks/use-notification';
import { usePublishStatus } from '../../../hooks/use-publishing-status';
import { ssPartnerService } from '../../../services/api-client/ss-partner-service';

export const useSocialMultiplierIntegration = (): [
  SocialMultiplier | undefined,
  boolean,
  boolean,
  (socialMultiplier: SocialMultiplier) => Promise<void>,
] => {
  const notification = useNotification();
  const {
    selectedOrganization,
    selectedCampaignData,
    selectedCampaignDataTrigger,
  } = useContext(GlobalContext);
  const [activatePublishIfNoErrors] = usePublishStatus();
  const [savingSocialMultiplier, setSavingSocialMultiplier] =
    useState<boolean>(false);

  const isFeatureConfigActive = useFeatureFlagEnabled(
    FeatureFlags.ShowFeaturesSection
  );

  const [socialMultiplier, loadingSocialMultiplier] = useDataFetcher({
    serviceCall() {
      if (selectedOrganization && selectedCampaignData) {
        return ssPartnerService.getSocialMultiplier(
          selectedOrganization.partnerSlug,
          selectedCampaignData.campaign_id
        );
      }
    },
    dependencies: [selectedCampaignData, selectedCampaignDataTrigger],
    conditionForExecution: isFeatureConfigActive,
  });

  const handleSocialMultiplierSave = async (
    socialMultiplier: SocialMultiplier
  ) => {
    if (!selectedCampaignData || !selectedOrganization) {
      return;
    }
    try {
      setSavingSocialMultiplier(true);
      await ssPartnerService.saveSocialMultiplier(
        socialMultiplier,
        selectedCampaignData.campaign_id,
        selectedOrganization.partnerSlug
      );
      activatePublishIfNoErrors();
    } catch (error: any) {
      notification.notifyApiError({
        status: MessageStatus.Error,
        title: 'Social Multiplier Error',
        description: 'An error occurred while saving the social multiplier',
        response: error.response,
      });
    } finally {
      setSavingSocialMultiplier(false);
    }
  };

  return [
    socialMultiplier,
    loadingSocialMultiplier,
    savingSocialMultiplier,
    handleSocialMultiplierSave,
  ];
};

import {
  Avatar,
  Card,
  Flex,
  FlexProps,
  Image,
  MantineStyleProp,
  Text,
  Tooltip,
} from '@mantine/core';
import { useHover } from '@mantine/hooks';
import { IconEdit, IconFaceIdError } from '@tabler/icons-react';
import { FunctionComponent, useEffect, useState } from 'react';
import { BiUser } from 'react-icons/bi';
import { MdLogout } from 'react-icons/md';
import { NavbarLink, NavLink } from './components/NavbarLink/NavbarLink';
import './SideBar.scss';

export interface SideBarProps {
  onLogout?: () => void;
  partnerTitle: string;
  partnerIcon?: string;
  membership: string;
  links: NavLink[];
  defaultItemSlug?: string;
  selectedStyles?: MantineStyleProp;
  expanded?: boolean;
  onItemSelected?: (slug: string) => void;
  onMenuClick?: () => void;
  onTierClick?: () => void;
  onAvatarClick?: () => void;
  style?: FlexProps;
  user?: auth0.Auth0UserProfile | null;
}

export const SideBar: FunctionComponent<SideBarProps> = props => {
  const { hovered, ref } = useHover();
  const [activeLink, setActiveLink] = useState<string>(
    props.defaultItemSlug ?? ''
  );

  useEffect(() => {
    setActiveLink(props.defaultItemSlug ?? '');
  }, [props.defaultItemSlug]);

  const handleClick = (slug: string) => {
    setActiveLink(slug);
    props.onItemSelected?.(slug);
  };

  return (
    <Flex
      classNames={{ root: 'SideBar' }}
      direction={'column'}
      w={'100%'}
      h={'100%'}
      justify={'space-between'}
      color={'#fff'}
      bg={'#f1f5f9'}
      fz={'1.5rem'}
      pt={'sm'}
      {...props.style}
    >
      <Flex
        direction={'column'}
        w={'100%'}
        color={'#fff'}
        fz={'1.5rem'}
        pl={'0.75rem'}
        pr={'0.75rem'}
        {...props.style}
      >
        <Card
          bd={'1px solid #E4E7EC'}
          radius={'0.75rem'}
          mah={'5rem'}
          bg={'#FFF'}
        >
          <Flex direction={'row'} gap={'1rem'} align={'center'}>
            <Tooltip label={props.partnerTitle} openDelay={1000}>
              <Flex direction={'column'} gap={'0.1rem'} align={'center'}>
                <Avatar ref={ref} h={'2.25rem'} w={'2.25rem'}>
                  {hovered ? (
                    <IconEdit
                      cursor={'pointer'}
                      onClick={() => props.onAvatarClick?.()}
                    />
                  ) : props.partnerIcon ? (
                    <Image src={props.partnerIcon} />
                  ) : (
                    <IconFaceIdError size="1.5rem" />
                  )}
                </Avatar>
                <Text size="xs" c={'#6085A7'}>
                  {props.membership.charAt(0).toUpperCase() +
                    props.membership.slice(1)}
                </Text>
              </Flex>
            </Tooltip>
            <Tooltip label={props.partnerTitle} openDelay={1000}>
              <Text lineClamp={2}>{props.partnerTitle}</Text>
            </Tooltip>
          </Flex>
        </Card>
        <Flex direction={'column'}>
          {props.links?.map(link => {
            {
              return (
                <NavbarLink
                  link={link}
                  key={link.slug}
                  disabled={link.disable}
                  active={link.slug === `/${activeLink}`}
                  expanded={props.expanded}
                  onClick={() => handleClick(link.slug)}
                />
              );
            }
          })}
        </Flex>
      </Flex>
      <Flex p={'12px'} gap={'0.3rem'} align={'center'} w={'100%'}>
        <Avatar
          variant="filled"
          size={'2.5rem'}
          color="#1F374E"
          alt={props.user?.name}
          src={props.user?.picture}
        >
          <BiUser size={'1.5rem'} />
        </Avatar>
        <Flex
          direction={'row'}
          align={'center'}
          w={'100%'}
          justify={'space-between'}
        >
          <Flex direction={'column'}>
            <Text fw={500}>
              {props.user?.nickname
                ?.split(' ')
                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ')}
            </Text>
            <Text size="xs" c={'#6085A7'}>
              {props.user?.email}
            </Text>
          </Flex>
          <MdLogout
            onClick={() => props.onLogout?.()}
            cursor={'pointer'}
            size={'1.5rem'}
            color="#6085A7"
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

import { Button, Flex, TextInput } from '@mantine/core';
import { FunctionComponent, useEffect, useState } from 'react';
import { DailyStreak } from '../../../../../data/daily-streak';

export interface DailyStreakSectionProps {
  defaultValues?: DailyStreak;
  enableConfiguration: boolean;
  onSave: (dailyStreak: DailyStreak) => void;
  savingDailyStreak: boolean;
}

const DailyStreakSection: FunctionComponent<
  DailyStreakSectionProps
> = props => {
  const { defaultValues, enableConfiguration, onSave, savingDailyStreak } =
    props;
  const [description, setDescription] = useState(
    defaultValues?.description || ''
  );
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);

  useEffect(() => {
    setDescription(defaultValues?.description || '');
  }, [defaultValues]);

  useEffect(() => {
    setIsSaveDisabled(
      description.trim() === '' ||
        description === defaultValues?.description ||
        description.length < 10
    );
  }, [description]);

  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDescription(event.target.value);
  };

  const handleSave = () => {
    onSave({ description });
    setIsSaveDisabled(true);
  };

  return (
    <Flex direction="column" gap="md">
      <TextInput
        description="10 characters minimum"
        label="Description"
        value={description}
        onChange={handleDescriptionChange}
        disabled={!enableConfiguration}
      />
      <Button
        onClick={handleSave}
        loading={savingDailyStreak}
        disabled={isSaveDisabled}
      >
        Save
      </Button>
    </Flex>
  );
};

export default DailyStreakSection;

import { Checkbox, Flex } from '@chakra-ui/react';
import {
  ActionIcon,
  Button,
  Image,
  Modal,
  Text,
  Title,
  Tooltip,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { FunctionComponent, useEffect, useState } from 'react';
import { GrAdd } from 'react-icons/gr';
import { LocalImages } from '../../../assets/images';
import { CampaignOption } from '../../../data/campaign-option';

const maxElementsValue = 2;

export interface AddCampaignDialogProps {
  opened?: boolean;
  isLoading?: boolean;
  onConfirm: (cloneOption: boolean) => void;
  campaignOptions: CampaignOption[];
}

const AddCampaignDialog: FunctionComponent<AddCampaignDialogProps> = props => {
  const { campaignOptions, onConfirm, isLoading } = props;
  const [cloneOption, setCloneOption] = useState(false);
  const [opened, { open, close }] = useDisclosure(props.opened);

  useEffect(() => {
    if (props.opened) {
      open();
    } else {
      close();
    }
  }, [props.opened]);

  const isAddingNewDraftDisabled = () => {
    if (campaignOptions.length >= maxElementsValue) {
      return true;
    }
    if (isLoading) {
      return true;
    }
    if (campaignOptions.length === 1) {
      if (!campaignOptions[0].published) {
        return true;
      }
    }
    return false;
  };

  const addCampaignButtonToolTip = () => {
    if (campaignOptions.length === 1) {
      if (!campaignOptions[0].published) {
        return 'You can only have one draft campaign at a time. Publish the current draft campaign to add a new one.';
      }
    }
    if (campaignOptions.length >= maxElementsValue) {
      return 'You can only have two campaigns at a time. One draft and one published. Publish the current draft campaign to add a new one.';
    }
    return 'Add new draft campaign';
  };

  if (!campaignOptions || campaignOptions.length === 0) {
    return (
      <Button
        loading={isLoading}
        disabled={isAddingNewDraftDisabled()}
        onClick={() => onConfirm?.(false)}
      >
        Add new draft campaign
      </Button>
    );
  }

  const getModalContent = () => {
    return (
      <Flex direction={'column'} gap={'1rem'}>
        <Title order={3}>Adding new draft camping</Title>
        <Flex direction={'column'} gap={'0.5rem'}>
          <Text c={'gray'}>
            At the moment of adding a new draft campaign you can shoes to clone
            the current campaign or start from scratch.
          </Text>
          <Checkbox
            checked={cloneOption}
            onChange={e => setCloneOption(e.currentTarget.checked)}
          >
            Clone the current campaign
          </Checkbox>
        </Flex>
        <Flex gap={'1rem'} w={'100%'} justify={'end'}>
          <Button onClick={close}>Cancel</Button>
          <Button onClick={() => onConfirm?.(cloneOption)} loading={isLoading}>
            Add Campaign
          </Button>
        </Flex>
      </Flex>
    );
  };

  return (
    <>
      <Modal
        title={<Image w={'8rem'} src={LocalImages.logo} />}
        zIndex={600}
        opened={opened}
        onClose={close}
        centered
      >
        {getModalContent()}
      </Modal>
      <Tooltip
        maw={'20rem'}
        multiline
        label={addCampaignButtonToolTip()}
        openDelay={500}
      >
        <ActionIcon
          onClick={open}
          disabled={isAddingNewDraftDisabled()}
          loading={isLoading}
        >
          <GrAdd />
        </ActionIcon>
      </Tooltip>
    </>
  );
};

export default AddCampaignDialog;

import { Button, Flex, Text, Title } from '@mantine/core';
import { FunctionComponent, useState } from 'react';
import { Campaign } from '../../../data/campaign';
import { CampaignAssetModel } from '../../../data/campaign-asset-model';
import ImageUploader from '../../Images/ImageUploader/ImageUploader';
import { ScreenshotsForm } from './ScreenshotsForm';

export interface CommonAssetsFormProps {
  campaign: Campaign;
  onSave: (assets: CampaignAssetModel) => void;
  savingCampaignAssets?: boolean;
}

export const CommonAssetsForm: FunctionComponent<
  CommonAssetsFormProps
> = props => {
  const { savingCampaignAssets, onSave } = props;
  const [campaignAssets, setCampaignAssets] = useState<CampaignAssetModel>({
    background: props.campaign.extra_data?.background,
    icon: props.campaign.extra_data?.icon,
    banner: props.campaign.extra_data?.banner,
    week_quests_bg: props.campaign.extra_data?.week_quests_bg,
    poster: props.campaign.extra_data?.poster,
    screenshots: props.campaign.extra_data?.screenshots,
  });

  const [hasChanges, setHasChanges] = useState(false);

  const handleImageChange = (
    key: keyof CampaignAssetModel,
    image: File | File[]
  ) => {
    const updatedAssets = { ...campaignAssets, [key]: image };
    setCampaignAssets(updatedAssets);
    setHasChanges(true);
  };

  const handleAssetRemove = (key: keyof CampaignAssetModel) => {
    if (key === 'screenshots') {
      throw new Error('Cannot remove screenshots this way');
    }
    const updatedAssets = { ...campaignAssets, [key]: 'removed' };
    setCampaignAssets(updatedAssets);
  };

  const getTitleWithDescription = (
    title: string,
    description: string,
    errorOnDelete?: string,
    isOptional?: boolean
  ) => {
    return (
      <Flex direction={'column'} justify={'center'} gap={'0.15rem'}>
        <Flex gap={'0.2rem'} align={'center'}>
          <Title order={6}>{title}</Title>
          {!isOptional && <Text c={'red'}>*</Text>}
        </Flex>
        {errorOnDelete && (
          <Text size={'xs'} c={'red'}>
            {errorOnDelete}
          </Text>
        )}
        <Text c={'#868e96'} size="xs">
          {description}
        </Text>
      </Flex>
    );
  };

  return (
    <Flex gap={'1rem'} p={'1rem'} direction={'column'} w={'100%'}>
      <Button
        onClick={() => onSave(campaignAssets)}
        disabled={!hasChanges}
        loading={savingCampaignAssets}
      >
        Save
      </Button>
      <Flex
        gap={'2rem'}
        direction={'column'}
        justify={'space-between'}
        w={'100%'}
      >
        <Flex direction={'column'} gap={'1rem'}>
          {getTitleWithDescription(
            'Icon',
            'Upload a 120 x 120 png with transparent background'
          )}
          <ImageUploader
            internalKey="icon"
            allowMultipleImages={false}
            actualImage={campaignAssets.icon ?? props.campaign.extra_data?.icon}
            expectedDimensions="120x120"
            onImageChange={images => handleImageChange('icon', images[0])}
            style={{ width: '8.25rem' }}
          />
        </Flex>
        <Flex direction={'column'} gap={'1rem'} w={'100%'}>
          {getTitleWithDescription(
            'Background',
            'Upload a 1440 x 900 background image with no text or logos'
          )}
          <ImageUploader
            internalKey="background"
            withRoundedBorders={true}
            allowMultipleImages={false}
            expectedDimensions="1440x900"
            actualImage={
              campaignAssets.background ?? props.campaign.extra_data?.background
            }
            onImageChange={images => handleImageChange('background', images[0])}
          />
        </Flex>
        <Flex direction={'column'} gap={'1rem'} w={'100%'}>
          {getTitleWithDescription(
            'Box Art',
            'Upload a 300 x 390 image of your game’s box art'
          )}
          <ImageUploader
            internalKey="poster"
            withRoundedBorders={true}
            allowMultipleImages={false}
            expectedDimensions="300x390"
            actualImage={
              campaignAssets.poster ?? props.campaign.extra_data?.poster
            }
            onImageChange={images => handleImageChange('poster', images[0])}
          />
        </Flex>

        <Flex direction={'column'} gap={'1rem'} w={'100%'}>
          {getTitleWithDescription(
            'Banner',
            'Upload a 1280 x 200 image',
            (!campaignAssets.banner && !props.campaign.extra_data?.banner) ||
              campaignAssets.banner === 'removed'
              ? 'Removing or not setting this asset will have an impact to the campaign page layout.'
              : '',
            true
          )}
          <ImageUploader
            internalKey="banner"
            withRoundedBorders={true}
            allowMultipleImages={false}
            withCloseButton={true}
            onRemoveButtonClicked={() => handleAssetRemove('banner')}
            expectedDimensions="1280x200"
            actualImage={
              campaignAssets.banner ?? props.campaign.extra_data?.banner
            }
            onImageChange={images => handleImageChange('banner', images[0])}
          />
        </Flex>
        <Flex direction={'column'} gap={'1rem'} w={'100%'}>
          {getTitleWithDescription(
            'User Points Background',
            'Upload a 520 x 120 image'
          )}
          <ImageUploader
            internalKey="week_quests_bg"
            withRoundedBorders={true}
            allowMultipleImages={false}
            expectedDimensions="520x120"
            actualImage={
              campaignAssets.week_quests_bg ??
              props.campaign.extra_data?.week_quests_bg
            }
            onImageChange={images =>
              handleImageChange('week_quests_bg', images[0])
            }
          />
        </Flex>
        <ScreenshotsForm
          campaignAssets={campaignAssets}
          onSaved={(assets: CampaignAssetModel) => {
            handleImageChange('screenshots', assets.screenshots as File[]);
          }}
        />
      </Flex>
      <Button
        onClick={() => onSave(campaignAssets)}
        disabled={!hasChanges}
        loading={savingCampaignAssets}
      >
        Save
      </Button>
    </Flex>
  );
};

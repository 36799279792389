import { Button, Flex, NumberInput, Switch } from '@mantine/core';
import { DateInput, DateValue } from '@mantine/dates';
import { FunctionComponent } from 'react';
import { getUTCDate } from '../../../helper/dates';

export interface QuestCommonFieldsProps {
  questId: string;
  points: number;
  startDatetime: Date;
  isDateEditable: boolean;
  enabled: boolean;
  tier: string;
  isForgeStaff?: boolean;
  isAddition: boolean;
  onPointsChanges?: (value: number) => void;
  onStartDateChanges?: (value: DateValue) => void;
  onHiddenChanges?: (value: boolean) => void;
  onEnabledChanges?: (value: boolean) => void;
  onUpsellClick?: () => void;
}

export const QuestCommonFields: FunctionComponent<
  QuestCommonFieldsProps
> = props => {
  const { questId, enabled, points, startDatetime, isAddition } = props;
  const isFreeTier = props.tier === 'free' ? true : false;
  const showUpsellButton = isFreeTier && !props.isForgeStaff;

  const getMinDate = () => {
    const today = getUTCDate(new Date());
    if (!startDatetime) {
      return today;
    }
    if (startDatetime < today) {
      return startDatetime;
    }
    return new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
  };

  const upsellButton = () => {
    return (
      <>
        {showUpsellButton && (
          <Flex m={'0.25rem'}>
            <Button size="compact-sm" onClick={() => props.onUpsellClick?.()}>
              Upgrade to edit
            </Button>
          </Flex>
        )}
      </>
    );
  };

  return (
    <>
      <NumberInput
        required
        description="Points from completing the action."
        key={`${questId}-points`}
        label="Points"
        rightSection=" "
        error={!isAddition && points <= 0 ? 'Points cannot be negative' : null}
        defaultValue={+points}
        onChange={value => {
          props.onPointsChanges?.(+value);
        }}
      />
      <DateInput
        required
        clearable
        description="The start time of the action."
        disabled={!props.isDateEditable}
        minDate={getMinDate()}
        key={`${questId}-startDatetime`}
        label={'Start Time'}
        value={startDatetime}
        rightSection={upsellButton()}
        rightSectionWidth={'auto'}
        popoverProps={{ zIndex: 600 }}
        onChange={value => {
          if (!showUpsellButton) {
            props.onStartDateChanges?.(value);
          }
        }}
      />
      <Switch
        key={`${questId}-enabled`}
        fw={500}
        labelPosition="right"
        label="Enabled"
        defaultChecked={enabled}
        onChange={e => {
          props.onEnabledChanges?.(e.target.checked);
        }}
      />
    </>
  );
};

export default QuestCommonFields;

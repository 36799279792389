import { useContext, useState } from 'react';
import { GlobalContext } from '../../../context/GlobalContext';
import { MessageStatus } from '../../../data/notify-options';
import { useNotification } from '../../../hooks/use-notification';
import { ssPartnerService } from '../../../services/api-client/ss-partner-service';

export const useAddDraftCampaignIntegration = (): [
  (cloneOption: boolean) => Promise<void>,
  boolean,
  boolean,
] => {
  const notification = useNotification();
  const {
    selectedOrganization,
    setAddingCampaign,
    campaignOptionsTrigger,
    setCampaignOptionsTrigger,
    selectedCampaignOption,
  } = useContext(GlobalContext);
  const [addingDraft, setAddingDraft] = useState<boolean>(false);
  const [isAddingCampaignModalOpen, setIsAddingCampaignModalOpen] =
    useState<boolean>(false);
  const handelAddNewDraft = async (cloneOption: boolean) => {
    try {
      setAddingDraft(true);
      setAddingCampaign(true);
      if (!selectedOrganization) {
        return;
      }
      if (cloneOption) {
        const campaignId = selectedCampaignOption?.draft_campaign_id;
        if (!campaignId) {
          return;
        }
        await ssPartnerService.cloneDraftCampaign(
          selectedOrganization.partnerSlug,
          campaignId
        );
      } else {
        await ssPartnerService.addDraftCampaign(
          selectedOrganization.partnerSlug
        );
      }
    } catch (error: any) {
      notification.notifyApiError({
        status: MessageStatus.Error,
        title: 'Error',
        description: `Error adding new draft`,
        response: error?.response,
      });
    } finally {
      setAddingDraft(false);
      setAddingCampaign(false);
      setCampaignOptionsTrigger(!campaignOptionsTrigger);
      setIsAddingCampaignModalOpen(false);
    }
  };

  return [handelAddNewDraft, addingDraft, isAddingCampaignModalOpen];
};

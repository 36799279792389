import { Flex } from '@chakra-ui/react';
import { Button, Group, Modal, TextInput } from '@mantine/core';
import { FunctionComponent, useEffect, useState } from 'react';
import { OrganizationStructure } from '../../../data/organization';
import { isValidEmail } from '../../../helper/validators';

export interface OrganizationAdditionFormProps {
  onSave?: (organizationStructure: OrganizationStructure) => void;
  addingOrganization?: boolean;
}

const OrganizationAdditionForm: FunctionComponent<
  OrganizationAdditionFormProps
> = props => {
  const [opened, setOpened] = useState(false);
  const [organizationName, setOrganizationName] = useState('');
  const [emails, setEmails] = useState<string[]>([]);
  const [emailInput, setEmailInput] = useState('');
  const [emailError, setEmailError] = useState(false);
  const { onSave, addingOrganization } = props;

  const handleEmailChange = (value: string) => {
    setEmailInput(value);
    const emailList = value
      .trim()
      .split(',')
      .map(email => email.trim());
    const allValid = emailList.every(email => isValidEmail(email));
    setEmailError(!allValid);
    if (allValid) {
      setEmails(emailList);
    } else {
      setEmails([]);
    }
  };

  useEffect(() => {
    if (opened) {
      setOrganizationName('');
      setEmails([]);
      setEmailInput('');
      setEmailError(false);
    }
  }, [opened]);

  return (
    <>
      <Button
        loading={addingOrganization}
        maw="5rem"
        onClick={() => setOpened(true)}
      >
        Add
      </Button>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title="Add Organization"
        zIndex={600}
        centered
      >
        <Flex direction={'column'} gap={'1rem'}>
          <TextInput
            label="Organization Name"
            placeholder="Enter organization name"
            value={organizationName}
            onChange={event => setOrganizationName(event.currentTarget.value)}
            required
          />
          <TextInput
            label="Invite Emails"
            placeholder="Enter emails separated by commas"
            value={emailInput}
            onChange={event => handleEmailChange(event.currentTarget.value)}
            error={
              emailError ? 'Please enter valid email addresses' : undefined
            }
            required
          />
        </Flex>
        <Group justify="end" mt="md">
          <Button variant="default" onClick={() => setOpened(false)}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              if (onSave) {
                onSave({ organizationName, emails });
              }
              setOpened(false);
            }}
            disabled={!organizationName || emails.length === 0 || emailError}
          >
            Save
          </Button>
        </Group>
      </Modal>
    </>
  );
};

export default OrganizationAdditionForm;

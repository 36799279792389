import { Button, Flex, Text, Textarea, TextInput } from '@mantine/core';
import { FunctionComponent, useEffect, useState } from 'react';

export interface BasicAssetsSectionProps {
  title?: string;
  subtitle?: string;
  onSave: (title: string, subtitle: string) => void;
  savingCampaign: boolean;
}

const BasicInfoSection: FunctionComponent<BasicAssetsSectionProps> = props => {
  const { onSave, savingCampaign } = props;
  const [title, setTitle] = useState(props.title);
  const [subtitle, setSubtitle] = useState(props.subtitle);
  const [enableSave, setEnableSave] = useState(false);

  useEffect(() => {
    evaluateConditionToSave();
  }, [title, subtitle]);

  const evaluateConditionToSave = () => {
    const isFormValid =
      !!title && title !== '' && !!subtitle && subtitle !== '';
    const hasChanged = title !== props.title || subtitle !== props.subtitle;
    setEnableSave(isFormValid && hasChanged);
  };

  const handleSave = () => {
    if (enableSave && title && subtitle) {
      onSave(title, subtitle!);
      setEnableSave(false);
    }
  };

  return (
    <Flex gap={'1rem'} p={'1rem'} direction={'column'} w={'100%'}>
      <TextInput
        error={!title || title === '' ? 'This field is required' : null}
        required
        defaultValue={title}
        label={'Game Name'}
        description={'Please include the full name of your game'}
        onChange={event => {
          setTitle(event.currentTarget.value);
        }}
      />
      <Flex direction={'column'} align={'flex-end'} w={'100%'} gap={'0.15rem'}>
        <Textarea
          w={'100%'}
          error={!subtitle || subtitle === '' ? 'This field is required' : null}
          defaultValue={subtitle}
          required
          maxLength={500}
          resize="vertical"
          label={'Loyalty Program Description'}
          description={`Get users excited about your game/project and 
          give them specific details about the rewards they can earn
          by participating in your Loyalty Program. Please be specific
           and include the exact rewards users can expect to receive by
            participating and the end-date for your campaign here.`}
          onChange={event => {
            setSubtitle(event.currentTarget.value);
          }}
        />
        <Text size="xs" c={'#868e96'}>
          Limited to 500 characters
        </Text>
      </Flex>
      <Button
        loading={savingCampaign}
        onClick={handleSave}
        disabled={!enableSave}
      >
        {props.savingCampaign ? 'Saving...' : 'Save'}
      </Button>
    </Flex>
  );
};

export default BasicInfoSection;

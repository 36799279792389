import {
  Card,
  Divider,
  Flex,
  ScrollAreaAutosize,
  Text,
  Title,
} from '@mantine/core';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { FunctionComponent, useState } from 'react';
import { RiNftFill } from 'react-icons/ri';
import { FeatureFlags } from '../../../../data/feature-flags';
import {
  NftContract,
  NftContractAdditionData,
} from '../../../../data/ntf-contract';
import { TableSkeletons } from '../../../Table/componets/Skeletons';
import AddNftContractDialog from './components/AddNftContractDialog';
import NftContractElement from './components/nftContractElement';

export interface NftContractsSectionProps {
  nftContracts?: NftContract[];
  loadingNftContracts: boolean;
  addingContract: boolean;
  handleNftContractSave?: (contract: NftContractAdditionData) => void;
  handleContractRemove?: (contract: NftContract) => void;
  removingNftContract: boolean;
  blockchains?: string[];
  loadingBlockchains: boolean;
}

const NftContractsSection: FunctionComponent<
  NftContractsSectionProps
> = props => {
  const {
    nftContracts,
    loadingNftContracts,
    addingContract,
    removingNftContract,
    blockchains,
    loadingBlockchains,
    handleNftContractSave,
    handleContractRemove,
  } = props;
  const showNftContracts = useFeatureFlagEnabled(FeatureFlags.ShowNftContracts);
  const [contractsBeenRemoved, setContractsBeenRemoved] = useState<string[]>(
    []
  );

  const handelNftContractRemove = (contract: NftContract) => {
    if (handleContractRemove) {
      handleContractRemove(contract);
    }
    setContractsBeenRemoved([
      ...contractsBeenRemoved,
      contract.address + contract.blockchain,
    ]);
  };

  const getAddButton = () => {
    return (
      <AddNftContractDialog
        addingContract={addingContract}
        onSave={handleNftContractSave}
        blockchains={blockchains}
      />
    );
  };
  const getContractElements = () => {
    if (!nftContracts) {
      return null;
    }
    return nftContracts.map((contract, index) => {
      return (
        <NftContractElement
          key={index}
          contract={contract}
          onRemove={handelNftContractRemove}
          removingNftContract={
            removingNftContract &&
            contractsBeenRemoved.includes(
              contract.address + contract.blockchain
            )
          }
        />
      );
    });
  };

  const getContent = () => {
    if (loadingNftContracts || addingContract || loadingBlockchains) {
      return TableSkeletons(10);
    }

    if (showNftContracts === undefined || !showNftContracts) {
      return (
        <Flex w={'100%'} gap={'1rem'} align={'center'} direction={'column'}>
          <RiNftFill size={'15rem'} color="#2aa1da" />
          <Card p={'2rem'} maw={'25rem'} withBorder={true} radius={'1rem'}>
            <Text ta="center">
              NFT contracts are yet under development. Stay tuned for more. 🚀
            </Text>
          </Card>
        </Flex>
      );
    }
    if (!nftContracts || nftContracts.length === 0) {
      return (
        <Flex w={'100%'} gap={'1rem'} align={'center'} direction={'column'}>
          <RiNftFill size={'15rem'} color="#2aa1da" />
          <Card p={'2rem'} maw={'25rem'} withBorder={true} radius={'1rem'}>
            <Text ta="center">
              No NFT contracts found for this campaign. You can also create new
              NFT contracts for this campaign using the{' '}
              <strong>"Add new contract"</strong> button.
            </Text>
          </Card>
          {getAddButton()}
        </Flex>
      );
    }
    return (
      <Flex direction={'column'} gap={'lg'}>
        <Flex direction={'row'} justify={'space-between'}>
          <Title order={2}>Contracts</Title>
          {getAddButton()}
        </Flex>
        <Divider />
        <ScrollAreaAutosize h={'calc(100vh - 19.063rem)'}>
          <Flex direction={'column'} gap={'lg'}>
            {getContractElements()}
          </Flex>
        </ScrollAreaAutosize>
      </Flex>
    );
  };

  return (
    <Flex direction="column" gap="md" p={'lg'}>
      {getContent()}
    </Flex>
  );
};

export default NftContractsSection;

import { AxiosError } from 'axios';
import { configuration } from '../../configuration';
import { TwitterLinkedAccount } from '../../data/twitter-linked-account';
import { BaseService } from './base-service';
import { BearerTokenApiClient } from './bearer-token-api-client';

class TwitterService extends BaseService {
  public constructor(apiClient: BearerTokenApiClient) {
    super(apiClient, 'self-serve-accounts/twitter');
  }

  public async unlinkAccount(id: string, partnerSlug: string) {
    const url = this.url(`unlink/${partnerSlug}/${id}`);
    await this.apiClient.post(
      url,
      {},
      {
        headers: {
          partner: partnerSlug,
        },
      }
    );
  }

  public async getLinkedAccount(
    partnerSlug: string
  ): Promise<TwitterLinkedAccount | null> {
    try {
      const url = this.url(`accounts-linked/${partnerSlug}`);
      const res = await this.apiClient.get<any>(url);

      if (!res?.data?.[0]?.user_info) {
        return null;
      }

      return {
        id: res.data[0].user_info.id,
        name: res.data[0].user_info.name,
        username: res.data[0].user_info.username,
      };
    } catch (e) {
      const axiosError = e as AxiosError;
      if (axiosError.response?.status === 404) {
        return null;
      }
      throw e;
    }
  }
}
export const twitterService = new TwitterService(
  new BearerTokenApiClient(configuration.forgeApi.baseUrl)
);

import {
  ActionIcon,
  Anchor,
  Blockquote,
  Button,
  Divider,
  Drawer,
  Flex,
  Loader,
  Skeleton,
  Text,
  Textarea,
  Title,
  Tooltip,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { FunctionComponent, useEffect, useState } from 'react';
import { BiUnlink } from 'react-icons/bi';
import { FaXTwitter } from 'react-icons/fa6';
import { IoInformationCircleOutline } from 'react-icons/io5';
import { FeaturedTweet } from '../../data/featured-tweet';
import { TwitterLinkedAccount } from '../../data/twitter-linked-account';
import { Card } from '../Card/Card';
import { FeaturedTweetCard } from './components/FeaturedTweetCard/FeaturedTweetCard';

export interface FeaturedTweetsProps {
  removingTweet?: boolean;
  onInvalidEmbeddedCode?(): void;
  onCopyCode(tweetCode: string): void;
  onPublish?(tweetCode: string): void;
  onAdd?(tweetCode: string): void;
  onLinkTwitterAccount?(): void;
  onUnlinkAccount?: () => void;
  onDeleteTweets?(tweets: FeaturedTweet[]): void;
  tweets?: FeaturedTweet[];
  addingTweet?: boolean;
  publishingTweet?: boolean;
  isLoading?: boolean;
  readOnly: boolean;
  badTweetCode: boolean;
  twitterLinkedAccount: TwitterLinkedAccount | null;
  isLoadingTwitterAccount: boolean;
  canCloseDrawer: boolean;
}

export const FeaturedTweets: FunctionComponent<FeaturedTweetsProps> = props => {
  const [inputValue, setInputValue] = useState<string>('');
  const [selectedTweet, setSelectedTweet] = useState<FeaturedTweet>();
  const [isOpen, { open, close }] = useDisclosure(false);
  const [twitterLinkedAccount, setTwitterLinkedAccount] =
    useState<TwitterLinkedAccount | null>(props.twitterLinkedAccount);
  useEffect(() => {
    if (props.canCloseDrawer) {
      close();
    }
  }, [props.canCloseDrawer]);

  useEffect(() => {
    if (props.twitterLinkedAccount) {
      setTwitterLinkedAccount(props.twitterLinkedAccount);
    }
  }, [props.twitterLinkedAccount]);
  const isInvalidEmbeddedCode = (code: string) => {
    if (!code?.startsWith('<blockquote class="twitter-tweet"')) {
      return false;
    }
    if (
      !code.endsWith(
        '<script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>'
      )
    ) {
      return false;
    }
    return true;
  };

  const handleAdd = () => {
    if (!props.onAdd) {
      return;
    }
    if (!isInvalidEmbeddedCode(inputValue)) {
      props.onInvalidEmbeddedCode?.();
      return;
    }
    props.onAdd(inputValue);
  };

  if (props.isLoading) {
    return (
      <Card className="FeaturedTweetsCard" w={'100%'} h={'100%'} bg={'white'}>
        <Flex
          className="FeaturedTweetsContainer"
          direction={'column'}
          h={'100%'}
          gap={'1rem'}
        >
          <Flex direction={'row'} gap={2}>
            <Skeleton height={14} mt={6} width={140} radius="xl" />
            <Skeleton height={14} mt={6} width={140} radius="xl" />
          </Flex>
          <Flex direction={'row'} gap={2}>
            <Skeleton height={140} mt={6} width={'15rem'} radius="xl" />
            <Skeleton height={140} mt={6} width={'15rem'} radius="xl" />
            <Skeleton height={140} mt={6} width={'15rem'} radius="xl" />
          </Flex>
        </Flex>
      </Card>
    );
  }

  const getTweetsElements = () => {
    if (!props.tweets || props.tweets.length === 0) {
      return (
        <Flex h={'100%'} w={'100%'} align={'center'} justify={'center'}>
          <Title size={'md'}>No tweets added yet.</Title>
        </Flex>
      );
    }
    const sortedTweets = props.tweets.sort((a, b) => {
      const dateA = new Date(a.createdAt).getTime();
      const dateB = new Date(b.createdAt).getTime();
      if (dateA > dateB) {
        return -1;
      } else if (dateA < dateB) {
        return 1;
      }
      return 0;
    });

    return sortedTweets.map(tweet => {
      return (
        <FeaturedTweetCard
          key={tweet.id}
          onCopy={tweetCode => props.onCopyCode?.(tweetCode)}
          tweet={tweet}
          removingTweet={props.removingTweet && selectedTweet?.id === tweet.id}
          onDeleteTweet={tweet => {
            setSelectedTweet(tweet);
            props.onDeleteTweets?.([tweet]);
          }}
          onPublish={tweet => {
            setSelectedTweet(tweet);
            props.onPublish?.(tweet.embedHtml);
          }}
          isPublishing={props.publishingTweet && selectedTweet?.id === tweet.id}
        />
      );
    });
  };
  const getAddingTweetForm = () => {
    return (
      <Flex
        className="FeaturedTweetsActionContainer"
        c={'black'}
        w={'100%'}
        direction={'column'}
        gap={'2rem'}
      >
        <Flex
          className="FeaturedTweetsInputSection"
          direction={'row'}
          gap={'1rem'}
        >
          <Textarea
            w={'100%'}
            label="Twitter Embed Code"
            disabled={props.readOnly || props.addingTweet}
            value={inputValue}
            rows={10}
            onChange={e => setInputValue(e.currentTarget.value)}
            error={props.badTweetCode}
          />
        </Flex>
        <Flex w={'100%'} justify={'end'}>
          <Button
            loading={props.addingTweet}
            disabled={props.readOnly}
            onClick={handleAdd}
          >
            Add
          </Button>
        </Flex>
        <Blockquote icon={<IoInformationCircleOutline size={'1.5rem'} />}>
          You can get the HTML code for your Tweet from{' '}
          <Anchor href="https://publish.twitter.com/#" target="_blank">
            here
          </Anchor>
          .
        </Blockquote>
      </Flex>
    );
  };

  const getButtonOrAccount = () => {
    const isTweetLoading = props.addingTweet || props.isLoadingTwitterAccount;

    if (twitterLinkedAccount) {
      return (
        <Flex
          direction={'row'}
          gap={'0.5rem'}
          align={'center'}
          style={{
            fontWeight: '400',
          }}
        >
          {!isTweetLoading ? (
            <>
              <FaXTwitter
                style={{
                  fontSize: '2rem',
                }}
              />
              <Text>Linked Account: @{twitterLinkedAccount.username}</Text>

              <Tooltip label="Unlink Twitter Account">
                <ActionIcon>
                  <BiUnlink onClick={() => props.onUnlinkAccount?.()} />
                </ActionIcon>
              </Tooltip>
            </>
          ) : (
            <Loader />
          )}
        </Flex>
      );
    }

    return (
      <Button
        loading={isTweetLoading}
        disabled={props.readOnly}
        onClick={() => props.onLinkTwitterAccount?.()}
      >
        Link Twitter Account
      </Button>
    );
  };

  return (
    <Flex
      className="FeaturedTweetsWrapper"
      direction={'column'}
      h={'100%'}
      w={'100%'}
    >
      <Flex
        align={'center'}
        pos={'sticky'}
        top={0}
        style={{ zIndex: 200 }}
        bg={'#f9fafb'}
        justify={'flex-end'}
        gap={'lg'}
        p={'xs'}
      >
        {getButtonOrAccount()}
        <Button
          onClick={() => {
            setInputValue('');
            open();
          }}
        >
          Add tweet
        </Button>
      </Flex>
      <Divider />
      <Card className="FeaturedTweetsCard" w={'100%'} h={'100%'} bg={'white'}>
        <Flex
          className="FeaturedTweetsContainer"
          direction={'column'}
          h={'100%'}
          gap={'1rem'}
        >
          <Drawer
            key={'edit-drawer'}
            zIndex={600}
            opened={isOpen}
            onClose={close}
            title={'Add tweet'}
            position="right"
            size={'lg'}
          >
            {getAddingTweetForm()}
          </Drawer>

          <Flex
            className="FeaturedTweetsCardsContainer"
            h={'100%'}
            direction={'row'}
            wrap={'wrap'}
            gap={'1rem'}
          >
            {getTweetsElements()}
          </Flex>
        </Flex>
      </Card>
    </Flex>
  );
};

import DailyStreakSection from '../components/DailyStreakSection';
import NftMultiplierSection from '../components/NftMultiplierSection';
import ReferralPointsSection from '../components/ReferralPointsSection';
import SocialMultiplierSection from '../components/SocialMultiplierSection';
import { FeaturesConfigurationProps } from './feature-configuration-props';

export const configurationElementsByFeature = new Map<
  string,
  (props: FeaturesConfigurationProps, feature: string) => JSX.Element
>([
  [
    'Referrer Multiplier',
    (props: FeaturesConfigurationProps, feature: string) => {
      return (
        <ReferralPointsSection
          defaultValues={props.referralPoints}
          onSave={props.onReferralPointsSave}
          savingReferralPoints={props.savingReferralPoints}
          enableConfiguration={
            props.featureData?.enabled_features.includes(feature) ?? false
          }
        />
      );
    },
  ],
  [
    'Social Multiplier',
    (props: FeaturesConfigurationProps, feature: string) => {
      return (
        <SocialMultiplierSection
          defaultValues={props.socialMultiplier}
          enableConfiguration={
            props.featureData?.enabled_features.includes(feature) ?? false
          }
          onSave={props.onSocialMultiplierSave}
          savingSocialMultiplier={props.savingSocialMultiplier}
        />
      );
    },
  ],
  [
    'NFT Multiplier',
    (props: FeaturesConfigurationProps, feature: string) => {
      return (
        <NftMultiplierSection
          defaultValues={props.nftMultiplier}
          enableConfiguration={
            props.featureData?.enabled_features.includes(feature) ?? false
          }
          onSave={props.onNftMultiplierSave}
          savingNftMultiplier={props.savingNftMultiplier}
        />
      );
    },
  ],
  [
    'Daily Streak',
    (props: FeaturesConfigurationProps, feature: string) => {
      return (
        <DailyStreakSection
          defaultValues={props.dailyStreak}
          enableConfiguration={
            props.featureData?.enabled_features.includes(feature) ?? false
          }
          onSave={props.onSaveDailyStreaks}
          savingDailyStreak={props.savingDailyStreak}
        />
      );
    },
  ],
]);

import { GamePageTabs } from '../components/Game/components/data/tabs-data';
import { Campaign } from '../data/campaign';
import { CampaignOption } from '../data/campaign-option';
import { CampaignPublishStatus } from '../data/campaign-publish-status';

class CampaignDataService {
  public getCampaignPublishableStatus(
    campaign: Campaign
  ): CampaignPublishStatus {
    const sectionsInError: GamePageTabs[] = [];
    let canPublish = true;
    if (!this._basicCheck(campaign)) {
      sectionsInError.push(GamePageTabs.BasicInformation);
      canPublish = false;
    }
    if (!this._extraDataCheck(campaign)) {
      sectionsInError.push(GamePageTabs.ExtraData);
      canPublish = false;
    }
    if (!this._assets(campaign)) {
      sectionsInError.push(GamePageTabs.Assets);
      canPublish = false;
    }
    return { canPublish, sectionsInError };
  }
  private _basicCheck(campaign: Campaign) {
    if (
      !campaign.title ||
      campaign.title === '' ||
      !campaign.subtitle ||
      campaign.subtitle === ''
    ) {
      return false;
    }
    return true;
  }

  private _extraDataCheck(campaign: Campaign) {
    if (
      !campaign.description ||
      campaign.description === '' ||
      !campaign.genre ||
      campaign.genre === '' ||
      !campaign.start_datetime ||
      !campaign.end_datetime ||
      !campaign.extra_data ||
      !campaign.extra_data.developer ||
      !campaign.extra_data.game_mode ||
      !campaign.extra_data.platform ||
      !campaign.extra_data.release_date ||
      !campaign.extra_data.website ||
      !campaign.extra_data.play_now ||
      !campaign.extra_data.partner_name ||
      (campaign.end_datetime &&
        new Date(campaign.end_datetime ?? '') <= new Date())
    ) {
      return false;
    }
    return true;
  }

  private _assets(campaign: Campaign) {
    return (
      campaign.extra_data?.background &&
      campaign.extra_data?.poster &&
      campaign.extra_data?.week_quests_bg &&
      campaign.extra_data?.icon
    );
  }

  public getLiveFromOptions(campaignOptions?: CampaignOption[]) {
    if (!campaignOptions) {
      return;
    }
    return campaignOptions
      ?.find(x => x.published)
      ?.draft_campaign_id.replace('DRAFT-', '');
  }
}

export const campaignDataService = new CampaignDataService();

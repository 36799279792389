export enum Path {
  Login = '/login',
  LoginCallback = '/login-callback',
  Unauthorized = '/unauthorized',
  Home = '/home',
  Report = '/report',
  Actions = '/actions',
  Api = '/api',
  Collaborators = '/collaborators',
  Discord = '/discord',
  Kols = '/kols',
  Tweets = '/tweets',
  TelegramBot = '/telegram-bot',
  UserLookup = '/user-lookup',
  Billing = '/billing',
  Game = '/game',
  SignUp = '/sign-up',
  OrganizationManagement = '/org-management',
  Rewards = '/rewards',
  Staff = '/staff',
}

export const noContentPages = [
  `${Path.Login}`,
  `${Path.LoginCallback}`,
  `${Path.Unauthorized}`,
  `${Path.SignUp}`,
];

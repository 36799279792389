import { useContext } from 'react';
import { GlobalContext } from '../context/GlobalContext';

export const usePublishStatus = (): [() => void] => {
  const { setCampaignPublishStatus, campaignPublishStatus } =
    useContext(GlobalContext);

  const activatePublishIfNoErrors = () => {
    if (campaignPublishStatus?.sectionsInError?.length === 0) {
      setCampaignPublishStatus({
        ...campaignPublishStatus,
        canPublish: true,
      });
    }
  };

  return [activatePublishIfNoErrors];
};

import { FunctionComponent } from 'react';
import { Organization } from '../../../data/organization';
import { ForgeTable, Row } from '../../Table/ForgeTable';

export interface OrganizationListProps {
  organizations?: Organization[];
  isLoading?: boolean;
}

const OrganizationList: FunctionComponent<OrganizationListProps> = props => {
  const { organizations, isLoading } = props;
  const headers = ['PartnerSlug', 'Partner', 'Game', 'Tier', 'CreatedAt'];

  if (organizations?.length === 0) {
    return (
      <div>
        <h2>No organizations found</h2>
      </div>
    );
  }

  const getRows = (): Row[] => {
    if (!organizations) {
      return [];
    }
    return organizations?.map(org => {
      return {
        elements: [
          org.partnerSlug,
          org.partner,
          org.gameName,
          org.tier,
          org.createdAt,
        ],
      };
    });
  };

  return (
    <ForgeTable
      isLoading={isLoading}
      headers={headers}
      rows={getRows()}
      skeletonAmount={10}
      stickyHeader
      headerColor="#f9fafb"
    />
  );
};

export default OrganizationList;

import {
  ActionIcon,
  Button,
  Flex,
  Modal,
  NumberInput,
  TextInput,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { FunctionComponent, useEffect, useState } from 'react';
import { IoAdd } from 'react-icons/io5';
import { Multiplier } from '../../../../../data/multiplier';

export interface AddMultiplierDialogProps {
  lastMultiplier?: Multiplier;
  onSave: (multiplier: Multiplier) => void;
  isDisabled: boolean;
  requireNoLimitMax?: boolean;
  requireMultiplierGreaterThanOne?: boolean;
}

const AddMultiplierDialog: FunctionComponent<
  AddMultiplierDialogProps
> = props => {
  const [description, setDescription] = useState('New multiplier');
  const [max, setMax] = useState<number | null>(null);
  const [min, setMin] = useState<number>(0);
  const [multiplier, setMultiplier] = useState(
    props.requireMultiplierGreaterThanOne ? 2 : 1
  );
  const [opened, { open, close }] = useDisclosure(false);
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [errors, setErrors] = useState({
    description: '',
    max: '',
    min: '',
    multiplier: '',
  });

  const validateMin = () => {
    if (min < 0) return false;
    if (props.lastMultiplier?.max && min <= props.lastMultiplier.max)
      return false;
    return true;
  };

  const validateMax = () => {
    if (props.requireNoLimitMax && max !== null) return false;
    if (max === null) return true;
    if (max <= min || max <= 0) return false;
    return true;
  };

  const validateDescription = () => {
    if (description.trim() === '') return false;
    return true;
  };

  const validateMultiplier = () => {
    if (props.requireMultiplierGreaterThanOne && multiplier <= 1) return false;
    if (multiplier <= 0) return false;
    if (
      props.lastMultiplier?.multiplier &&
      multiplier <= props.lastMultiplier.multiplier
    )
      return false;
    return true;
  };

  useEffect(() => {
    const isMinValid = validateMin();
    const isMaxValid = validateMax();
    const isDescriptionValid = validateDescription();
    const isMultiplierValid = validateMultiplier();
    setErrors({
      description: isDescriptionValid ? '' : 'Description is required',
      max: isMaxValid ? '' : 'Max must be higher than Min and greater than 0',
      min: isMinValid
        ? ''
        : `Min must be greater or equal to 0${props.lastMultiplier?.max ? ` and higher than ${props.lastMultiplier.max}` : ''}`,
      multiplier: isMultiplierValid
        ? ''
        : `Multiplier must be greater than 0${props.lastMultiplier?.multiplier ? ` and higher than ${props.lastMultiplier.multiplier}` : ''}${props.requireMultiplierGreaterThanOne ? ' and greater than 1' : ''}`,
    });
    setIsSaveEnabled(
      isMinValid && isMaxValid && isDescriptionValid && isMultiplierValid
    );
  }, [
    description,
    min,
    max,
    multiplier,
    props.lastMultiplier,
    props.requireNoLimitMax,
    props.requireMultiplierGreaterThanOne,
  ]);

  const handleOpen = () => {
    setDescription('New multiplier');
    setMax(null);
    setMin(props.lastMultiplier?.max ? props.lastMultiplier.max + 1 : 0);
    setMultiplier(
      props.lastMultiplier?.multiplier
        ? props.lastMultiplier.multiplier + 1
        : props.requireMultiplierGreaterThanOne
          ? 2
          : 1
    );
    open();
  };

  const handleSave = () => {
    const newMultiplier: Multiplier = { description, max, min, multiplier };
    props.onSave(newMultiplier);
    close();
  };

  return (
    <>
      {props.lastMultiplier ? (
        <ActionIcon
          disabled={props.isDisabled}
          color="blue"
          size="sm"
          onClick={handleOpen}
        >
          <IoAdd />
        </ActionIcon>
      ) : (
        <Button onClick={handleOpen} disabled={props.isDisabled}>
          Add Multiplier
        </Button>
      )}
      <Modal
        centered
        zIndex={300}
        opened={opened}
        onClose={close}
        title="Add Multiplier"
      >
        <Flex direction={'column'} gap={'sm'}>
          <TextInput
            label="Description"
            placeholder="Description"
            value={description}
            onChange={e => setDescription(e.currentTarget.value)}
            error={errors.description}
          />

          <NumberInput
            label="Min"
            min={0}
            placeholder="Min"
            allowDecimal={false}
            value={min}
            onChange={value =>
              setMin(
                ['', undefined].includes(value.toString())
                  ? 0
                  : (value as number)
              )
            }
            error={errors.min}
          />
          <NumberInput
            label="Max"
            min={0}
            allowDecimal={false}
            placeholder={max === null ? 'No limit' : 'Max'}
            value={max ?? undefined}
            onChange={value =>
              setMax(
                ['', undefined].includes(value.toString())
                  ? null
                  : (value as number | null)
              )
            }
            error={errors.max}
            disabled={props.requireNoLimitMax}
          />
          <NumberInput
            label="Multiplier"
            placeholder="Multiplier"
            value={multiplier}
            onChange={value => setMultiplier(value as number)}
            error={errors.multiplier}
            min={1}
          />
          <Button onClick={handleSave} disabled={!isSaveEnabled}>
            Save
          </Button>
        </Flex>
      </Modal>
    </>
  );
};

export default AddMultiplierDialog;

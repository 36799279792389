import { Divider, Flex, Skeleton, Text, Title } from '@mantine/core';
import { useContext } from 'react';
import ChargesAccordion from '../../components/Billing/ChargesAccordion/ChargesAccordion';
import { GlobalContext } from '../../context/GlobalContext';
import { StripeCharge } from '../../data/stripe-transaction-data';
import { useDataFetcher } from '../../hooks/use-data-fetcher';
import { upSellService } from '../../services/api-client/up-sell-service';
import { withAuthProtection } from '../../services/protect-route-element';

const Page = () => {
  const { selectedOrganization } = useContext(GlobalContext);
  const [charges, loadingCharges] = useDataFetcher<StripeCharge[]>({
    serviceCall() {
      if (!selectedOrganization) {
        throw new Error('Invalid partner');
      }
      return upSellService.getLoyaltyProgramTransactions(
        selectedOrganization.partnerSlug
      );
    },
  });

  if (loadingCharges) {
    return (
      <Flex
        w={'100%'}
        p={'5rem'}
        gap={'xl'}
        direction={'column'}
        justify={'center'}
      >
        {Array.from({ length: 5 }).map((_, index) => (
          <Skeleton key={index} height={'5rem'} width={'100%'} />
        ))}
      </Flex>
    );
  }

  if (!charges || charges.length === 0) {
    return (
      <Flex w={'100%'} p={'5rem'} justify={'center'}>
        No transactions
      </Flex>
    );
  }

  return (
    <Flex
      w={'100%'}
      direction={'column'}
      gap={'1rem'}
      p={'3rem'}
      justify={'center'}
    >
      <Flex align={'center'} justify={'space-between'}>
        <Title size={'1.5rem'}>Transactions</Title>
        <Text>
          Tier:{' '}
          {selectedOrganization?.tier
            ? selectedOrganization.tier.charAt(0).toUpperCase() +
              selectedOrganization.tier.slice(1)
            : 'N/A'}
        </Text>
      </Flex>
      <Divider w={'100%'} />
      <ChargesAccordion charges={charges} />
    </Flex>
  );
};

export const BillingPage = withAuthProtection(Page);

import { Flex } from '@mantine/core';
import { useContext, useState } from 'react';
import RewardSection from '../../components/Game/components/RewardSection';
import { GlobalContext } from '../../context/GlobalContext';
import { MessageStatus } from '../../data/notify-options';
import { RewardInfo } from '../../data/reward-info';
import { useDataFetcher } from '../../hooks/use-data-fetcher';
import { useNotification } from '../../hooks/use-notification';
import { campaignService } from '../../services/api-client/campaign-service';
import { ssPartnerService } from '../../services/api-client/ss-partner-service';
import { withAuthProtection } from '../../services/protect-route-element';

const Page = () => {
  const notification = useNotification();
  const {
    campaignPublishStatus,
    setCampaignPublishStatus,
    selectedCampaignData,
    selectedCampaignDataTrigger,
    selectedOrganization,
  } = useContext(GlobalContext);
  const [savingRewards, setSavingRewards] = useState<boolean>(false);

  const checkAndSetPublish = () => {
    if (campaignPublishStatus?.sectionsInError?.length === 0) {
      setCampaignPublishStatus({
        ...campaignPublishStatus,
        canPublish: true,
      });
    }
  };
  const [rewardsData, loadingRewardsData] = useDataFetcher<RewardInfo>({
    serviceCall() {
      if (selectedOrganization && selectedCampaignData) {
        return ssPartnerService.getRewardInfo(
          selectedOrganization.partnerSlug,
          selectedCampaignData.campaign_id
        );
      }
    },
    dependencies: [selectedCampaignData, selectedCampaignDataTrigger],
  });

  const handelRewardSave = async (rewardOutPut: {
    rewardInfo: RewardInfo;
    image: File | null;
  }) => {
    if (!selectedCampaignData || !selectedOrganization) {
      return;
    }
    try {
      setSavingRewards(true);
      debugger; //eslint-disable-line
      const rewardUrl = await campaignService.getRewardUrl(rewardOutPut.image);
      const reward = {
        ...rewardOutPut.rewardInfo,
        reward_image:
          rewardOutPut.image && rewardUrl
            ? rewardUrl
            : rewardOutPut.rewardInfo.reward_image,
      };
      await ssPartnerService.saveRewardsInfo(
        reward,
        selectedCampaignData,
        selectedOrganization.partnerSlug
      );
      checkAndSetPublish();
    } catch (error: any) {
      notification.notifyApiError({
        status: MessageStatus.Error,
        title: 'Reward Error',
        description: 'An error occurred while saving the reward',
        response: error.response,
      });
    } finally {
      setSavingRewards(false);
    }
  };

  return (
    <Flex className="RewardPage" justify={'center'} align={'center'}>
      <RewardSection
        reward={rewardsData}
        onSave={handelRewardSave}
        savingReward={savingRewards}
        loading={loadingRewardsData}
      />
    </Flex>
  );
};
export const RewardsPage = withAuthProtection(Page);

import { Button, Card, Flex, Grid, Text, TextInput } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { Multiplier } from '../../../../../data/multiplier';
import { NftMultiplier } from '../../../../../data/nft-multiplier';
import AddMultiplierDialog from './AddMultiplierDialog';
import MultiplierElement from './MultiplierElement';

export enum MultiplierType {
  Partner = 'partner',
  Project = 'project',
}

export interface NftMultiplierSectionProps {
  defaultValues?: NftMultiplier;
  savingNftMultiplier: boolean;
  enableConfiguration: boolean;
  onSave: (nftMultiplier: NftMultiplier) => void;
}

const NftMultiplierSection: React.FC<NftMultiplierSectionProps> = ({
  defaultValues,
  onSave,
  savingNftMultiplier,
  enableConfiguration,
}) => {
  const [nftMultiplier, setNftMultiplier] = useState<NftMultiplier>({
    description: defaultValues?.description || '',
    multiplier: defaultValues?.multiplier || { partner: [], project: [] },
  });

  const [isModified, setIsModified] = useState(false);
  const [validationMessage, setValidationMessage] = useState<string | null>(
    null
  );

  useEffect(() => {
    setNftMultiplier({
      description: defaultValues?.description || '',
      multiplier: defaultValues?.multiplier || { partner: [], project: [] },
    });
  }, [defaultValues]);

  useEffect(() => {
    validateNftMultiplier();
  }, [nftMultiplier]);

  const handleDescriptionChange = (value: string) => {
    setNftMultiplier(prev => ({
      ...prev,
      description: value,
    }));
    setIsModified(true);
  };

  const handleSave = () => {
    onSave(nftMultiplier);
    setIsModified(false);
  };

  const validateNftMultiplier = () => {
    const { partner, project } = nftMultiplier.multiplier;
    const lastPartner = partner?.[partner.length - 1];
    const lastProject = project?.[project.length - 1];

    if (
      (lastPartner && lastPartner.max !== null) ||
      (lastProject && lastProject.max !== null)
    ) {
      setValidationMessage(
        `The last elements of both Partner and Project lists must have max property as 'no limit'.`
      );
    } else {
      setValidationMessage(null);
    }
  };

  const isSaveDisabled =
    !isModified || !enableConfiguration || validationMessage !== null;

  const handleElementRemove = (index: number, type: MultiplierType) => {
    setNftMultiplier(prev => ({
      ...prev,
      multiplier: {
        ...prev.multiplier,
        [type]: prev.multiplier[type]?.filter((_, i) => i !== index) || [],
      },
    }));
    setIsModified(true);
  };

  const handleElementAddition = (
    multiplier: Multiplier,
    type: MultiplierType
  ) => {
    setNftMultiplier(prev => ({
      ...prev,
      multiplier: {
        ...prev.multiplier,
        [type]: [...(prev.multiplier[type] || []), multiplier],
      },
    }));
    setIsModified(true);
  };

  const canAddMultiplier = (type: MultiplierType) => {
    const lastMultiplier = nftMultiplier.multiplier[type]?.slice(-1)[0];
    return lastMultiplier && lastMultiplier.max !== null;
  };

  const getElements = (type: MultiplierType) => {
    const elements = nftMultiplier.multiplier[type];
    const title = type === MultiplierType.Partner ? 'Partner' : 'Project';
    if (!elements || elements.length === 0) {
      return (
        <Flex direction="column" gap="md">
          <Text size="sm">{title}:</Text>
          <Flex justify="center" align="center" style={{ height: '100px' }}>
            <AddMultiplierDialog
              isDisabled={!enableConfiguration}
              onSave={multiplier => handleElementAddition(multiplier, type)}
              requireMultiplierGreaterThanOne
            />
          </Flex>
        </Flex>
      );
    }
    return (
      <Flex direction="column" gap="md">
        <Text size="sm">{title}:</Text>
        <Grid>
          {elements.map((multiplierValue, i) => (
            <Grid.Col span={12} key={i}>
              <MultiplierElement
                multiplier={multiplierValue}
                isLastOne={i === elements.length - 1}
                isDisabled={!enableConfiguration}
                onRemove={() => handleElementRemove(i, type)}
                canAddMultiplier={canAddMultiplier(type)}
                handleElementAddition={multiplier =>
                  handleElementAddition(multiplier, type)
                }
              />
            </Grid.Col>
          ))}
        </Grid>
      </Flex>
    );
  };

  return (
    <Flex direction="column" gap="md">
      <TextInput
        label="Description"
        value={nftMultiplier.description ?? ''}
        onChange={event => handleDescriptionChange(event.currentTarget.value)}
        disabled={!enableConfiguration}
      />
      <Card shadow="sm" padding="lg" withBorder>
        <Card.Section>
          <Text pl={'1rem'} pt={'1rem'} size="md" mb="xs">
            Multipliers
          </Text>
        </Card.Section>
        <Grid>
          <Grid.Col span={6}>{getElements(MultiplierType.Partner)}</Grid.Col>
          <Grid.Col span={6}>{getElements(MultiplierType.Project)}</Grid.Col>
        </Grid>
      </Card>
      {validationMessage && (
        <Text color="red" size="sm">
          {validationMessage}
        </Text>
      )}
      <Button
        loading={savingNftMultiplier}
        onClick={handleSave}
        disabled={isSaveDisabled}
      >
        Save
      </Button>
    </Flex>
  );
};

export default NftMultiplierSection;

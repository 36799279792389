import { configuration } from '../../configuration';
import { OrganizationStructure } from '../../data/organization';
import { BaseService } from './base-service';
import { BearerTokenApiClient } from './bearer-token-api-client';

class SsOrganizationService extends BaseService {
  public constructor(apiClient: BearerTokenApiClient) {
    super(apiClient, 'create-organization');
  }

  public async create(organizationStructure: OrganizationStructure) {
    await this.apiClient.post(this.url(), {
      partner_name: organizationStructure.organizationName,
      game_name: organizationStructure.organizationName.replaceAll(' ', ''),
      emails: organizationStructure.emails,
      tier: 'free',
    });
  }
}

export const ssOrganizationService = new SsOrganizationService(
  new BearerTokenApiClient(configuration.forgeApi.selfServiceUrl)
);

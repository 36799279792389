import { Button, Flex, NumberInput, TextInput } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { ReferralPoints } from '../../../../../data/referral-points';

interface ReferralPointsComponentProps {
  defaultValues?: ReferralPoints;
  onSave: (referralPoints: ReferralPoints) => void;
  savingReferralPoints: boolean;
  enableConfiguration: boolean;
}

const numericFields = [
  'points_for_referral_child',
  'points_for_referral_grand_child',
];

const ReferralPointsSection: React.FC<ReferralPointsComponentProps> = ({
  defaultValues,
  onSave,
  savingReferralPoints,
  enableConfiguration,
}) => {
  const [referralPoints, setReferralPoints] = useState<ReferralPoints>({
    points_for_referral_child: defaultValues?.points_for_referral_child || 0,
    points_for_referral_grand_child:
      defaultValues?.points_for_referral_grand_child || 0,
    description: defaultValues?.description || '',
  });

  const [isModified, setIsModified] = useState(false);
  const [errors, setErrors] = useState({
    points_for_referral_child: '',
    points_for_referral_grand_child: '',
  });

  useEffect(() => {
    setReferralPoints({
      points_for_referral_child: defaultValues?.points_for_referral_child || 0,
      points_for_referral_grand_child:
        defaultValues?.points_for_referral_grand_child || 0,
      description: defaultValues?.description || '',
    });
  }, [defaultValues]);

  const validateField = (field: keyof ReferralPoints, value: any): string => {
    if (numericFields.includes(field)) {
      return value === '' || isNaN(value) || value < 0
        ? 'Value must be a number and cannot be empty'
        : '';
    }
    return '';
  };

  const handleChange = (field: keyof ReferralPoints, value: any) => {
    const error = validateField(field, value);
    setErrors(prev => ({
      ...prev,
      [field]: error,
    }));
    if (error === '') {
      setReferralPoints(prev => ({
        ...prev,
        [field]: value,
      }));
      setIsModified(true);
    }
  };

  const handleSave = () => {
    onSave(referralPoints);
    setIsModified(false);
  };

  const isSaveDisabled =
    !isModified ||
    !enableConfiguration ||
    Object.values(errors).some(error => error !== '');

  return (
    <Flex direction="column" gap="md">
      <NumberInput
        label="Points for Referral Child"
        value={referralPoints.points_for_referral_child}
        onChange={value => handleChange('points_for_referral_child', value)}
        disabled={!enableConfiguration}
        error={errors.points_for_referral_child}
        min={0}
      />
      <NumberInput
        label="Points for Referral Grand Child"
        value={referralPoints.points_for_referral_grand_child}
        onChange={value =>
          handleChange('points_for_referral_grand_child', value)
        }
        disabled={!enableConfiguration}
        error={errors.points_for_referral_grand_child}
        min={0}
      />
      <TextInput
        label="Description"
        value={referralPoints.description}
        onChange={event =>
          handleChange('description', event.currentTarget.value)
        }
        disabled={!enableConfiguration}
      />
      <Button
        loading={savingReferralPoints}
        onClick={handleSave}
        disabled={isSaveDisabled}
      >
        Save
      </Button>
    </Flex>
  );
};

export default ReferralPointsSection;

import { configuration } from '../../configuration';
import { Kol, TopKol } from '../../data/kols';
import { BaseService } from './base-service';
import { BearerTokenApiClient } from './bearer-token-api-client';

class KolsService extends BaseService {
  public constructor(apiClient: BearerTokenApiClient) {
    super(apiClient, 'publish-multiplier-override');
  }

  public async getTopKols(): Promise<TopKol[]> {
    return this.apiClient.get<TopKol[]>(this.url('top-kols'));
  }

  public async remove(userName: any): Promise<void> {
    await this.apiClient.delete(this.url(userName));
  }

  public async edit(newKol: Kol): Promise<void> {
    await this.apiClient.put<Kol, Kol>(this.url(), newKol);
  }

  public async add(newKol: Kol): Promise<void> {
    await this.apiClient.post<Kol, Kol>(this.url(), newKol);
  }

  public getKols(): Promise<Kol[]> {
    return this.apiClient.get<Kol[]>(this.url());
  }
}

export const kolsService = new KolsService(
  new BearerTokenApiClient(configuration.backendApi.url, true)
);

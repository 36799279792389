import { ApiClient } from './api-client';

export abstract class BaseService {
  public constructor(
    protected apiClient: ApiClient,
    private _basePath: string
  ) {}

  protected url(path = ''): string {
    const extendedPath = path.startsWith('/') ? path.substring(1) : path;
    const separator = extendedPath && extendedPath !== '' ? '/' : '';
    return `/${this._basePath}${separator}${extendedPath}`;
  }
}

import {
  Anchor,
  Button,
  Divider,
  Flex,
  Image,
  Modal,
  Text,
  Title,
  Tooltip,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { FunctionComponent, useEffect, useState } from 'react';
import { IoRocket } from 'react-icons/io5';
import { LocalImages } from '../../assets/images';
import { CampaignPublishStatus } from '../../data/campaign-publish-status';

interface PublishDialogProps {
  hasUnpublishedChanges?: boolean;
  campaignPublishStatus?: CampaignPublishStatus;
  opened?: boolean;
  isLoading?: boolean;
  isTriggerLoading?: boolean;
  campaignEndDatetime?: string;
  onPublish?: () => void;
  onClickNavigateToGamePage?: () => void;
}

export const PublishDialog: FunctionComponent<PublishDialogProps> = props => {
  const {
    campaignPublishStatus,
    campaignEndDatetime,
    onClickNavigateToGamePage,
    hasUnpublishedChanges,
  } = props;
  const [opened, { open, close }] = useDisclosure(props.opened);
  const [disablePublishButton, setDisablePublishButton] = useState(false);

  useEffect(() => {
    const shouldDisablePublishButton = () => {
      if (!campaignPublishStatus) return true;
      if (!campaignPublishStatus.canPublish) return true;
      if (hasUnpublishedChanges && !campaignPublishStatus.canPublish)
        return true;
      if ((campaignPublishStatus?.sectionsInError?.length ?? 0) > 0)
        return true;
      return false;
    };
    setDisablePublishButton(shouldDisablePublishButton());
  }, [campaignPublishStatus, hasUnpublishedChanges]);

  useEffect(() => {
    if (props.opened) {
      open();
    } else {
      close();
    }
  }, [props]);

  const getLabelValue = () => {
    if (!campaignPublishStatus) {
      return 'Campaign status is not available.';
    }
    if (
      !hasUnpublishedChanges &&
      !campaignPublishStatus.canPublish &&
      campaignPublishStatus.sectionsInError?.length === 0
    ) {
      return 'You have no changes to publish.';
    }
    if ((campaignPublishStatus.sectionsInError?.length ?? 0) > 0) {
      const formattedSections = (campaignPublishStatus.sectionsInError ?? [])
        .map(section => section.charAt(0).toUpperCase() + section.slice(1))
        .join(', ')
        .replace(/, ([^,]*)$/, ' and $1')
        .replaceAll('_', ' ');
      const errorMessage = `Make sure all the required fields are properly filled before publishing the changes. 
      You have missing fields in: ${formattedSections} section${campaignPublishStatus.sectionsInError?.length === 1 ? '' : 's'} 
      in Game page.`;
      return errorMessage;
    }

    if (campaignPublishStatus.canPublish) {
      return 'You can publish your changes now.';
    }

    return 'Publishing is currently disabled.';
  };

  const getEndDateWarning = () => {
    if (
      !campaignEndDatetime ||
      campaignEndDatetime === '3000-01-01T03:00:00.000Z'
    ) {
      return (
        <>
          <Divider />
          <Flex
            direction={'row'}
            w={'100%'}
            align={'center'}
            justify={'space-between'}
          >
            <Title order={4}>Important</Title>
            <Button
              variant="link"
              size="compact-xs"
              onClick={onClickNavigateToGamePage}
            >
              Set end date
            </Button>
          </Flex>
          <Text c={'gray'}>
            No end date set. It's recommended to set one to avoid potential
            issues. Navigate to the information section in game page to set it.
          </Text>
        </>
      );
    }
    return null;
  };

  return (
    <>
      <Tooltip maw={'15rem'} multiline openDelay={500} label={getLabelValue()}>
        <Button
          leftSection={<IoRocket size="1rem" />}
          loading={props.isTriggerLoading}
          disabled={disablePublishButton}
          onClick={open}
        >
          Publish
        </Button>
      </Tooltip>
      <Modal
        title={<Image w={'8rem'} src={LocalImages.logo} />}
        zIndex={600}
        opened={opened}
        onClose={close}
        centered
      >
        <Flex direction={'column'} gap={'lg'} p={'sm'} pt={0} pb={'0'}>
          <Flex direction={'column'} gap={'0.5rem'}>
            <Title order={3}>Publish</Title>
            <Text c={'gray'}>
              Onward and Upward! Click Publish to allow thousands of gamers to
              participate in your new loyalty program.
            </Text>
            {getEndDateWarning()}
          </Flex>

          <Flex gap={'md'} justify={'center'} w={'100%'}>
            <Button variant="outline" w={'100%'} onClick={close}>
              Cancel
            </Button>
            <Button
              w={'100%'}
              loading={props.isLoading}
              color={'blue'}
              onClick={() => props.onPublish?.()}
            >
              Publish
            </Button>
          </Flex>
          <Flex w={'100%'} align={'center'} direction={'column'}>
            <Text c={'dark'} size="sm">
              By publishing you accept our{' '}
              <Anchor
                href="https://forge-gg.gitbook.io/the-fine-print"
                target="_blank"
                underline="hover"
                c={'blue'}
              >
                Terms of Services
              </Anchor>
            </Text>
          </Flex>
        </Flex>
      </Modal>
    </>
  );
};

import { organizationService } from './api-client/organization-service';
import { localStorageService } from './local-storage-service';

class SetupService {
  public async setUpOrganizations(email: string) {
    const organizations =
      await organizationService.getOrganizationsByEmail(email);
    if (!organizations || organizations.length === 0) {
      return;
    }
    const selectedOrganization = localStorageService.getSelectedOrganization();
    if (!selectedOrganization) {
      const newSelectedOrganizations = organizations.sort((a, b) =>
        a.partner.localeCompare(b.partner)
      )[0];
      localStorageService.setSelectedOrganization(newSelectedOrganizations);
      return newSelectedOrganizations;
    }
    return selectedOrganization;
  }
}

export const setupService = new SetupService();

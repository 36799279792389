import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useContext, useState } from 'react';
import { GlobalContext } from '../../../context/GlobalContext';
import { FeatureFlags } from '../../../data/feature-flags';
import { MessageStatus } from '../../../data/notify-options';
import { ReferralPoints } from '../../../data/referral-points';
import { useDataFetcher } from '../../../hooks/use-data-fetcher';
import { useNotification } from '../../../hooks/use-notification';
import { usePublishStatus } from '../../../hooks/use-publishing-status';
import { ssPartnerService } from '../../../services/api-client/ss-partner-service';

export const useReferralPointsIntegrations = (): [
  ReferralPoints | undefined,
  boolean,
  boolean,
  (referralPoints: ReferralPoints) => Promise<void>,
] => {
  const notification = useNotification();
  const {
    selectedCampaignData,
    selectedOrganization,
    selectedCampaignDataTrigger,
  } = useContext(GlobalContext);
  const isFeatureConfigActive = useFeatureFlagEnabled(
    FeatureFlags.ShowFeaturesSection
  );
  const [savingReferralPoints, setSavingReferralPoints] =
    useState<boolean>(false);

  const [activatePublishIfNoErrors] = usePublishStatus();
  const [referralPoints, loadingReferralPoints] =
    useDataFetcher<ReferralPoints>({
      serviceCall() {
        if (selectedOrganization && selectedCampaignData) {
          return ssPartnerService.getRefererPoints(
            selectedCampaignData.campaign_id,
            selectedOrganization.partnerSlug
          );
        }
      },
      dependencies: [selectedCampaignData, selectedCampaignDataTrigger],
      conditionForExecution: isFeatureConfigActive,
    });

  const handleReferralPointsSave = async (referralPoints: ReferralPoints) => {
    if (!selectedCampaignData || !selectedOrganization) {
      return;
    }
    try {
      setSavingReferralPoints(true);
      await ssPartnerService.saveReferralPoints(
        referralPoints,
        selectedCampaignData.campaign_id,
        selectedOrganization.partnerSlug
      );
      activatePublishIfNoErrors();
    } catch (error: any) {
      notification.notifyApiError({
        status: MessageStatus.Error,
        title: 'Referral Points Error',
        description: 'An error occurred while saving the referral points',
        response: error.response,
      });
    } finally {
      setSavingReferralPoints(false);
    }
  };

  return [
    referralPoints,
    loadingReferralPoints,
    savingReferralPoints,
    handleReferralPointsSave,
  ];
};

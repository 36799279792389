import { useContext, useState } from 'react';
import { GlobalContext } from '../../../context/GlobalContext';
import { MessageStatus } from '../../../data/notify-options';
import { useNotification } from '../../../hooks/use-notification';
import { ssPartnerService } from '../../../services/api-client/ss-partner-service';

export const useListStatusChangeIntegration = (): [
  (value: boolean) => Promise<void>,
  boolean,
] => {
  const notification = useNotification();
  const {
    selectedOrganization,
    selectedCampaignOption,
    campaignOptionsTrigger,
    setCampaignOptionsTrigger,
  } = useContext(GlobalContext);
  const [changingCampaignStatus, setChangingCampaignStatus] =
    useState<boolean>(false);

  const handleConfirmStatusChange = async (value: boolean) => {
    try {
      if (!selectedOrganization || !selectedCampaignOption) {
        return;
      }
      setChangingCampaignStatus(true);
      if (value) {
        await ssPartnerService.list(
          selectedOrganization.partnerSlug,
          selectedCampaignOption.draft_campaign_id
        );
      } else {
        await ssPartnerService.unList(
          selectedOrganization.partnerSlug,
          selectedCampaignOption.draft_campaign_id
        );
      }
      setCampaignOptionsTrigger(!campaignOptionsTrigger);
    } catch (error: any) {
      notification.notifyApiError({
        status: MessageStatus.Error,
        title: 'Error',
        description: `Error setting campaign live`,
        response: error?.response,
      });
    } finally {
      setChangingCampaignStatus(false);
    }
  };

  return [handleConfirmStatusChange, changingCampaignStatus];
};

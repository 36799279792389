import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useContext, useState } from 'react';
import { GlobalContext } from '../../../context/GlobalContext';
import { FeatureFlags } from '../../../data/feature-flags';
import { NftMultiplier } from '../../../data/nft-multiplier';
import { MessageStatus } from '../../../data/notify-options';
import { useDataFetcher } from '../../../hooks/use-data-fetcher';
import { useNotification } from '../../../hooks/use-notification';
import { usePublishStatus } from '../../../hooks/use-publishing-status';
import { ssPartnerService } from '../../../services/api-client/ss-partner-service';

export const useNftMultiplierIntegrations = (): [
  NftMultiplier | undefined,
  boolean,
  boolean,
  (nftMultiplier: NftMultiplier) => Promise<void>,
] => {
  const [activatePublishIfNoErrors] = usePublishStatus();
  const notification = useNotification();
  const isFeatureConfigActive = useFeatureFlagEnabled(
    FeatureFlags.ShowFeaturesSection
  );
  const {
    selectedCampaignData,
    selectedCampaignDataTrigger,
    selectedOrganization,
  } = useContext(GlobalContext);

  const [savingNftMultiplier, setSavingNftMultiplier] =
    useState<boolean>(false);
  const [nftMultiplier, loadingNftMultiplier] = useDataFetcher({
    serviceCall() {
      if (selectedOrganization && selectedCampaignData) {
        return ssPartnerService.getNftMultiplier(
          selectedOrganization.partnerSlug,
          selectedCampaignData.campaign_id
        );
      }
    },
    dependencies: [selectedCampaignData, selectedCampaignDataTrigger],
    conditionForExecution: isFeatureConfigActive,
  });

  const handelNftMultiplierSave = async (nftMultiplier: NftMultiplier) => {
    if (!selectedCampaignData || !selectedOrganization) {
      return;
    }
    try {
      setSavingNftMultiplier(true);
      await ssPartnerService.saveNftMultiplier(
        nftMultiplier,
        selectedCampaignData.campaign_id,
        selectedOrganization.partnerSlug
      );
      activatePublishIfNoErrors();
    } catch (error: any) {
      notification.notifyApiError({
        status: MessageStatus.Error,
        title: 'NFT Multiplier Error',
        description: 'An error occurred while saving the NFT multiplier',
        response: error.response,
      });
    } finally {
      setSavingNftMultiplier(false);
    }
  };

  return [
    nftMultiplier,
    loadingNftMultiplier,
    savingNftMultiplier,
    handelNftMultiplierSave,
  ];
};

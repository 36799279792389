import { Image } from '@mantine/core';
import {
  AiOutlineAntDesign,
  AiOutlineApi,
  AiOutlineSearch,
  AiOutlineUsergroupAdd,
  AiOutlineUserSwitch,
} from 'react-icons/ai';
import {
  BiGift,
  BiHomeSmile,
  BiMoneyWithdraw,
  BiSolidReport,
} from 'react-icons/bi';
import { FaDiscord, FaRocket, FaTelegramPlane } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import { TbListDetails } from 'react-icons/tb';
import { LocalImages } from '../assets/images';
import { NavLink } from '../components/SideBar/components/NavbarLink/NavbarLink';
import { Path } from '../data/paths';
import {
  getShowBillingPage,
  getShowDiscordPage,
  getShowKolsPage,
  getShowTelegramBotPage,
  staffCheck,
} from './feature-flags';

export const getDefaultLinks = (): NavLink[] => [
  {
    label: 'Home',
    slug: Path.Home,
    icon: <BiHomeSmile size={'1.25rem'} />,
    disable: false,
  },
  {
    label: 'Reports',
    slug: Path.Report,
    icon: <BiSolidReport size={'1.25rem'} />,
    disable: false,
  },
  {
    label: 'Game',
    slug: Path.Game,
    icon: <AiOutlineAntDesign size={'1.25rem'} />,
    disable: false,
  },
  {
    label: 'Actions',
    slug: Path.Actions,
    icon: <FaRocket size={'1.25rem'} />,
    disable: false,
  },
  {
    label: 'Rewards',
    slug: Path.Rewards,
    icon: <BiGift size={'1.25rem'} />,
    disable: false,
    withSeparatorInBottom: true,
  },
  {
    label: 'Telegram',
    slug: Path.TelegramBot,
    icon: <FaTelegramPlane size={'1.25rem'} />,
    disable: !getShowTelegramBotPage(),
  },
  {
    label: 'Tweets',
    slug: Path.Tweets,
    icon: <FaXTwitter size={'1.25rem'} />,
    disable: false,
    withSeparatorInBottom: !getShowDiscordPage(),
  },
  {
    label: 'Discord',
    slug: Path.Discord,
    icon: <FaDiscord size={'1.25rem'} />,
    disable: !getShowDiscordPage(),
    withSeparatorInBottom: true,
  },
  {
    label: 'User Lookup',
    slug: Path.UserLookup,
    icon: <AiOutlineSearch size={'1.25rem'} />,
    disable: false,
  },
  {
    label: 'API',
    slug: Path.Api,
    icon: <AiOutlineApi size={'1.25rem'} />,
    disable: false,
    withSeparatorInBottom: true,
  },
  {
    label: 'KOLVerse',
    slug: Path.Kols,
    icon: <Image src={LocalImages.kolverse} w={'1.25rem'} />,
    disable: !getShowKolsPage(),
    withSeparatorInBottom: true,
  },
  {
    label: 'Organizations',
    slug: Path.OrganizationManagement,
    icon: <TbListDetails size={'1.25rem'} />,
    disable: !staffCheck(),
  },
  {
    label: 'Billing',
    slug: Path.Billing,
    icon: <BiMoneyWithdraw size={'1.25rem'} />,
    disable: !getShowBillingPage(),
    position: 'bottom',
  },
  {
    label: 'Team',
    slug: Path.Collaborators,
    icon: <AiOutlineUsergroupAdd size={'1.25rem'} />,
    disable: false,
  },
  {
    label: 'Staff',
    slug: Path.Staff,
    icon: <AiOutlineUserSwitch size={'1.25rem'} />,
    disable: !staffCheck(),
  },
];

import { Loader } from '@mantine/core';
import posthog from 'posthog-js';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { GlobalContext } from '../../context/GlobalContext';
import { invitationService } from '../../services/api-client/invitation-service';
import { ssPartnerService } from '../../services/api-client/ss-partner-service';
import { authentication } from '../../services/authentication';
import { setupService } from '../../services/setup-service';

const Page = () => {
  const {
    setCampaignOptions,
    setSelectCampaignOption,
    setSelectedOrganization,
    setOrganizationsTrigger,
    organizationsTrigger,
  } = useContext(GlobalContext);

  const navigate = useNavigate();
  useEffect(() => {
    const completeLogin = async () => {
      try {
        await authentication.handleRedirectCallback();
        const user = authentication.getUser();
        if (!user || !user.email) {
          authentication.logout('invalid_user');
          return;
        }
        posthog.identify(user?.sub, { email: user?.email, name: user?.email });
        const selectedOrganization = await setupService.setUpOrganizations(
          user.email
        );
        if (!selectedOrganization) {
          authentication.logout('invalid_user');
          return;
        }
        await invitationService.setInvitationActive(user.email);
        const campaignOptions = await ssPartnerService.getDraftCampaigns(
          selectedOrganization.partnerSlug
        );
        setOrganizationsTrigger(!organizationsTrigger);
        setSelectedOrganization(selectedOrganization);
        setSelectCampaignOption(campaignOptions[0]);
        setCampaignOptions(campaignOptions);
        navigate('/home');
      } catch (err: any) {
        authentication.logout(err.error);
      }
    };
    completeLogin();
  }, [navigate]);

  return (
    <div>
      <Loader size="xl" />
    </div>
  );
};

export const LoginCallbackPage = Page;

export interface NotifyOptions {
  status: MessageStatus;
  title: string;
  description: string;
  position?: ToastPositions;
  response?: any;
}

export enum MessageStatus {
  Warning = 'warning',
  Loading = 'loading',
  Info = 'info',
  Error = 'error',
  Success = 'success',
}

export enum ToastPositions {
  Top = 'top',
  TopRight = 'top-right',
  TopLeft = 'top-left',
  Bottom = 'bottom',
  BottomRight = 'bottom-right',
  BottomLeft = 'bottom-left',
}

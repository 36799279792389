import { Auth0Provider } from '@auth0/auth0-react';
import { ChakraProvider } from '@chakra-ui/react';
import '@mantine/carousel/styles.css';
import { MantineProvider } from '@mantine/core';
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import * as Sentry from '@sentry/react';
import { PostHogProvider } from 'posthog-js/react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { configuration } from './configuration';
import './index.scss';

import GlobalProvider from './context/GlobalContext';
import { Kernel } from './Kernel';
import reportWebVitals from './reportWebVitals';

Sentry.init({
  dsn: configuration.sentry.sentrySpaUrl,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  profilesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <PostHogProvider
    apiKey={configuration.posthog.apiKey}
    options={{ api_host: configuration.posthog.apiHost }}
  >
    <Auth0Provider
      domain={configuration.userRegistry.domain}
      clientId={configuration.userRegistry.clientId}
      useRefreshTokens={true}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: configuration.userRegistry.audience,
      }}
    >
      <MantineProvider>
        <BrowserRouter>
          <ChakraProvider>
            <GlobalProvider>
              <Kernel />
            </GlobalProvider>
          </ChakraProvider>
        </BrowserRouter>
      </MantineProvider>
    </Auth0Provider>
  </PostHogProvider>
);

reportWebVitals();

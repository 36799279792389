import { useContext, useState } from 'react';
import { GlobalContext } from '../../../context/GlobalContext';
import { MessageStatus } from '../../../data/notify-options';
import {
  NftContract,
  NftContractAdditionData,
} from '../../../data/ntf-contract';
import { useDataFetcher } from '../../../hooks/use-data-fetcher';
import { useNotification } from '../../../hooks/use-notification';
import { usePublishStatus } from '../../../hooks/use-publishing-status';
import { blockchainsService } from '../../../services/api-client/blockchains-service';
import { ssPartnerService } from '../../../services/api-client/ss-partner-service';

export const useNftContractsIntegration = (): [
  NftContract[] | undefined,
  boolean,
  boolean,
  boolean,
  boolean,
  string[] | undefined,
  (nftContract: NftContractAdditionData) => Promise<void>,
  (nftContract: NftContract) => Promise<void>,
] => {
  const notification = useNotification();
  const [nftContractsTrigger, setNftContractsTrigger] = useState(false);
  const [removingNftContract, setRemovingNftContract] = useState(false);
  const { selectedCampaignData, selectedOrganization } =
    useContext(GlobalContext);
  const [activatePublishIfNoErrors] = usePublishStatus();
  const [addingNftContract, setAddingNftContract] = useState(false);

  const [blockchains, loadingBlockchains] = useDataFetcher<string[]>({
    serviceCall() {
      return blockchainsService.getBlockchains();
    },
    onError(error) {
      notification.notifyApiError({
        status: MessageStatus.Error,
        title: 'Error',
        description: 'Error fetching blockchains',
        response: error.response,
      });
    },
  });
  const [nftContracts, loadingNftContracts] = useDataFetcher<NftContract[]>({
    serviceCall() {
      if (selectedOrganization && selectedCampaignData) {
        return ssPartnerService.getNftContracts(
          selectedOrganization.partnerSlug,
          selectedCampaignData.campaign_id
        );
      }
    },
    onError(error) {
      notification.notifyApiError({
        status: MessageStatus.Error,
        title: 'Error',
        description: 'Error fetching nft contracts',
        response: error.response,
      });
    },
    dependencies: [selectedCampaignData, nftContractsTrigger, blockchains],
    conditionForExecution: !!blockchains,
  });

  const handleNftContractSave = async (
    nftContract: NftContractAdditionData
  ) => {
    if (!selectedOrganization || !selectedCampaignData) {
      return;
    }
    try {
      setAddingNftContract(true);
      await ssPartnerService.addNftContract(
        selectedOrganization.partnerSlug,
        selectedCampaignData.campaign_id,
        nftContract
      );
      setNftContractsTrigger(!nftContractsTrigger);
      activatePublishIfNoErrors();
    } catch (error: any) {
      notification.notifyApiError({
        status: MessageStatus.Error,
        title: 'Error',
        description: 'Error saving nft contract',
        response: error.response,
      });
    } finally {
      setAddingNftContract(false);
    }
  };

  const handleContractRemove = async (nftContract: NftContract) => {
    if (!selectedOrganization || !selectedCampaignData) {
      return;
    }
    try {
      setRemovingNftContract(true);
      await ssPartnerService.removeNftContract(
        selectedOrganization.partnerSlug,
        selectedCampaignData.campaign_id,
        nftContract
      );
      setNftContractsTrigger(!nftContractsTrigger);
      activatePublishIfNoErrors();
    } catch (error: any) {
      notification.notifyApiError({
        status: MessageStatus.Error,
        title: 'Error',
        description: 'Error saving nft contract',
        response: error.response,
      });
    } finally {
      setRemovingNftContract(false);
    }
  };

  return [
    nftContracts,
    loadingNftContracts,
    addingNftContract,
    removingNftContract,
    loadingBlockchains,
    blockchains,
    handleNftContractSave,
    handleContractRemove,
  ];
};

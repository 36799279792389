import { Quest } from '../data/quest';
import { QuestTemplate } from '../data/templates';

export const preSetOrder = (quests: Quest[]): Quest[] => {
  const newQuests = [...quests].map((quest, index) => {
    quest.orderNo = index + 1;
    return quest;
  });
  return newQuests;
};

export const getOrderByActive = (activeFirst: boolean, quests: Quest[]) => {
  if (activeFirst) {
    return [...quests].sort(
      (a, b) => (b.enabled ? 1 : 0) - (a.enabled ? 1 : 0)
    );
  }
  return quests;
};

export const getQuestNumber = (
  quests: Quest[],
  selectedTemplate?: QuestTemplate
): number => {
  if (!quests) {
    return 1;
  }
  if (!selectedTemplate) {
    return 1;
  }
  const matchingQuests = quests.filter(
    quest => quest.template === selectedTemplate.template_name
  );

  return matchingQuests.length + 1;
};

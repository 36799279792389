import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { Campaign } from '../data/campaign';
import { CampaignOption } from '../data/campaign-option';
import { CampaignPublishStatus } from '../data/campaign-publish-status';
import { MessageStatus } from '../data/notify-options';
import { Organization } from '../data/organization';
import { noContentPages } from '../data/paths';
import { useDataFetcher } from '../hooks/use-data-fetcher';
import { useNotification } from '../hooks/use-notification';
import { campaignService } from '../services/api-client/campaign-service';
import { organizationService } from '../services/api-client/organization-service';
import { ssPartnerService } from '../services/api-client/ss-partner-service';
import { authentication } from '../services/authentication';
import { campaignDataService } from '../services/campaign-data-service';
import { localStorageService } from '../services/local-storage-service';

interface GlobalProviderProps {
  children: ReactNode;
}

export interface GlobalState {
  selectedCampaignOption?: CampaignOption;
  setSelectCampaignOption: Dispatch<SetStateAction<CampaignOption | undefined>>;
  setCampaignOptionsTrigger: Dispatch<SetStateAction<boolean | undefined>>;
  campaignOptionsTrigger?: boolean;
  campaignOptions?: CampaignOption[];
  setCampaignOptions: Dispatch<SetStateAction<CampaignOption[] | undefined>>;
  loadingCampaignsOptions?: boolean;
  selectedCampaignData?: Campaign;
  setSelectedCampaignData: Dispatch<SetStateAction<Campaign | undefined>>;
  loadingSelectedCampaignData?: boolean;
  setSelectedCampaignDataTrigger: Dispatch<SetStateAction<boolean | undefined>>;
  selectedCampaignDataTrigger?: boolean;
  selectedOrganization?: Organization;
  setSelectedOrganization: Dispatch<SetStateAction<Organization | undefined>>;
  loadingOrganizations?: boolean;
  organizations?: Organization[];
  setOrganizationsTrigger: Dispatch<SetStateAction<boolean | undefined>>;
  organizationsTrigger?: boolean;
  setAddingCampaign: Dispatch<SetStateAction<boolean | undefined>>;
  addingCampaign?: boolean;
  campaignPublishStatus?: CampaignPublishStatus;
  setCampaignPublishStatus: Dispatch<
    SetStateAction<CampaignPublishStatus | undefined>
  >;
  publishingCampaign?: boolean;
  setPublishingCampaign: Dispatch<SetStateAction<boolean | undefined>>;
}

const initialGlobalContextValues: GlobalState = {
  setCampaignOptionsTrigger: () => null,
  setSelectCampaignOption: () => null,
  setSelectedCampaignData: () => null,
  setSelectedCampaignDataTrigger: () => null,
  setSelectedOrganization: () => null,
  setCampaignOptions: () => null,
  setAddingCampaign: () => null,
  setCampaignPublishStatus: () => null,
  setPublishingCampaign: () => null,
  setOrganizationsTrigger: () => null,
};

export const GlobalContext = createContext<GlobalState>(
  initialGlobalContextValues
);

const GlobalProvider = (props: GlobalProviderProps) => {
  const user = authentication.getUser();
  const notification = useNotification();
  const [selectedOrganization, setSelectedOrganization] = useState<
    Organization | undefined
  >(localStorageService.getSelectedOrganization() ?? undefined);
  const [campaignOptionsTrigger, setCampaignOptionsTrigger] =
    useState<boolean>();
  const [selectedCampaignDataTrigger, setSelectedCampaignDataTrigger] =
    useState<boolean>();
  const [selectedCampaignOption, setSelectCampaignOption] =
    useState<CampaignOption>();
  const [addingCampaign, setAddingCampaign] = useState<boolean>();
  const [campaignPublishStatus, setCampaignPublishStatus] =
    useState<CampaignPublishStatus>();
  const [publishingCampaign, setPublishingCampaign] = useState<boolean>();
  const [organizationsTrigger, setOrganizationsTrigger] = useState<boolean>();
  const [campaignOptions, loadingCampaignsOptions, setCampaignOptions] =
    useDataFetcher({
      serviceCall() {
        if (selectedOrganization) {
          return ssPartnerService.getDraftCampaigns(
            selectedOrganization.partnerSlug
          );
        }
      },
      dependencies: [selectedOrganization, campaignOptionsTrigger],
      conditionForExecution:
        !!selectedOrganization &&
        !noContentPages.includes(window.location.pathname),
      onFinish(response) {
        if (!response || response.length === 0) {
          setSelectedCampaignData(undefined);
          return;
        }
        if (!selectedCampaignOption) {
          setSelectCampaignOption(response[0]);
          return;
        }
      },
      onError(error: any) {
        notification.notifyApiError({
          status: MessageStatus.Error,
          title: 'Error',
          description: 'Error fetching campaigns options',
          response: error?.response,
        });
      },
    });

  const [
    selectedCampaignData,
    loadingSelectedCampaignData,
    setSelectedCampaignData,
  ] = useDataFetcher({
    serviceCall() {
      if (selectedCampaignOption) {
        return campaignService.getCampaignById(
          selectedCampaignOption.draft_campaign_id
        );
      }
    },
    dependencies: [selectedCampaignOption, selectedCampaignDataTrigger],
    conditionForExecution: !!selectedCampaignOption,
    onError(error: any) {
      notification.notifyApiError({
        status: MessageStatus.Error,
        title: 'Error',
        description: 'Error fetching campaign data',
        response: error?.response,
      });
    },
  });

  const [organizations, loadingOrganizations] = useDataFetcher({
    serviceCall() {
      if (user && user.email) {
        return organizationService.getOrganizationsByEmail(user.email);
      }
    },
    dependencies: [organizationsTrigger],
    conditionForExecution: !noContentPages.includes(window.location.pathname),
    onError(error: any) {
      notification.notifyApiError({
        status: MessageStatus.Error,
        title: 'Error',
        description: 'Error fetching organizations',
        response: error?.response,
      });
    },
  });

  useEffect(() => {
    if (selectedCampaignData) {
      const campaignPublishableStatus =
        campaignDataService.getCampaignPublishableStatus(selectedCampaignData);
      setCampaignPublishStatus({
        ...campaignPublishableStatus,
        canPublish: selectedCampaignOption?.unpublished_changes ?? false,
      });
      return;
    }
    setCampaignPublishStatus({
      canPublish: false,
    });
  }, [selectedCampaignData]);

  return (
    <GlobalContext.Provider
      value={{
        organizations,
        loadingOrganizations,
        setOrganizationsTrigger,
        organizationsTrigger,
        campaignOptions,
        loadingCampaignsOptions,
        setCampaignOptionsTrigger,
        setSelectCampaignOption,
        setSelectedCampaignData,
        setCampaignOptions,
        campaignOptionsTrigger,
        selectedCampaignOption,
        selectedCampaignData,
        loadingSelectedCampaignData,
        setSelectedCampaignDataTrigger,
        selectedCampaignDataTrigger,
        setSelectedOrganization,
        selectedOrganization,
        setAddingCampaign,
        addingCampaign,
        campaignPublishStatus: campaignPublishStatus,
        setCampaignPublishStatus: setCampaignPublishStatus,
        publishingCampaign,
        setPublishingCampaign,
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};

export default GlobalProvider;

import { GamePageTabs } from '../components/Game/components/data/tabs-data';
import { Path } from './paths';

export interface MetadataDirectedFields {
  campaign_id: string;
  //Campaign Mechanics
  quest_number: number;
  partner_name: string;
  //Website
  website_description: string;
  website_link: string;

  //telegram
  telegram_handle: string;
  game_platform: string;
  telegram_channel_id: string;
  telegram_channel_name: string;

  //YouTube
  youtube_handle: string;
  // youtube_account_name: string;

  //twitter
  twitter_handle: string;
  twitter_user_id: string;

  //discord
  // channel_name: string;
  discord_handle: string;
}

export interface NotUserVisibleFields {
  campaign_id: string;
  quest_number: number;
}

export const defaultNotUserVisibleFields: NotUserVisibleFields = {
  campaign_id: '',
  quest_number: 1,
};

export const defaultMetadataDirectedFields: MetadataDirectedFields = {
  campaign_id: '',
  telegram_channel_id: '',
  telegram_channel_name: '',
  quest_number: 1,
  website_description: '',
  telegram_handle: '',
  twitter_handle: '',
  website_link: '',
  partner_name: '',
  discord_handle: '',
  youtube_handle: '',
  twitter_user_id: '',
  game_platform: '',
};

export interface QuestFieldMetadata {
  inheritedFromPage?: string;
  displayName: string;
  description: string;
  value: any;
  fieldKey: string;
  isInherited: boolean;
  inheritedFromTitle: string;
  inheritedFromUrl: string; //https://dash.forge.gg/game-customization#game-platform
  type: 'calendar' | 'text' | 'boolean' | 'textarea';
}

export const inheritedVariablesByPages = new Map<string, string>([
  ['game_platform', Path.Api],
  ['telegram_handle', `${Path.Game}?section=${GamePageTabs.Socials}`],
  ['twitter_handle', `${Path.Game}?section=${GamePageTabs.Socials}`],
  ['youtube_handle', `${Path.Game}?section=${GamePageTabs.Socials}`],
  ['discord_handle', `${Path.Game}?section=${GamePageTabs.Socials}`],
  ['website_link', `${Path.Game}?section=${GamePageTabs.ExtraData}`],
  ['telegram_channel_id', Path.TelegramBot],
  ['telegram_channel_name', Path.TelegramBot],
]);

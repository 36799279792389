import * as Sentry from '@sentry/react';
import { configuration } from '../configuration';
import { MessageStatus, NotifyOptions } from '../data/notify-options';
import { authentication } from './authentication';

class SentryService {
  private _getSeverityFromStatus(status: MessageStatus): Sentry.SeverityLevel {
    switch (status) {
      case MessageStatus.Error:
        return 'error';
      case MessageStatus.Warning:
        return 'warning';
      case MessageStatus.Info:
        return 'info';
      case MessageStatus.Success:
        return 'info';
      default:
        return 'info';
    }
  }

  public sendSentryEvent(notifyOptions: NotifyOptions) {
    if (
      ![MessageStatus.Error, MessageStatus.Warning].includes(
        notifyOptions.status
      ) ||
      configuration.environment !== 'production'
    ) {
      return;
    }
    const currentUser = authentication.getUser();
    Sentry.captureEvent({
      message: notifyOptions.description,
      level: this._getSeverityFromStatus(notifyOptions.status),
      environment: configuration.environment,
      user: { email: currentUser?.email, username: currentUser?.nickname },
    });
  }
}

export const sentryService = new SentryService();

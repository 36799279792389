import { Flex } from '@mantine/core';
import { useContext, useState } from 'react';
import { GamePageTabs } from '../../components/Game/components/data/tabs-data';
import GameEditionSection from '../../components/Game/GameEditionSection';
import { GlobalContext } from '../../context/GlobalContext';
import { getSkeletons } from '../../helper/skeletons';
import { withAuthProtection } from '../../services/protect-route-element';
import { useAssetsIntegration } from './hooks/use-assets-integration';
import { useCampaignIntegration } from './hooks/use-campaign-integration';
import { useDailyStreakIntegrations } from './hooks/use-daily-streak-integration';
import { useFeaturesIntegrations } from './hooks/use-features-integration';
import { useNftContractsIntegration } from './hooks/use-nft-contracts-integration';
import { useNftMultiplierIntegrations } from './hooks/use-nft-multiplier-integration';
import { useReferralPointsIntegrations } from './hooks/use-referral-points-integration';
import { useSocialMultiplierIntegration } from './hooks/use-social-multiplier-integration';
const Page = () => {
  const [addingDraft, handelAddNewDraft, savingCampaign, handleCampaignSave] =
    useCampaignIntegration();
  const [savingAssets, handelAssetsSave] = useAssetsIntegration();
  const [
    nftMultiplier,
    loadingNftMultiplier,
    savingNftMultiplier,
    handelNftMultiplierSave,
  ] = useNftMultiplierIntegrations();
  const [
    dailyStreaks,
    loadingDailyStreaks,
    savingDailyStreak,
    handleSaveDailyStreaks,
  ] = useDailyStreakIntegrations();
  const [
    referralPoints,
    loadingReferralPoints,
    savingReferralPoints,
    handleReferralPointsSave,
  ] = useReferralPointsIntegrations();
  const [
    featuresData,
    loadingFeaturesData,
    featuresLoadingStates,
    handleFeatureActivationChange,
  ] = useFeaturesIntegrations();
  const [
    socialMultiplier,
    loadingSocialMultiplier,
    savingSocialMultiplier,
    handleSocialMultiplierSave,
  ] = useSocialMultiplierIntegration();

  const [
    nftContracts,
    loadingNftContracts,
    addingNftContract,
    removingNftContract,
    loadingBlockchains,
    blockchains,
    handleNftContractSave,
    handleContractRemove,
  ] = useNftContractsIntegration();

  const params = new URLSearchParams(window.location.search);
  const sectionFromNavigation = params.get('section');
  const {
    selectedCampaignData,
    loadingSelectedCampaignData,
    addingCampaign,
    publishingCampaign,
  } = useContext(GlobalContext);

  const [selectedTab, setSelectedTab] = useState<GamePageTabs>(
    (sectionFromNavigation as GamePageTabs) ?? GamePageTabs.BasicInformation
  );

  if (loadingSelectedCampaignData || publishingCampaign) {
    return (
      <Flex
        align={'center'}
        gap={'1rem'}
        direction={'column'}
        justify={'center'}
        h={'100%'}
        w={'100%'}
        p={'2rem'}
      >
        {getSkeletons(10)}
      </Flex>
    );
  }

  return (
    <Flex
      direction={'column'}
      className="GamePage"
      w={'100%'}
      align={'center'}
      h={'100%'}
    >
      <GameEditionSection
        campaign={selectedCampaignData}
        addingDraft={addingDraft || addingCampaign}
        addNewDraft={handelAddNewDraft}
        featuresData={featuresData}
        featuresLoadingStates={featuresLoadingStates}
        onFeatureActivationChange={handleFeatureActivationChange}
        loadingFeaturesData={loadingFeaturesData}
        setSelectedTab={setSelectedTab}
        selectedTab={selectedTab}
        referralPoints={referralPoints}
        loadingReferralPoints={loadingReferralPoints}
        nftMultiplier={nftMultiplier}
        loadingNftMultiplier={loadingNftMultiplier}
        handleReferralPointsSave={handleReferralPointsSave}
        savingReferralPoints={savingReferralPoints}
        socialMultiplier={socialMultiplier}
        loadingSocialMultiplier={loadingSocialMultiplier}
        handleSocialMultiplierSave={handleSocialMultiplierSave}
        savingSocialMultiplier={savingSocialMultiplier}
        handleCampaignSave={handleCampaignSave}
        savingCampaign={savingCampaign}
        handelAssetsSave={handelAssetsSave}
        savingAssets={savingAssets}
        onNftMultiplierSave={handelNftMultiplierSave}
        savingNftMultiplier={savingNftMultiplier}
        dailyStreaks={dailyStreaks}
        loadingDailyStreaks={loadingDailyStreaks}
        savingDailyStreak={savingDailyStreak}
        handleSaveDailyStreaks={handleSaveDailyStreaks}
        nftContracts={nftContracts}
        loadingNftContracts={loadingNftContracts}
        addingNftContract={addingNftContract}
        handleNftContractSave={handleNftContractSave}
        removingNftContract={removingNftContract}
        handleContractRemove={handleContractRemove}
        loadingBlockchains={loadingBlockchains}
        blockchains={blockchains}
      />
    </Flex>
  );
};

export const GamePage = withAuthProtection(Page);

import { ActionIcon, Anchor, Card, Flex, Text } from '@mantine/core';
import { IconTrash } from '@tabler/icons-react';
import { FunctionComponent } from 'react';
import { NftContract } from '../../../../../data/ntf-contract';

export interface NftContractElementProps {
  contract: NftContract;
  onRemove?: (contract: NftContract) => void;
  removingNftContract?: boolean;
}

const NftContractElement: FunctionComponent<
  NftContractElementProps
> = props => {
  const { contract, onRemove, removingNftContract } = props;
  return (
    <Card
      key={`contract-element-${contract.address}`}
      p="md"
      withBorder={true}
      radius="md"
      shadow="sm"
    >
      <Flex direction="column" gap="md">
        <Flex direction={'row'} justify={'space-between'}>
          <Text fw={500} size={'1rem'}>
            <Text size="sm">
              <strong>Collection name:</strong> {contract.collection_name}
            </Text>
          </Text>
          <ActionIcon
            loading={removingNftContract}
            color="red"
            onClick={() => onRemove?.(contract)}
          >
            <IconTrash />
          </ActionIcon>
        </Flex>

        <Flex direction="row" w={'100%'} gap="md" justify={'space-between'}>
          <Text size="sm">
            <strong>Blockchain:</strong> {contract.blockchain}
          </Text>
          <Text size="sm">
            <strong>Address:</strong> {contract.address}
          </Text>
          <Text size="sm">
            <strong>Collection URL:</strong>{' '}
            <Anchor
              href={contract.collection_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {contract.collection_url}
            </Anchor>
          </Text>
          <Text size="sm">
            <strong>Wallets supported:</strong>{' '}
            {contract.wallets_supported
              ? contract.wallets_supported.join(', ')
              : 'N/A'}
          </Text>
          <Text size="sm">
            <strong>Type:</strong> {contract.type}
          </Text>
        </Flex>
      </Flex>
    </Card>
  );
};

export default NftContractElement;

import { ActionIcon, Card, Flex, Text, Tooltip } from '@mantine/core';
import { FunctionComponent } from 'react';
import { IoRemove } from 'react-icons/io5';
import { Multiplier } from '../../../../../data/multiplier';
import AddMultiplierDialog from './AddMultiplierDialog';

export interface MultiplierElementProps {
  multiplier: Multiplier;
  isLastOne: boolean;
  onRemove?: () => void;
  handleElementAddition: (multiplier: Multiplier) => void;
  canAddMultiplier?: boolean;
  isDisabled: boolean;
  isError?: boolean;
}

const MultiplierElement: FunctionComponent<MultiplierElementProps> = props => {
  const {
    multiplier,
    isLastOne,
    canAddMultiplier,
    isDisabled,
    onRemove,
    handleElementAddition,
  } = props;

  const getManagementElements = () => {
    return (
      <Flex pl={'0.5rem'} justify={'center'} direction="column" gap="0.2rem">
        {canAddMultiplier && (
          <AddMultiplierDialog
            onSave={handleElementAddition}
            lastMultiplier={multiplier}
            isDisabled={isDisabled}
          />
        )}
        <Tooltip
          hidden={canAddMultiplier}
          label={
            canAddMultiplier
              ? ''
              : 'The max value of the last element has no limit'
          }
        >
          <ActionIcon disabled={isDisabled} color="red" size="sm">
            <IoRemove onClick={onRemove} />
          </ActionIcon>
        </Tooltip>
      </Flex>
    );
  };

  return (
    <Flex w={'100%'}>
      <Card w={'100%'} shadow="sm" bd={'1px solid #a8b2bc'} padding="lg">
        <Card.Section pl={'0.5rem'}>
          <Text size="md">{multiplier.description}</Text>
        </Card.Section>
        <Card.Section pl={'0.5rem'}>
          <Flex direction="row" gap="md">
            <Text size="sm" color="dimmed">
              Multiplier: {multiplier.multiplier}
            </Text>
            <Text size="sm" color="dimmed">
              Min: {multiplier.min}
            </Text>
            <Text size="sm" color="dimmed">
              Max: {multiplier.max ?? 'No limit'}
            </Text>
          </Flex>
        </Card.Section>
      </Card>
      {isLastOne && getManagementElements()}
    </Flex>
  );
};

export default MultiplierElement;

import { Button, Card, Flex, Grid, Text, TextInput } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { Multiplier } from '../../../../../data/multiplier';
import { SocialMultiplier } from '../../../../../data/social-multiplier';
import AddMultiplierDialog from './AddMultiplierDialog';
import MultiplierElement from './MultiplierElement';

export interface SocialMultiplierSectionProps {
  defaultValues?: SocialMultiplier;
  onSave: (socialMultiplier: SocialMultiplier) => void;
  savingSocialMultiplier: boolean;
  enableConfiguration: boolean;
}

const SocialMultiplierSection: React.FC<SocialMultiplierSectionProps> = ({
  defaultValues,
  onSave,
  savingSocialMultiplier,
  enableConfiguration,
}) => {
  const [socialMultiplier, setSocialMultiplier] = useState<SocialMultiplier>({
    description: defaultValues?.description || '',
    multiplier: defaultValues?.multiplier || [],
  });

  const [isModified, setIsModified] = useState(false);

  useEffect(() => {
    setSocialMultiplier({
      description: defaultValues?.description || '',
      multiplier: defaultValues?.multiplier || [],
    });
  }, [defaultValues]);

  const handleDescriptionChange = (value: string) => {
    setSocialMultiplier(prev => ({
      ...prev,
      description: value,
    }));
    setIsModified(true);
  };

  const handleSave = () => {
    onSave(socialMultiplier);
    setIsModified(false);
  };

  const isSaveDisabled = !isModified || !enableConfiguration;

  const handleElementRemove = (index: number) => {
    setSocialMultiplier(prev => ({
      ...prev,
      multiplier: prev.multiplier.filter((_, i) => i !== index),
    }));
    setIsModified(true);
  };

  const handleElementAddition = (multiplier: Multiplier) => {
    setSocialMultiplier(prev => ({
      ...prev,
      multiplier: [...prev.multiplier, multiplier],
    }));
    setIsModified(true);
  };

  const canAddMultiplier = () => {
    return (
      socialMultiplier.multiplier[socialMultiplier.multiplier.length - 1]
        .max !== null
    );
  };

  const getElements = () => {
    if (
      !socialMultiplier.multiplier ||
      socialMultiplier.multiplier.length === 0
    ) {
      return (
        <Flex justify="center" align="center" style={{ height: '100px' }}>
          <AddMultiplierDialog
            isDisabled={!enableConfiguration}
            onSave={handleElementAddition}
          />
        </Flex>
      );
    }
    return (
      <Grid>
        {socialMultiplier.multiplier.map((multiplierValue, index) => (
          <Grid.Col span={12} key={index}>
            <MultiplierElement
              multiplier={multiplierValue}
              isLastOne={index === socialMultiplier.multiplier.length - 1}
              isDisabled={!enableConfiguration}
              onRemove={() => handleElementRemove(index)}
              canAddMultiplier={canAddMultiplier()}
              handleElementAddition={handleElementAddition}
            />
          </Grid.Col>
        ))}
      </Grid>
    );
  };

  return (
    <Flex direction="column" gap="md">
      <TextInput
        label="Description"
        value={socialMultiplier.description ?? ''}
        onChange={event => handleDescriptionChange(event.currentTarget.value)}
        disabled={!enableConfiguration}
      />
      <Card shadow="sm" padding="lg" withBorder>
        <Card.Section>
          <Text pl={'1rem'} pt={'1rem'} size="md" mb="xs">
            Multipliers
          </Text>
        </Card.Section>
        {getElements()}
      </Card>
      <Button
        loading={savingSocialMultiplier}
        onClick={handleSave}
        disabled={isSaveDisabled}
      >
        Save
      </Button>
    </Flex>
  );
};

export default SocialMultiplierSection;

import { useContext, useState } from 'react';
import { GlobalContext } from '../../../context/GlobalContext';
import { MessageStatus } from '../../../data/notify-options';
import { useNotification } from '../../../hooks/use-notification';
import { ssPartnerService } from '../../../services/api-client/ss-partner-service';

export const useLiveStatusChanceIntegration = (): [
  (value: boolean) => Promise<void>,
  boolean,
] => {
  const notification = useNotification();
  const {
    selectedOrganization,
    selectedCampaignOption,
    campaignOptionsTrigger,
    setCampaignOptionsTrigger,
  } = useContext(GlobalContext);
  const [unPublishing, setUnPublishing] = useState<boolean>(false);
  const handleOnLiveToggleChange = async (value: boolean) => {
    try {
      if (!selectedOrganization || !selectedCampaignOption || value) {
        return;
      }
      setUnPublishing(true);
      await ssPartnerService.unPublishGame(
        selectedOrganization.partnerSlug,
        selectedCampaignOption.draft_campaign_id
      );
      setCampaignOptionsTrigger(!campaignOptionsTrigger);
    } catch (error: any) {
      notification.notifyApiError({
        status: MessageStatus.Error,
        title: 'Error',
        description: `Error toggling campaign live`,
        response: error?.response,
      });
    } finally {
      setUnPublishing(false);
    }
  };
  return [handleOnLiveToggleChange, unPublishing];
};

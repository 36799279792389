import {
  Button,
  Flex,
  MultiSelect,
  Text,
  Textarea,
  TextInput,
} from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { FunctionComponent, useEffect, useState } from 'react';
import { Campaign } from '../../../data/campaign';
import { gameModesData } from '../../../data/game-mode';
import { genresData, getSeparateElements } from '../../../data/genre';
import { isValidLink } from '../../../helper/validators';

export interface ExtraDataSectionProps {
  campaign?: Campaign;
  savingCampaign: boolean;
  onSave?: (campaign: Campaign) => void;
}

const ExtraDataSection: FunctionComponent<ExtraDataSectionProps> = props => {
  const { onSave, campaign, savingCampaign } = props;
  const [description, setDescription] = useState(
    props.campaign?.description ?? ''
  );
  const [genre, setGenre] = useState(props.campaign?.genre ?? '');
  const [website, setWebsite] = useState(
    props.campaign?.extra_data?.website ?? ''
  );
  const [playNow, setPlayNow] = useState(
    props.campaign?.extra_data?.play_now ?? ''
  );
  const [partnerName, setPartnerName] = useState(
    props.campaign?.extra_data?.partner_name ?? ''
  );
  const [endDate, setEndDate] = useState<Date | null>(
    props.campaign?.end_datetime ?? null
  );
  const [releaseDate, setReleaseDate] = useState(
    props.campaign?.extra_data?.release_date ?? ''
  );
  const [gameModes, setGameModes] = useState(
    props.campaign?.extra_data?.game_mode ?? ''
  );
  const [platform, setPlatform] = useState(
    props.campaign?.extra_data?.platform ?? ''
  );
  const [developer, setDeveloper] = useState(
    props.campaign?.extra_data?.developer ?? ''
  );
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    const isDataChanged =
      description !== (props.campaign?.description ?? '') ||
      genre !== (props.campaign?.genre ?? '') ||
      website !== (props.campaign?.extra_data?.website ?? '') ||
      playNow !== (props.campaign?.extra_data?.play_now ?? '') ||
      partnerName !== (props.campaign?.extra_data?.partner_name ?? '') ||
      new Date(endDate ?? '')?.toISOString() !==
        (new Date(props.campaign?.end_datetime ?? '')?.toISOString() ?? null) ||
      releaseDate !== (props.campaign?.extra_data?.release_date ?? '') ||
      gameModes !== (props.campaign?.extra_data?.game_mode ?? '') ||
      platform !== (props.campaign?.extra_data?.platform ?? '') ||
      developer !== (props.campaign?.extra_data?.developer ?? '');

    setIsDirty(isDataChanged);
  }, [
    description,
    genre,
    website,
    playNow,
    partnerName,
    endDate,
    releaseDate,
    gameModes,
    platform,
    developer,
    props.campaign,
  ]);

  const getEndDate = () => {
    if (!endDate) {
      return undefined;
    }
    const date = new Date(endDate);
    if (date?.toISOString() === '3000-01-01T03:00:00.000Z') {
      return undefined;
    }
    return date;
  };

  const handleSave = () => {
    if (!campaign) {
      return;
    }
    if (onSave) {
      const updatedCampaign: Campaign = {
        ...campaign,
        description,
        genre,
        end_datetime: endDate ?? null,
        extra_data: {
          ...campaign?.extra_data,
          website: website ?? '',
          play_now: playNow ?? '',
          partner_name: partnerName ?? '',
          release_date: releaseDate ?? '',
          game_mode: gameModes ?? '',
          platform: platform ?? '',
          developer: developer ?? '',
        },
      };
      onSave(updatedCampaign);
      setIsDirty(false);
    }
  };

  const getEndsToday = () => {
    if (!endDate) return null;
    const today = new Date();
    const date = new Date(endDate);
    const isToday =
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear();
    if (isToday) {
      return 'This campaign ends today';
    }
    return '';
  };

  const getDateError = () => {
    if (!endDate) return null;
    const today = new Date();
    const isPast =
      new Date(endDate ?? '') < new Date(today.setHours(0, 0, 0, 0));
    if (isPast) {
      return 'This campaign has already ended';
    }
    return null;
  };

  if (!campaign || !campaign?.extra_data) return <></>;
  return (
    <Flex gap={'1rem'} p={'1rem'} direction={'column'} w={'100%'}>
      <Flex direction={'column'} align={'flex-end'} w={'100%'} gap={'0.15rem'}>
        <Textarea
          w={'100%'}
          error={
            !description || description === '' ? 'This field is required' : null
          }
          defaultValue={description}
          required
          maxLength={1000}
          resize="vertical"
          label={'Game Description'}
          description={`
                    The description of the game that will be displayed in the loyalty program.
                    `}
          onChange={event => {
            setDescription(event.currentTarget.value);
          }}
        />
        {
          <Text size="xs" c={'#868e96'}>
            Limited to 1000 characters
          </Text>
        }
      </Flex>
      <MultiSelect
        label="Genre"
        description="Select the genre of your game."
        searchable
        data={genresData}
        defaultValue={getSeparateElements(genre) ?? []}
        w={'100%'}
        onChange={genres => {
          setGenre(genres.join(', '));
        }}
        required
        error={!genre || genre === '' ? 'This field is required' : null}
      />
      <TextInput
        w={'100%'}
        error={
          !isValidLink(website)
            ? `This field is required and must be a valid URL (e.g. https://example.com)`
            : null
        }
        required
        defaultValue={website}
        label={'Website'}
        description={'Set you game website for the users to access it easily.'}
        onChange={event => {
          setWebsite(event.currentTarget.value);
        }}
      />
      <TextInput
        w={'100%'}
        error={!platform || platform === '' ? 'This field is required' : null}
        required
        defaultValue={platform}
        label={'Platform'}
        description={
          'Set you game platform for the users to know the options they have to play your game.'
        }
        onChange={event => {
          setPlatform(event.currentTarget.value);
        }}
      />
      <TextInput
        w={'100%'}
        error={!developer || developer === '' ? 'This field is required' : null}
        required
        defaultValue={developer}
        label={'Developer'}
        description={'Set the game developer.'}
        onChange={event => {
          setDeveloper(event.currentTarget.value);
        }}
      />
      <MultiSelect
        label="Game Mode"
        description="Select your game mode."
        defaultValue={getSeparateElements(gameModes) ?? []}
        data={gameModesData}
        w={'100%'}
        onChange={gameModes => {
          setGameModes(gameModes.join(', '));
        }}
        required
        error={!gameModes || gameModes === '' ? 'This field is required' : null}
      />
      <TextInput
        w={'100%'}
        error={
          !releaseDate || releaseDate === '' ? 'This field is required' : null
        }
        required
        defaultValue={releaseDate}
        label={'Release Date'}
        description={'Set the release date of your campaign.'}
        onChange={event => {
          setReleaseDate(event.currentTarget.value);
        }}
      />
      <DateInput
        w={'100%'}
        value={getEndDate()}
        labelProps={{ c: `${getEndsToday() ? 'orange' : ''}` }}
        label={`End Date ${getEndsToday() ? ` [${getEndsToday()}]` : ''}`}
        description={'Set the end date of your campaign.'}
        highlightToday
        error={getDateError()}
        onChange={date => {
          setEndDate(date);
        }}
      />
      <TextInput
        w={'100%'}
        error={
          !partnerName || partnerName === '' ? 'This field is required' : null
        }
        required
        defaultValue={partnerName}
        label={'Partner Name'}
        description={'Set the partner name of your game.'}
        onChange={event => {
          setPartnerName(event.currentTarget.value);
        }}
      />
      <TextInput
        w={'100%'}
        error={
          !playNow || playNow === '' || !isValidLink(playNow)
            ? `This field is required and must be a valid URL (e.g. https://example.com)`
            : null
        }
        required
        defaultValue={playNow}
        label={'Play Now Link'}
        description={
          'Link for users to download or play your game. If still in the pre-testing phase, link to your website.'
        }
        onChange={event => {
          setPlayNow(event.currentTarget.value);
        }}
      />
      <Button loading={savingCampaign} onClick={handleSave} disabled={!isDirty}>
        Save
      </Button>
    </Flex>
  );
};

export default ExtraDataSection;

import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useContext, useState } from 'react';
import { GlobalContext } from '../../../context/GlobalContext';
import { DailyStreak } from '../../../data/daily-streak';
import { FeatureFlags } from '../../../data/feature-flags';
import { MessageStatus } from '../../../data/notify-options';
import { useDataFetcher } from '../../../hooks/use-data-fetcher';
import { useNotification } from '../../../hooks/use-notification';
import { usePublishStatus } from '../../../hooks/use-publishing-status';
import { ssPartnerService } from '../../../services/api-client/ss-partner-service';

export const useDailyStreakIntegrations = (): [
  DailyStreak | undefined,
  boolean,
  boolean,
  (dailyStreaks: DailyStreak) => Promise<void>,
] => {
  const notification = useNotification();
  const [activatePublishIfNoErrors] = usePublishStatus();
  const {
    selectedCampaignData,
    selectedOrganization,
    selectedCampaignDataTrigger,
  } = useContext(GlobalContext);
  const isFeatureConfigActive = useFeatureFlagEnabled(
    FeatureFlags.ShowFeaturesSection
  );
  const [savingDailyStreak, setSavingDailyStreak] = useState<boolean>(false);

  const [dailyStreaks, loadingDailyStreaks] = useDataFetcher({
    serviceCall() {
      if (selectedOrganization && selectedCampaignData) {
        return ssPartnerService.getDailyStreaks(
          selectedOrganization.partnerSlug,
          selectedCampaignData.campaign_id
        );
      }
    },
    dependencies: [selectedCampaignData, selectedCampaignDataTrigger],
    conditionForExecution: isFeatureConfigActive,
  });

  const handleSaveDailyStreaks = async (dailyStreaks: DailyStreak) => {
    if (!selectedCampaignData || !selectedOrganization) {
      return;
    }
    try {
      setSavingDailyStreak(true);
      await ssPartnerService.saveDailyStreaks(
        dailyStreaks,
        selectedCampaignData.campaign_id,
        selectedOrganization.partnerSlug
      );
      activatePublishIfNoErrors();
    } catch (error: any) {
      notification.notifyApiError({
        status: MessageStatus.Error,
        title: 'Daily Streaks Error',
        description: 'An error occurred while saving the daily streaks',
        response: error.response,
      });
    } finally {
      setSavingDailyStreak(false);
    }
  };

  return [
    dailyStreaks,
    loadingDailyStreaks,
    savingDailyStreak,
    handleSaveDailyStreaks,
  ];
};

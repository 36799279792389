import { ActionIcon, Anchor, Text } from '@mantine/core';
import { FaEdit } from 'react-icons/fa';
import { NavigateFunction } from 'react-router-dom';
import { Campaign } from '../data/campaign';
import {
  MetadataDirectedFields,
  QuestFieldMetadata,
} from '../data/metadata-directed-fields';
import { Quest } from '../data/quest';
import { TelegramChannel } from '../data/telegram-channel';
import { QuestTemplate } from '../data/templates';
import { getQuestNumber } from '../helper/quests';
import { handleVariablesMap } from './api-client/quest-service';

interface MetadataDirectedFieldsParams {
  quest: Quest[];
  selectedTemplate?: QuestTemplate;
  twitterUserId: string | undefined;
  selectedCampaignData: Campaign;
  telegramChannels?: TelegramChannel[];
  gamePlatform: string | undefined;
}

class ActionFieldsService {
  public getDescription(field: QuestFieldMetadata) {
    return `${field.description ? field.description + '.' : ''} ${
      field.isInherited && !field.inheritedFromPage
        ? 'This field is inherited from the game page'
        : ''
    }`;
  }
  public getRightSection(
    field: QuestFieldMetadata,
    navigate: NavigateFunction
  ) {
    if (field && field.isInherited && field.inheritedFromPage) {
      return (
        <Anchor
          onClick={() =>
            field.inheritedFromPage && navigate(field.inheritedFromPage)
          }
        >
          <ActionIcon>
            <FaEdit />
          </ActionIcon>
        </Anchor>
      );
    }
  }

  public getLeftSection(field: QuestFieldMetadata, quest: Quest) {
    return handleVariablesMap?.includes(field.fieldKey) ? (
      <Text key={`left-section-${quest.quest_id}-${field.fieldKey}`}>@</Text>
    ) : undefined;
  }

  public getMetadataDirectedFields(
    params: MetadataDirectedFieldsParams
  ): MetadataDirectedFields {
    return {
      quest_number: getQuestNumber(params.quest, params.selectedTemplate),
      twitter_user_id: params.twitterUserId ?? '',
      partner_name: params.selectedCampaignData?.extra_data.partner_name ?? '',
      campaign_id: params.selectedCampaignData?.campaign_id ?? '',
      website_link: params.selectedCampaignData?.extra_data?.website ?? '',
      telegram_channel_name: params.telegramChannels?.[0]?.title ?? '',
      telegram_channel_id: params.telegramChannels?.[0]?.id ?? '',
      website_description: '',
      discord_handle:
        params.selectedCampaignData?.extra_data?.social.discord
          ?.split('/')
          .pop() ?? '',
      youtube_handle:
        params.selectedCampaignData?.extra_data?.social?.youtube
          ?.split('/')
          .pop() ?? '',
      twitter_handle:
        params.selectedCampaignData?.extra_data?.social?.twitter
          ?.split('/')
          .pop() ?? '',
      telegram_handle:
        params.selectedCampaignData?.extra_data?.social?.telegram
          ?.split('/')
          .pop() ?? '',
      game_platform: params.gamePlatform ?? '',
    };
  }

  public getOrderedTemplates(templates: QuestTemplate[]) {
    return templates
      .sort((a, b) =>
        (a.displayName ?? a.template_name).localeCompare(
          b.displayName ?? b.template_name
        )
      )
      .sort((a, b) => (a.featured === b.featured ? 0 : a.featured ? -1 : 1));
  }

  public getOrderedTemplatesSelectData(templates: QuestTemplate[]) {
    const orderedTemplates = this.getOrderedTemplates(templates);
    return [
      {
        group: 'Featured',
        items: orderedTemplates
          .filter(x => x.featured === true)
          .map(x => ({
            value: x.displayName ?? x.template_name,
            label: x.displayName ?? x.template_name,
          })),
      },
      {
        group: 'Common',
        items: orderedTemplates
          .filter(x => x.featured === false)
          .map(x => ({
            value: x.displayName ?? x.template_name,
            label: x.displayName ?? x.template_name,
          })),
      },
    ];
  }
}
export const actionFieldsService = new ActionFieldsService();

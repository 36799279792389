import { Button, Modal, Select, TextInput } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { FunctionComponent, useEffect, useState } from 'react';
import { NftContractAdditionData } from '../../../../../data/ntf-contract';

export interface AddNftContractDialogProps {
  onSave?: (contract: NftContractAdditionData) => void;
  addingContract?: boolean;
  blockchains?: string[];
}

const AddNftContractDialog: FunctionComponent<
  AddNftContractDialogProps
> = props => {
  const { onSave, addingContract, blockchains } = props;
  const [opened, { open, close }] = useDisclosure(false);
  const [collectionName, setCollectionName] = useState('');
  const [blockchain, setBlockchain] = useState<string | null>(null);
  const [address, setAddress] = useState('');
  const [collectionUrl, setCollectionUrl] = useState('');
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);

  useEffect(() => {
    setIsSaveEnabled(
      !!collectionName && !!blockchain && !!address && !!collectionUrl
    );
  }, [collectionName, blockchain, address, collectionUrl]);

  useEffect(() => {
    if (opened) {
      setCollectionName('');
      setBlockchain(null);
      setAddress('');
      setCollectionUrl('');
    }
  }, [opened]);

  const handleSave = () => {
    const newContract: NftContractAdditionData = {
      type: 'project',
      collection_name: collectionName,
      blockchain: blockchain as string,
      address,
      collection_url: collectionUrl,
    };
    if (onSave) {
      onSave(newContract);
    }
    close();
  };

  return (
    <>
      <Button onClick={open}>Add new contract</Button>
      <Modal
        zIndex={300}
        opened={opened}
        onClose={close}
        title="Add New NFT Contract"
        centered
      >
        <TextInput
          label="Collection Name"
          value={collectionName}
          onChange={event => setCollectionName(event.currentTarget.value)}
          required
        />
        <Select
          label="Blockchain"
          value={blockchain}
          onChange={value => setBlockchain(value)}
          data={blockchains?.map(blockchain => {
            return {
              label: blockchain.charAt(0).toUpperCase() + blockchain.slice(1),
              value: blockchain,
            };
          })}
          required
        />
        <TextInput
          label="Address"
          value={address}
          onChange={event => setAddress(event.currentTarget.value)}
          required
        />
        <TextInput
          label="Collection URL"
          value={collectionUrl}
          onChange={event => setCollectionUrl(event.currentTarget.value)}
          required
        />
        <Button
          mt={'0.3rem'}
          w={'100%'}
          onClick={handleSave}
          loading={addingContract}
          disabled={!isSaveEnabled}
        >
          Save
        </Button>
      </Modal>
    </>
  );
};

export default AddNftContractDialog;

import { configuration } from '../../configuration';
import { BaseService } from './base-service';
import { BearerTokenApiClient } from './bearer-token-api-client';

class BlockchainsService extends BaseService {
  public constructor(apiClient: BearerTokenApiClient) {
    super(apiClient, 'blockchains');
  }

  public async getBlockchains(): Promise<string[]> {
    const response = await this.apiClient.get<{ data: string[] }>(this.url());
    return response.data;
  }
}
export const blockchainsService = new BlockchainsService(
  new BearerTokenApiClient(configuration.forgeApi.selfServiceUrl)
);

import { useContext, useState } from 'react';
import { GlobalContext } from '../../../context/GlobalContext';
import { MessageStatus } from '../../../data/notify-options';
import { useNotification } from '../../../hooks/use-notification';
import { ssPartnerService } from '../../../services/api-client/ss-partner-service';

export const useCampaignPublishIntegration = (): [
  () => Promise<void>,
  boolean,
  (value: boolean) => void,
] => {
  const notification = useNotification();
  const {
    selectedCampaignOption,
    setCampaignOptionsTrigger,
    campaignOptionsTrigger,
    setPublishingCampaign,
    selectedOrganization,
  } = useContext(GlobalContext);

  const [publishDialogOpened, setPublishDialogOpened] =
    useState<boolean>(false);

  const handleOnCampaignPublished = async () => {
    try {
      setPublishingCampaign(true);
      if (!selectedOrganization || !selectedCampaignOption) {
        return;
      }
      await ssPartnerService.publishCampaign(
        selectedOrganization.partnerSlug,
        selectedCampaignOption.draft_campaign_id
      );
      setCampaignOptionsTrigger(!campaignOptionsTrigger);
    } catch (error: any) {
      notification.notifyApiError({
        status: MessageStatus.Error,
        title: 'Error',
        description: `Error publishing campaign`,
        response: error?.response,
      });
    } finally {
      setPublishingCampaign(false);
      setPublishDialogOpened(false);
    }
  };

  return [
    handleOnCampaignPublished,
    publishDialogOpened,
    setPublishDialogOpened,
  ];
};

import { Button, Card, Flex, Text } from '@mantine/core';
import { FunctionComponent } from 'react';
import { IoGameControllerOutline } from 'react-icons/io5';

export interface NoCampaignEmptyStateProps {
  addingDraft?: boolean;
  addNewDraft?: () => void;
}

const NoCampaignEmptyState: FunctionComponent<
  NoCampaignEmptyStateProps
> = props => {
  const { addingDraft, addNewDraft } = props;
  return (
    <Flex w={'100%'} h={'100%'} align={'center'} justify={'center'}>
      <Flex
        direction={'column'}
        align={'center'}
        gap={'1rem'}
        justify={'center'}
      >
        <IoGameControllerOutline color="#1c7ed6" size={'13.375rem'} />
        <Card p={'2rem'} maw={'25rem'} withBorder={true} radius={'1rem'}>
          <Text ta="center">
            Customize your campaigns and increase your player engagement. Add
            new drafts campaign to your game and make it more fun 🙂.
          </Text>
        </Card>
        <Flex direction={'row'} gap={'1rem'}>
          <Button loading={addingDraft} onClick={addNewDraft}>
            Add new Draft
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default NoCampaignEmptyState;

import {
  Button,
  Flex,
  Image,
  Loader,
  Modal,
  Switch,
  Text,
  Title,
  Tooltip,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { FunctionComponent, useEffect, useState } from 'react';
import { LocalImages } from '../../assets/images';
import { CampaignOption } from '../../data/campaign-option';

export interface HideCampaignDialogProps {
  campaign?: CampaignOption;
  onConfirmStatusChange: (status: boolean) => void;
  changingCampaignStatus?: boolean;
}

const HideCampaignDialog: FunctionComponent<
  HideCampaignDialogProps
> = props => {
  const { campaign, onConfirmStatusChange, changingCampaignStatus } = props;
  const [opened, { open, close }] = useDisclosure();
  const [listStatus, setListStatus] = useState<boolean | null>(
    campaign?.listed ?? null
  );
  const [status, setStatus] = useState<string>('');

  useEffect(() => {
    if (!campaign) {
      return;
    }
    setListStatus(campaign.listed ?? null);
    setStatus(campaign.listed === false ? 'available' : 'unavailable');
  }, [campaign]);

  if (!campaign) {
    return null;
  }
  if (!changingCampaignStatus && listStatus === null) {
    return null;
  }
  return (
    <>
      <Modal
        title={<Image w={'8rem'} src={LocalImages.logo} />}
        centered
        zIndex={300}
        opened={opened}
        onClose={close}
      >
        <Flex direction={'column'} gap={'1rem'}>
          <Title order={3}>Game status</Title>
          <Flex direction={'column'} gap={'0.5rem'}>
            <Text c={'gray'}>
              {`Are you sure you want to make publicly ${status} your game?
               ${!listStatus ? 'Un-listing' : 'Listing'}
                will ${!listStatus ? 'hide' : 'show'}
                 your game ${!listStatus ? 'from' : 'in'}
                  the games page and home page. ${!listStatus ? 'It will be accessible from the game url though.' : ''}`}
            </Text>
          </Flex>
          <Flex gap={'1rem'} w={'100%'} justify={'end'}>
            <Button onClick={close}>Cancel</Button>
            <Button
              color="red"
              onClick={() => {
                onConfirmStatusChange?.(listStatus ?? false);
                close();
              }}
            >
              Confirm
            </Button>
          </Flex>
        </Flex>
      </Modal>
      {changingCampaignStatus ? (
        <Loader size={'sm'} />
      ) : (
        <Tooltip
          label={`Changing this toggle will make the campaign ${status}`}
        >
          <Flex>
            <Switch
              checked={campaign?.listed ?? false}
              onChange={value => {
                setListStatus(value.currentTarget.checked);
                open();
              }}
              size="lg"
              onLabel="LIST"
              offLabel="UNLIST"
            />
          </Flex>
        </Tooltip>
      )}
    </>
  );
};

export default HideCampaignDialog;

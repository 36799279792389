import { Button, Flex, Text, Textarea, TextInput } from '@mantine/core';
import { DateInput } from '@mantine/dates';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { RewardInfo } from '../../../data/reward-info';
import { getSkeletons } from '../../../helper/skeletons';
import ImageUploader from '../../Images/ImageUploader/ImageUploader';

export interface RewardSectionProps {
  reward?: RewardInfo;
  onSave?: (saveOutPut: { rewardInfo: RewardInfo; image: File | null }) => void;
  savingReward?: boolean;
  loading?: boolean;
}

const RewardSection: FunctionComponent<RewardSectionProps> = props => {
  const { reward, onSave, savingReward, loading } = props;
  const [rewardImage, setRewardImage] = useState<File | null>(null);
  const [editedReward, setEditedReward] = useState<RewardInfo>(
    reward || {
      reward_title: '',
      reward_description: '',
      reward_image: '',
      extra_data: {
        expected_distribution_date: '',
        distribution_process: '',
      },
    }
  );
  const [isModified, setIsModified] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [errors, setErrors] = useState({
    reward_title: '',
    reward_description: '',
    reward_image: '',
    expected_distribution_date: '',
    distribution_process: '',
  });

  useEffect(() => {
    setEditedReward(
      reward || {
        reward_title: '',
        reward_description: '',
        reward_image: '',
        extra_data: {
          expected_distribution_date: '',
          distribution_process: '',
        },
      }
    );
    setRewardImage(null);
  }, [reward]);

  useEffect(() => {
    setIsValid(validateReward(editedReward));
  }, [editedReward]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setEditedReward(prev => ({
      ...prev,
      [name]: value,
    }));
    setIsModified(true);
  };

  const handleExtraDataChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEditedReward(prev => ({
      ...prev,
      extra_data: {
        ...prev.extra_data,
        [name]: value,
      },
    }));
    setIsModified(true);
  };

  const handleSave = () => {
    if (onSave && isValid) {
      const rewardOutput = {
        rewardInfo: editedReward,
        image: rewardImage,
      };
      onSave(rewardOutput);
      setIsModified(false);
    }
  };

  const validateReward = (reward: RewardInfo): boolean => {
    const isValidUrl = (url: string) => {
      try {
        new URL(url);
        return true;
      } catch {
        return false;
      }
    };

    const newErrors = {
      reward_title:
        reward.reward_title.trim() === '' ? 'Title is required' : '',
      reward_description:
        reward.reward_description.trim().length <= 50
          ? 'Description must be longer than 50 characters'
          : '',
      reward_image:
        !rewardImage && !isValidUrl(reward.reward_image) ? 'Invalid URL' : '',
      expected_distribution_date:
        reward.extra_data.expected_distribution_date.trim() === ''
          ? 'Expected Distribution Date is required'
          : '',
      distribution_process:
        reward.extra_data.distribution_process.trim() === ''
          ? 'Distribution Process is required'
          : '',
    };

    setErrors(newErrors);

    return Object.values(newErrors).every(error => error === '');
  };

  const handleDateChange = (value: Date | null) => {
    setEditedReward(prev => ({
      ...prev,
      extra_data: {
        ...prev.extra_data,
        expected_distribution_date: value ? value.toISOString() : '',
      },
    }));
    setIsModified(true);
  };

  if (loading) {
    return (
      <Flex
        align={'center'}
        gap={'1rem'}
        direction={'column'}
        justify={'center'}
        h={'100%'}
        w={'100%'}
        p={'2rem'}
      >
        {getSkeletons(10)}
      </Flex>
    );
  }

  return (
    <Flex gap={'1rem'} p={'1rem'} direction={'column'} w={'100%'}>
      <TextInput
        label="Title"
        name="reward_title"
        value={editedReward.reward_title}
        onChange={handleChange}
        required
        error={errors.reward_title}
      />
      <Textarea
        label="Description"
        name="reward_description"
        value={editedReward.reward_description}
        onChange={handleChange}
        required
        resize="vertical"
        error={errors.reward_description}
      />
      <Flex direction={'column'} gap={'0.3rem'}>
        <Flex gap={'0.2rem'}>
          <Text fw={500} fz="sm">
            Image
          </Text>
          <Text c={'red'} fw={500} fz="sm">
            *
          </Text>
        </Flex>
        <ImageUploader
          internalKey="reward"
          allowMultipleImages={false}
          withCloseButton={false}
          actualImage={rewardImage ?? editedReward.reward_image}
          expectedDimensions="120x120"
          onImageChange={images => {
            setRewardImage(images[0]);
            setIsModified(true);
          }}
          style={{ width: '8.25rem' }}
        />
      </Flex>

      <DateInput
        label="Expected Distribution Date"
        value={
          editedReward.extra_data.expected_distribution_date
            ? new Date(editedReward.extra_data.expected_distribution_date)
            : undefined
        }
        minDate={new Date()}
        onChange={(value: Date | null) => {
          handleDateChange(value);
        }}
        required
        error={errors.expected_distribution_date}
      />
      <TextInput
        label="Distribution Process"
        name="distribution_process"
        value={editedReward.extra_data.distribution_process}
        onChange={handleExtraDataChange}
        required
        error={errors.distribution_process}
      />

      <Button
        loading={savingReward}
        onClick={handleSave}
        disabled={!isModified || !isValid}
      >
        Save
      </Button>
    </Flex>
  );
};

export default RewardSection;

import { configuration } from '../../configuration';
import { Campaign } from '../../data/campaign';
import {
  CampaignOption,
  CampaignOptionResponse,
} from '../../data/campaign-option';
import { DailyStreak } from '../../data/daily-streak';
import { FeaturesData } from '../../data/features';
import { NftMultiplier } from '../../data/nft-multiplier';
import { NftContract, NftContractAdditionData } from '../../data/ntf-contract';
import { ReferralPoints } from '../../data/referral-points';
import { RewardInfo } from '../../data/reward-info';
import { SocialMultiplier } from '../../data/social-multiplier';
import { BaseService } from './base-service';
import { BearerTokenApiClient } from './bearer-token-api-client';

class SsPartnerService extends BaseService {
  public constructor(apiClient: BearerTokenApiClient) {
    super(apiClient, 'partner');
  }

  public async markInactive(partnerSlug: string, draft_campaign_id: string) {
    await this.apiClient.post(
      this.url(`${partnerSlug}/campaign/${draft_campaign_id}/mark-inactive`),
      {}
    );
  }

  public async markActive(partnerSlug: string, draft_campaign_id: string) {
    await this.apiClient.post(
      this.url(`${partnerSlug}/campaign/${draft_campaign_id}/mark-active`),
      {}
    );
  }

  public async removeNftContract(
    partnerSlug: string,
    campaign_id: string,
    nftContract: NftContract
  ) {
    await this.apiClient.delete(
      this.url(
        `${partnerSlug}/campaign/${campaign_id}/contracts/${nftContract.address}`
      ),
      { data: { blockchain: nftContract.blockchain } }
    );
  }

  public async addNftContract(
    partnerSlug: string,
    campaign_id: string,
    nftContract: NftContractAdditionData
  ) {
    await this.apiClient.post(
      this.url(`${partnerSlug}/campaign/${campaign_id}/contracts`),
      nftContract
    );
  }

  public async getNftContracts(
    partnerSlug: string,
    campaign_id: string
  ): Promise<NftContract[]> {
    const data = await this.apiClient.get<{ records: NftContract[] }>(
      this.url(`${partnerSlug}/campaign/${campaign_id}/contracts`)
    );
    return data.records;
  }

  public async unList(partnerSlug: string, draft_campaign_id: string) {
    await this.apiClient.post(
      this.url(`${partnerSlug}/campaign/${draft_campaign_id}/unlist`),
      {}
    );
  }
  public async list(partnerSlug: string, draft_campaign_id: string) {
    await this.apiClient.post(
      this.url(`${partnerSlug}/campaign/${draft_campaign_id}/list`),
      {}
    );
  }

  public async unPublishGame(partnerSlug: string, campaign_id: string) {
    await this.apiClient.post(
      this.url(`${partnerSlug}/campaign/${campaign_id}/unpublish`),
      {}
    );
  }

  public async deleteDraftCampaign(
    partnerSlug: string,
    draft_campaign_id: string
  ) {
    await this.apiClient.post(
      this.url(`${partnerSlug}/campaign/${draft_campaign_id}/delete-draft`),
      {}
    );
  }

  public async saveRewardsInfo(
    rewardsData: RewardInfo,
    selectedCampaign: Campaign,
    partnerSlug: string
  ) {
    await this.apiClient.post(
      this.url(
        `${partnerSlug}/campaign/${selectedCampaign.campaign_id}/reward`
      ),
      rewardsData
    );
  }

  public async getNftMultiplier(
    partnerSlug: string,
    campaign_id: string
  ): Promise<NftMultiplier> {
    return await this.apiClient.get<NftMultiplier>(
      this.url(`${partnerSlug}/campaign/${campaign_id}/nft-multiplier`)
    );
  }

  public async saveNftMultiplier(
    nftMultiplier: NftMultiplier,
    campaign_id: string,
    partnerSlug: string
  ) {
    await this.apiClient.post(
      this.url(`${partnerSlug}/campaign/${campaign_id}/nft-multiplier`),
      nftMultiplier
    );
  }

  public async getDailyStreaks(
    partnerSlug: string,
    campaign_id: string
  ): Promise<DailyStreak> {
    const response = await this.apiClient.get<{ data: DailyStreak }>(
      this.url(`${partnerSlug}/campaign/${campaign_id}/daily-streak`)
    );
    return response.data;
  }

  public async saveDailyStreaks(
    dailyStreak: DailyStreak,
    campaign_id: string,
    partnerSlug: string
  ) {
    await this.apiClient.post(
      this.url(`${partnerSlug}/campaign/${campaign_id}/daily-streak`),
      dailyStreak
    );
  }

  public async getSocialMultiplier(
    partnerSlug: string,
    campaign_id: string
  ): Promise<SocialMultiplier> {
    return this.apiClient.get<SocialMultiplier>(
      this.url(`${partnerSlug}/campaign/${campaign_id}/social-multiplier`)
    );
  }

  public async saveSocialMultiplier(
    socialMultiplier: SocialMultiplier,
    campaign_id: string,
    partnerSlug: string
  ) {
    await this.apiClient.post(
      this.url(`${partnerSlug}/campaign/${campaign_id}/social-multiplier`),
      socialMultiplier
    );
  }

  public async getRefererPoints(
    selectedCampaign: string,
    partnerSlug: string
  ): Promise<ReferralPoints> {
    return this.apiClient.get<ReferralPoints>(
      this.url(`${partnerSlug}/campaign/${selectedCampaign}/referral-points`)
    );
  }

  public async saveReferralPoints(
    referralPoints: ReferralPoints,
    campaign_id: string,
    partnerSlug: string
  ) {
    await this.apiClient.post(
      this.url(`${partnerSlug}/campaign/${campaign_id}/referral-points`),
      referralPoints
    );
  }

  public async getRewardInfo(
    partnerSlug: string,
    campaignId: string
  ): Promise<RewardInfo> {
    const rewardConfig = await this.apiClient.get<{ data: RewardInfo }>(
      this.url(`${partnerSlug}/campaign/${campaignId}/reward`)
    );
    return rewardConfig.data;
  }

  public async getFeaturesData(
    partnerSlug: string,
    campaign_id: string
  ): Promise<FeaturesData> {
    return this.apiClient.get<FeaturesData>(
      this.url(`${partnerSlug}/campaign/${campaign_id}/features`)
    );
  }

  public async enableFeature(
    partnerSlug: string,
    campaign_id: string,
    feature: string,
    checked: boolean
  ) {
    await this.apiClient.post(
      this.url(`${partnerSlug}/campaign/${campaign_id}/toggle-feature`),
      {
        feature: feature,
        enable: checked,
      }
    );
  }

  public async addDraftCampaign(partnerSlug: string) {
    await this.apiClient.post(this.url(`${partnerSlug}/campaign/create`), {
      default_quests: true,
    });
  }

  public async cloneDraftCampaign(partnerSlug: string, campaignId: string) {
    await this.apiClient.post(
      this.url(`${partnerSlug}/campaign/${campaignId}/clone`),
      {
        leaderboard: true,
        nft_multiplier: true,
        social_multiplier: true,
        referrer_multiplier: true,
        milestone: true,
        quests: true,
        contracts: true,
        tweets: true,
        twitter_accounts: true,
        twitter_cashtags: true,
        campaign_reward: true,
        daily_streak: true,
      }
    );
  }

  public async getDraftCampaigns(
    partnerSlug: string
  ): Promise<CampaignOption[]> {
    const response = await this.apiClient.get<CampaignOptionResponse>(
      this.url(`${partnerSlug}/get-draft-campaigns`)
    );

    if (!response) {
      return [];
    }
    const sortedCampaignOptions = response.draft_campaigns?.sort((a, b) => {
      if (a.published && !b.published) {
        return -1;
      } else if (!a.published && b.published) {
        return 1;
      } else {
        return 0;
      }
    });

    return sortedCampaignOptions;
  }
  public async publishCampaign(partnerSlug: string, campaignId: string) {
    await this.apiClient.post(
      this.url(`${partnerSlug}/campaign/${campaignId}/push`),
      {}
    );
  }
}

export const ssPartnerService = new SsPartnerService(
  new BearerTokenApiClient(configuration.forgeApi.selfServiceUrl)
);

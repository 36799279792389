import { useContext, useState } from 'react';
import { GlobalContext } from '../../../context/GlobalContext';
import { CampaignOption } from '../../../data/campaign-option';
import { MessageStatus } from '../../../data/notify-options';
import { useNotification } from '../../../hooks/use-notification';
import { ssPartnerService } from '../../../services/api-client/ss-partner-service';

export const useDeleteDraftCampaign = (): [
  (campaignOption: CampaignOption) => Promise<void>,
  boolean,
] => {
  const notification = useNotification();
  const {
    selectedOrganization,
    campaignOptionsTrigger,
    setCampaignOptionsTrigger,
  } = useContext(GlobalContext);
  const [deletingCampaign, setDeletingCampaign] = useState<boolean>(false);
  const handleCampaignDelete = async (campaignOption: CampaignOption) => {
    try {
      setDeletingCampaign(true);
      if (!selectedOrganization) {
        return;
      }
      await ssPartnerService.deleteDraftCampaign(
        selectedOrganization.partnerSlug,
        campaignOption.draft_campaign_id
      );
      setCampaignOptionsTrigger(!campaignOptionsTrigger);
    } catch (error: any) {
      notification.notifyApiError({
        status: MessageStatus.Error,
        title: 'Error',
        description: `Error deleting campaign`,
        response: error?.response,
      });
    } finally {
      setDeletingCampaign(false);
    }
  };

  return [handleCampaignDelete, deletingCampaign];
};

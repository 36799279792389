import { Card, Center, Flex, Title } from '@mantine/core';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CollaboratorInviteList from '../../components/CollaboratorInviteList/CollaboratorInviteList';
import NewCollaboratorInviteForm from '../../components/CollaboratorInviteList/components/NewColaboratorInviteForm';
import { GlobalContext } from '../../context/GlobalContext';
import { CollaboratorInvite } from '../../data/collaborator-invite';
import { useDataFetcher } from '../../hooks/use-data-fetcher';
import { useFromCheckList } from '../../hooks/use-from-checklist';
import { useNotification } from '../../hooks/use-notification';
import { invitationService } from '../../services/api-client/invitation-service';

import { MessageStatus } from '../../data/notify-options';
import { withAuthProtection } from '../../services/protect-route-element';

const Page = () => {
  const navigate = useNavigate();
  const { selectedOrganization } = useContext(GlobalContext);
  const [isFromCheckList, setIsFromChecklist] = useFromCheckList();
  const notification = useNotification();
  const [savingNewCollaboratorInvite, setSavingNewCollaboratorInvite] =
    useState<boolean>(false);
  const [invites, isLoading, setInvites] = useDataFetcher({
    serviceCall() {
      if (!selectedOrganization) {
        notification.notify(
          MessageStatus.Error,
          'Collaborators Invites',
          'Error fetching invites: No organization selected'
        );
      }
      return invitationService.getCollaboratorInvites(
        selectedOrganization?.partnerSlug ?? ''
      );
    },
    dependencies: [selectedOrganization],
    conditionForExecution: !!selectedOrganization,
    onError() {
      notification.notify(
        MessageStatus.Error,
        'Collaborators Invites',
        'Error fetching invites'
      );
    },
  });

  const handleSaveNewCollaboratorInvite = async (
    invite: CollaboratorInvite,
    isRefresh?: boolean
  ) => {
    try {
      setSavingNewCollaboratorInvite(true);
      const inviteCoincidence = invites?.find(i => i.email === invite.email);
      if (inviteCoincidence && !isRefresh) {
        notification.notify(
          MessageStatus.Error,
          'Collaborators Invites',
          'Invite already exists'
        );
        return;
      }
      if (!isRefresh) {
        if (!selectedOrganization) {
          notification.notify(
            MessageStatus.Error,
            'Collaborators Invites',
            'Error creating invitation: No organization selected'
          );
          return;
        }
        await invitationService.addNewInvite(
          invite.email,
          selectedOrganization.partnerSlug
        );
        setInvites([...(invites ?? []), invite]);
      }
      notification.notify(
        MessageStatus.Success,
        'Collaborators Invites',
        'Invitation created successfully'
      );
    } catch (error) {
      notification.notify(
        MessageStatus.Error,
        'Collaborators Invites',
        'Error creating invitation'
      );
    } finally {
      setSavingNewCollaboratorInvite(false);
      if (isFromCheckList) {
        setIsFromChecklist(false);
        navigate('/home');
      }
    }
  };

  return (
    <Flex
      p={'1rem'}
      direction={'column'}
      gap={'sm'}
      align={'center'}
      className="CollaboratorsInvitesPageWrapper"
    >
      <Card
        w={'100%'}
        maw={'60rem'}
        h={'100%'}
        shadow="sm"
        padding="lg"
        radius="md"
        withBorder
      >
        <Center>
          <Flex
            w={'100%'}
            gap={'1rem'}
            maw={'50rem'}
            direction={'column'}
            justify={'center'}
          >
            <Flex direction={'row'} justify={'space-between'} align={'center'}>
              <Title size={'md'}>Invites</Title>
              <NewCollaboratorInviteForm
                savingNewCollaboratorInvite={savingNewCollaboratorInvite}
                onSaveNewCollaboratorInvite={invite => {
                  handleSaveNewCollaboratorInvite(invite);
                }}
              />
            </Flex>

            <CollaboratorInviteList
              invites={invites ?? []}
              isLoading={isLoading || savingNewCollaboratorInvite}
              onRefresh={invite => {
                handleSaveNewCollaboratorInvite(invite, true);
              }}
            />
          </Flex>
        </Center>
      </Card>
    </Flex>
  );
};

export const CollaboratorsPage = withAuthProtection(Page);

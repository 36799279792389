import { useEffect, useState } from 'react';
import { LocalImages } from '../../assets/images';
import { Login } from '../../components/Login/Login';
import { MessageStatus } from '../../data/notify-options';
import { useNotification } from '../../hooks/use-notification';
import { authentication } from '../../services/authentication';
import { localStorageService } from '../../services/local-storage-service';

export const LoginPage = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const notification = useNotification();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const processError = (error: string | null) => {
    switch (error) {
      case 'invalid_user':
        return 'Invalid email. Please try again.';
      case 'access_denied':
        return 'Access denied. Please try again.';
      case 'unauthorized':
        return 'Unauthorized. Please try again.';
      case 'invalid_token':
        return 'Please try again using the same browser was used to generate the link.';
      case 'unauthorized_from_backend':
        return 'Your session was lost. Please generate a new login lik using your email.';
      case 'logout':
        return '';
      default:
        return error;
    }
  };

  useEffect(() => {
    localStorageService.clearAppData();
  }, []);

  const [error, setError] = useState<string | undefined>(
    processError(urlParams.get('error')) ?? ''
  );

  const handleSendMagicLink = async (email: string) => {
    if (!email) {
      return;
    }
    try {
      setError(undefined);
      setIsLoading(true);
      await authentication.passwordlessStart(email);
      notification.notify(
        MessageStatus.Info,
        'Login Info',
        'Magic link sent to your email. If you do not see it, please check your spam folder.'
      );
    } catch (e: any) {
      console.error(e);
      setError('Email not found');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Login
      isLoading={isLoading}
      logo={LocalImages.logo}
      failedSignInMessage={error}
      onSendMagicLink={email => handleSendMagicLink(email)}
    />
  );
};

import {
  Button,
  Flex,
  NativeSelect,
  Select,
  Text,
  TextInput,
} from '@mantine/core';
import { FunctionComponent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getIcon } from '../../../data/icon-registry';
import {
  defaultNotUserVisibleFields,
  MetadataDirectedFields,
  QuestFieldMetadata,
} from '../../../data/metadata-directed-fields';
import { Quest, QuestVariable } from '../../../data/quest';
import { TelegramChannel } from '../../../data/telegram-channel';
import { QuestTemplate } from '../../../data/templates';
import { formatDate, getUTCDate } from '../../../helper/dates';
import { actionFieldsService } from '../../../services/action-fields-service';
import {
  handleVariables,
  questDataService,
} from '../../../services/quest-data-service';
import QuestElement from '../QuestBasicElement/QuestBasicElement';
import QuestCommonFields from '../QuestCommonFields/QuestCommonFields';

export interface QuestAdditionFormProps {
  isAddition: boolean;
  isForgeStaff: boolean;
  campaignId: string;
  tier: string;
  metadataDirectedFields: MetadataDirectedFields;
  questTemplates: QuestTemplate[];
  isLoading?: boolean;
  onSave?: (quest: Quest, isAddition: boolean) => void;
  onTemplateChange: (template: QuestTemplate) => void;
  onCancel?: () => void;
  currentQuest?: Quest;
  onUpsellClick?: () => void;
  getQuestNumber: (selectedTemplate: QuestTemplate) => number;
  questCount: number;
  gamePage: string;
  telegramBotInformation?: TelegramChannel[];
}

interface SelectElementsStructure {
  getElements: () => string[] | undefined;
  getSelectedValue: () => string | undefined;
  getDefaultValue: () => string | undefined;
  onChange: (value: string) => void;
}
export const QuestManagementForm: FunctionComponent<
  QuestAdditionFormProps
> = props => {
  const {
    currentQuest,
    metadataDirectedFields,
    isAddition,
    isLoading,
    questTemplates,
    campaignId,
    tier,
    questCount,
    onSave,
    onCancel,
    gamePage,
    onTemplateChange,
    telegramBotInformation,
    getQuestNumber,
  } = props;
  const navigate = useNavigate();
  const [allowAddition, setAllowAddition] = useState(false);
  const [formError, setFormError] = useState('');
  const [elementsInError, setElementInError] = useState<string[]>([]);
  const [questTemplate, setQuestTemplate] = useState<QuestTemplate>(
    questDataService.initializeQuestTemplate(questTemplates, currentQuest)
  );
  const [quest, setQuest] = useState<Quest>();
  const [questVariables, setQuestVariables] = useState<QuestVariable[]>(
    questDataService.initializeQuestVariables(
      currentQuest,
      questTemplate,
      questCount
    )
  );
  const [selectedTelegramChannel, setSelectedTelegramChannel] =
    useState<TelegramChannel>();
  const [questMetadataFields, setQuestMetadataFields] =
    useState<QuestFieldMetadata[]>();

  const getCorrectValueToCompare = (fieldKey: string) => {
    const questVariablesCoincidence = questVariables.find(
      x => x.key === fieldKey
    );
    if (questVariablesCoincidence) {
      return questVariablesCoincidence.value;
    }
    return questMetadataFields?.find(x => x.fieldKey === fieldKey)?.value;
  };

  const selectTypeFields = new Map<string, SelectElementsStructure>([
    [
      'telegram_channel_name',
      {
        getElements: () => telegramBotInformation?.map(x => x.title),
        getSelectedValue: () => selectedTelegramChannel?.title,
        getDefaultValue: () => {
          return (
            telegramBotInformation?.find(
              x => x.title === getCorrectValueToCompare('telegram_channel_name')
            )?.title ?? telegramBotInformation?.[0]?.title
          );
        },
        onChange: (value: string) => {
          const selectedValue = telegramBotInformation?.find(
            x => x.title === value
          );
          setSelectedTelegramChannel(selectedValue);
          handleInputChange('telegram_channel_name', value);
          checkError('telegram_channel_name', value);
        },
      },
    ],
    [
      'telegram_channel_id',
      {
        getElements: () => telegramBotInformation?.map(x => x.id),
        getSelectedValue: () => selectedTelegramChannel?.id,
        getDefaultValue: () => {
          return (
            telegramBotInformation?.find(
              x => x.id === getCorrectValueToCompare('telegram_channel_id')
            )?.id ?? telegramBotInformation?.[0]?.id
          );
        },
        onChange: (value: string) => {
          const selectedValue = telegramBotInformation?.find(
            x => x.id === value
          );
          setSelectedTelegramChannel(selectedValue);
          handleInputChange('telegram_channel_id', value);
          checkError('telegram_channel_id', value);
        },
      },
    ],
  ]);

  useEffect(() => {
    const questFields = buildQuestFields(questTemplate);
    if (!questFields) {
      return;
    }
    const initializedQuest = questDataService.initializeQuest(
      questTemplate,
      campaignId,
      isAddition
        ? getQuestNumber(questTemplate) + 1
        : metadataDirectedFields['quest_number'],
      questCount,
      questFields,
      currentQuest
    );
    const questVariables = questDataService.initializeQuestVariables(
      initializedQuest,
      questTemplate,
      questCount
    );
    setQuestVariables(questVariables);
    onTemplateChange(questTemplate);
    setQuestMetadataFields(questFields);
    handelMultiActive(questTemplate);
    setQuest(initializedQuest);
    const initialFieldErrors: string[] = [];
    questFields?.forEach(field => {
      if (!field.fieldKey || !field.value || field.value === '') {
        initialFieldErrors.push(field.fieldKey);
      }
    });
    setElementInError(initialFieldErrors);
  }, [questTemplate]);

  const evaluateInheritValue = (variable: keyof MetadataDirectedFields) => {
    if (!questTemplate || !metadataDirectedFields) {
      return '';
    }
    return metadataDirectedFields[variable];
  };

  const getInitialValue = (
    isInherited: boolean,
    variable: keyof MetadataDirectedFields
  ) => {
    const element = questVariables.find(x => x.key === variable);

    if (selectTypeFields.has(variable)) {
      if (isAddition) {
        return selectTypeFields.get(variable)?.getElements()?.[0];
      }
      return selectTypeFields.get(variable)?.getDefaultValue();
    }

    const initialValue = isInherited
      ? evaluateInheritValue(variable)
      : element?.value;
    return initialValue;
  };

  const buildQuestFields = (templateObj: QuestTemplate) => {
    if (!templateObj) {
      return;
    }

    const templateVariables = templateObj.template_variables
      .join(',')
      .replaceAll('{', '')
      .replaceAll('}', '')
      .split(',')
      .map(x => x as keyof MetadataDirectedFields);
    const questMetadataFields: QuestFieldMetadata[] = [];
    templateVariables.forEach(variable => {
      const isInherited = questDataService.determineIfInheritedField(variable);
      const fieldValue = getInitialValue(isInherited, variable);
      const newQuestField: QuestFieldMetadata = {
        description:
          templateObj.templateVariablesMetadata?.[variable]?.description,
        displayName: variable.charAt(0).toUpperCase() + variable.slice(1),
        inheritedFromTitle: isInherited ? 'Game Info' : '',
        inheritedFromUrl: isInherited ? gamePage : '',
        value: fieldValue,
        fieldKey: variable,
        isInherited,
        type: 'text',
        inheritedFromPage: questDataService.getInheritedFromPage(variable),
      };
      questMetadataFields.push(newQuestField);
    });
    return questMetadataFields;
  };

  interface ElementsToUpdate {
    fieldKey: string;
    value: any;
  }
  interface DependentElement {
    fieldKey: string;
    getValue: (filter?: any) => any;
  }

  const dependentElementRegistry = new Map<string, DependentElement[]>([
    [
      'telegram_channel_name',
      [
        {
          fieldKey: 'telegram_channel_id',
          getValue: (filter: any) =>
            telegramBotInformation?.find(x => x.title === filter)?.id,
        },
      ],
    ],
    [
      'telegram_channel_id',
      [
        {
          fieldKey: 'telegram_channel_name',
          getValue: (filter: any) =>
            telegramBotInformation?.find(x => x.id === filter)?.title,
        },
      ],
    ],
  ]);

  const getElement = (
    tempQuestVariables: QuestVariable[],
    fieldKey: keyof QuestTemplate
  ) => {
    if (!questMetadataFields) {
      return;
    }
    return questDataService.getElement(
      questTemplate,
      tempQuestVariables,
      fieldKey
    );
  };

  const getJsonElement = (
    tempQuestVariables: QuestVariable[],
    fieldKey: keyof QuestTemplate
  ) => {
    if (!questMetadataFields) {
      return;
    }
    return questDataService.getElementJson(
      questTemplate,
      tempQuestVariables,
      fieldKey
    );
  };

  const getDependentElements = (
    fieldKey: string,
    value: any
  ): ElementsToUpdate[] => {
    return (
      dependentElementRegistry.get(fieldKey)?.map(element => {
        return { fieldKey: element.fieldKey, value: element.getValue(value) };
      }) ?? []
    );
  };

  const getElementsToUpdate = (initialElement: ElementsToUpdate) => {
    const result = [initialElement];
    if (dependentElementRegistry.has(initialElement.fieldKey)) {
      result.push(
        ...getDependentElements(initialElement.fieldKey, initialElement.value)
      );
    }
    return result;
  };

  const getUpdatedDependentElementValues = (
    elementsToUpdate: ElementsToUpdate[],
    questVariables: QuestVariable[],
    responseQuestVariables: any
  ) => {
    let newInternalQuestVariables = [...questVariables];
    let newRequestQuestVariables = { ...responseQuestVariables };
    elementsToUpdate.forEach(element => {
      newInternalQuestVariables = questDataService.getUpdatedQuestVariables(
        [...newInternalQuestVariables],
        element.fieldKey,
        element.value
      );
      newRequestQuestVariables = {
        ...newRequestQuestVariables,
        [element.fieldKey]: element.value,
      };
    });
    return { newInternalQuestVariables, newRequestQuestVariables };
  };

  const handleInputChange = (fieldKey: string, value: string) => {
    if (!quest || !questTemplate || !questVariables) {
      return;
    }
    const elementsToUpdate: ElementsToUpdate[] = getElementsToUpdate({
      fieldKey,
      value,
    });
    const updatedDependentElementValues = getUpdatedDependentElementValues(
      elementsToUpdate,
      [...questVariables],
      { ...quest.quest_variables }
    );

    const newInternalQuestVariables =
      updatedDependentElementValues.newInternalQuestVariables;
    const newRequestQuestVariables =
      updatedDependentElementValues.newRequestQuestVariables;

    const newTitle = getElement(newInternalQuestVariables, 'title');
    const newDescription = getElement(newInternalQuestVariables, 'description');
    const newJsonVariables = getJsonElement(
      newInternalQuestVariables,
      'variable_json'
    );
    const newExtraData = getJsonElement(
      newInternalQuestVariables,
      'extra_data'
    );

    setQuest({
      ...quest,
      title: newTitle,
      description: newDescription,
      variable_json: newJsonVariables,
      extra_data: newExtraData,
      quest_variables: newRequestQuestVariables,
    });
    setQuestVariables(newInternalQuestVariables);
  };

  const getDynamicDefaultValue = (fieldValue: QuestFieldMetadata) => {
    if (!fieldValue) {
      return '';
    }
    if (fieldValue.fieldKey === 'link') {
      return decodeURIComponent(
        !fieldValue.value || fieldValue.value === 'undefined'
          ? ''
          : fieldValue.value
      );
    }
    if (handleVariables?.includes(fieldValue.fieldKey)) {
      return fieldValue.value?.replace(/\s+/g, '').replaceAll('@', '');
    }
    return fieldValue.value;
  };

  const checkError = (fieldKey: string, value?: string | number) => {
    if (!value || value === '' || value === '0') {
      setElementInError([...elementsInError, fieldKey]);
      return;
    }
    setElementInError(elementsInError.filter(e => e !== fieldKey));
    return;
  };

  const getError = (field: keyof Quest) => {
    if (!isAddition) {
      if (elementsInError.includes(field)) {
        return `${field.charAt(0).toUpperCase() + field.slice(1).replaceAll('_', ' ')} field is required`;
      }
    }
    return;
  };

  const getDynamicInputs = () => {
    if (!quest || !questMetadataFields) {
      return;
    }
    return questMetadataFields?.map(field => {
      if (Object.keys(defaultNotUserVisibleFields).includes(field.fieldKey)) {
        return;
      }
      if (
        selectTypeFields.has(field.fieldKey) &&
        selectTypeFields.get(field.fieldKey)?.getElements()?.length
      ) {
        return (
          <NativeSelect
            required
            description={actionFieldsService.getDescription(field)}
            key={`select-${quest.quest_id}-${field.fieldKey}`}
            styles={{ input: { color: 'black' } }}
            rightSection={actionFieldsService.getRightSection(field, navigate)}
            error={getError(field.fieldKey as keyof Quest)}
            errorProps={{ position: 'top' }}
            label={field.displayName?.replaceAll('_', ' ')}
            data={selectTypeFields.get(field.fieldKey)?.getElements()}
            defaultValue={selectTypeFields
              .get(field.fieldKey)
              ?.getDefaultValue()}
            onChange={value => {
              selectTypeFields
                .get(field.fieldKey)
                ?.onChange(value.currentTarget.value);
            }}
            value={selectTypeFields.get(field.fieldKey)?.getSelectedValue()}
          />
        );
      }
      return (
        <TextInput
          required
          description={actionFieldsService.getDescription(field)}
          leftSection={actionFieldsService.getLeftSection(field, quest)}
          error={getError(field.fieldKey as keyof Quest)}
          key={`${quest.quest_id}-${field.fieldKey}`}
          disabled={field.isInherited}
          rightSection={actionFieldsService.getRightSection(field, navigate)}
          defaultValue={getDynamicDefaultValue(field)}
          label={field.displayName?.replaceAll('_', ' ')}
          onChange={value => {
            handleInputChange(field.fieldKey, value.currentTarget.value);
            checkError(field.fieldKey, value.currentTarget.value);
          }}
        />
      );
    });
  };

  const getDynamicInputsValue = (fieldKey: keyof Quest) => {
    if (!quest || !questTemplate || !questMetadataFields || isAddition) {
      return;
    }
    const field = questMetadataFields.find(x => x.fieldKey === fieldKey);
    if (!field) {
      return (
        <TextInput
          required
          description={`Quest ${fieldKey}`}
          error={getError(fieldKey)}
          key={`dynamic-field-${fieldKey}`}
          defaultValue={decodeURIComponent(quest[fieldKey])}
          label={fieldKey.charAt(0).toUpperCase() + fieldKey.slice(1)}
          onChange={value => {
            setQuest({ ...quest, [fieldKey]: value.currentTarget.value });
            checkError(fieldKey, value.currentTarget.value);
          }}
        />
      );
    }
  };

  const isDateEditable = () => {
    if (!quest) {
      return false;
    }
    const formattedStartTime = getUTCDate(quest.start_datetime);
    const today = getUTCDate(new Date());

    if (!isAddition && formattedStartTime < today) {
      return false;
    }
    return true;
  };

  const renderUI = () => {
    if (!questTemplate) {
      return <Text>There is not template selected</Text>;
    }
    if (!quest) {
      return <Text>There is a problem with your quest initialization</Text>;
    }
    const dynamicTitle = getDynamicInputsValue('title');
    const dynamicDescription = getDynamicInputsValue('description');
    const dynamicInputs = getDynamicInputs();
    return [
      selectSection(),
      dynamicTitle,
      dynamicDescription,
      dynamicInputs,
      <QuestCommonFields
        key={`quest-common-fields-${quest.quest_id}`}
        isAddition={isAddition}
        questId={quest.quest_id}
        points={quest.points}
        tier={tier}
        isDateEditable={isDateEditable()}
        isForgeStaff={props.isForgeStaff}
        startDatetime={new Date(quest.start_datetime)}
        enabled={quest.enabled}
        onUpsellClick={props.onUpsellClick}
        onPointsChanges={value => {
          setQuest({ ...quest, points: value });
          checkError('points', value);
        }}
        onStartDateChanges={value => {
          setQuest({ ...quest, start_datetime: formatDate(value) });
        }}
        onHiddenChanges={value => {
          setQuest({ ...quest, hidden: value });
        }}
        onEnabledChanges={value => {
          setQuest({ ...quest, enabled: value });
        }}
      />,
    ];
  };

  const selectSection = () => {
    if (isAddition) {
      const templates =
        actionFieldsService.getOrderedTemplatesSelectData(questTemplates);
      return (
        <Select
          searchable
          nothingFoundMessage="No action found"
          styles={{ input: { color: 'black' } }}
          mb={formError ? '1rem' : ''}
          error={formError}
          errorProps={{ position: 'top' }}
          label={'Select or type the action you want to add'}
          defaultValue={templates[0].items[0].value}
          data={templates}
          allowDeselect={false}
          h={'3rem'}
          onChange={e => handleTemplateChange(e)}
        />
      );
    }
  };

  const handleTemplateChange = (value: string | null) => {
    setSelectedTelegramChannel(undefined);
    if (!isAddition || !value) {
      return;
    }
    const template = questTemplates.find(x =>
      [x.displayName, x.template_name].includes(value)
    );
    if (!template) {
      return;
    }
    setQuestTemplate(template);
  };

  const handleSave = () => {
    if (!quest) {
      return;
    }
    onSave?.(quest, isAddition);
  };

  const handelMultiActive = (questTemplate: QuestTemplate) => {
    if (
      isAddition &&
      !questTemplate.multi_active &&
      getQuestNumber(questTemplate) > 1
    ) {
      setFormError('This type of quest does not allow multiple instance');
      setAllowAddition(false);
      return;
    }
    setFormError('');
    setAllowAddition(true);
  };

  return (
    <Flex direction="column" h={'100%'} gap={'2rem'} justify={'space-between'}>
      <Flex direction={'column'} gap={'1rem'} justify={'space-between'}>
        {renderUI()}
      </Flex>
      <Flex gap={'1rem'} justify={'end'}>
        <Button variant="outline" onClick={() => onCancel?.()}>
          Cancel
        </Button>
        <Button
          disabled={!allowAddition || elementsInError?.length > 0}
          onClick={handleSave}
          loading={isLoading}
        >
          Save
        </Button>
      </Flex>
      {quest && (
        <Flex direction={'column'}>
          <Text>Preview</Text>
          <QuestElement
            orderingActions={false}
            icon={getIcon(quest.icon)}
            isPreview
            sortableQuest={{ quest: quest, id: 0 }}
          />
        </Flex>
      )}
    </Flex>
  );
};

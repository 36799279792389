import { Button, Flex } from '@mantine/core';
import { FunctionComponent, useState } from 'react';
import { Socials } from '../../../../data/campaign';
import SocialElement from './components/SocialElement';

export interface SocialSectionProps {
  socials: Socials;
  onSave: (socials: Socials) => void;
  savingCampaign: boolean;
}

const SocialSection: FunctionComponent<SocialSectionProps> = props => {
  const { socials, onSave, savingCampaign } = props;
  const [modifiedSocials, setModifiedSocials] = useState<Socials>(socials);
  const [hasChanges, setHasChanges] = useState(false);

  const onSocialsChange = (updatedSocials: Socials) => {
    setModifiedSocials(updatedSocials);
    setHasChanges(true);
  };

  const handleSave = () => {
    onSave(modifiedSocials);
    setHasChanges(false);
  };

  return (
    <Flex gap={'1rem'} p={'1rem'} direction={'column'} w={'100%'}>
      <SocialElement
        defaultInputValue={modifiedSocials?.discord}
        fieldName={'discord'}
        onUpdate={(fieldName, newValue) =>
          onSocialsChange({ ...modifiedSocials, [fieldName]: newValue })
        }
      />
      <SocialElement
        defaultInputValue={modifiedSocials?.twitter}
        fieldName={'twitter'}
        onUpdate={(fieldName, newValue) =>
          onSocialsChange({ ...modifiedSocials, [fieldName]: newValue })
        }
      />
      <SocialElement
        defaultInputValue={modifiedSocials?.youtube}
        fieldName={'youtube'}
        onUpdate={(fieldName, newValue) =>
          onSocialsChange({ ...modifiedSocials, [fieldName]: newValue })
        }
      />
      <SocialElement
        defaultInputValue={modifiedSocials?.telegram}
        fieldName={'telegram'}
        onUpdate={(fieldName, newValue) =>
          onSocialsChange({ ...modifiedSocials, [fieldName]: newValue })
        }
      />
      <SocialElement
        defaultInputValue={modifiedSocials?.medium}
        fieldName={'medium'}
        onUpdate={(fieldName, newValue) =>
          onSocialsChange({ ...modifiedSocials, [fieldName]: newValue })
        }
      />
      <SocialElement
        defaultInputValue={modifiedSocials?.opensea}
        fieldName={'opensea'}
        onUpdate={(fieldName, newValue) =>
          onSocialsChange({ ...modifiedSocials, [fieldName]: newValue })
        }
      />
      <Button
        loading={savingCampaign}
        onClick={handleSave}
        disabled={!hasChanges}
      >
        Save
      </Button>
    </Flex>
  );
};

export default SocialSection;

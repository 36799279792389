import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useContext, useState } from 'react';
import { GlobalContext } from '../../../context/GlobalContext';
import { FeatureFlags } from '../../../data/feature-flags';
import { FeaturesData } from '../../../data/features';
import { MessageStatus } from '../../../data/notify-options';
import { useDataFetcher } from '../../../hooks/use-data-fetcher';
import { useNotification } from '../../../hooks/use-notification';
import { usePublishStatus } from '../../../hooks/use-publishing-status';
import { ssPartnerService } from '../../../services/api-client/ss-partner-service';

export const useFeaturesIntegrations = (): [
  FeaturesData | undefined,
  boolean,
  { feature: string; loading: boolean }[],
  (feature: string, checked: boolean) => Promise<void>,
] => {
  const notification = useNotification();
  const {
    selectedCampaignData,
    selectedOrganization,
    selectedCampaignDataTrigger,
  } = useContext(GlobalContext);
  const [activatePublishIfNoErrors] = usePublishStatus();
  const isFeatureConfigActive = useFeatureFlagEnabled(
    FeatureFlags.ShowFeaturesSection
  );

  const [featuresLoadingStates, setFeaturesLoadingStates] = useState<
    { feature: string; loading: boolean }[]
  >([]);

  const [featureDataTrigger, setFeatureDataTrigger] = useState<boolean>(false);

  const [featuresData, loadingFeaturesData] = useDataFetcher({
    serviceCall() {
      if (selectedOrganization && selectedCampaignData) {
        return ssPartnerService.getFeaturesData(
          selectedOrganization.partnerSlug,
          selectedCampaignData.campaign_id
        );
      }
    },
    dependencies: [
      selectedCampaignData,
      selectedCampaignDataTrigger,
      featureDataTrigger,
    ],
    conditionForExecution: isFeatureConfigActive,
  });

  const handleFeatureActivationChange = async (
    feature: string,
    checked: boolean
  ) => {
    if (!selectedCampaignData || !selectedOrganization) {
      return;
    }
    try {
      setFeaturesLoadingStates([
        ...featuresLoadingStates,
        { feature, loading: true },
      ]);
      await ssPartnerService.enableFeature(
        selectedOrganization.partnerSlug,
        selectedCampaignData.campaign_id,
        feature,
        checked
      );
      setFeatureDataTrigger(!featureDataTrigger);
      activatePublishIfNoErrors();
    } catch (error: any) {
      notification.notifyApiError({
        status: MessageStatus.Error,
        title: 'Feature Error',
        description: 'An error occurred while saving the feature',
        response: error.response,
      });
    } finally {
      setFeaturesLoadingStates(
        featuresLoadingStates.filter(state => state.feature === feature)
      );
    }
  };

  return [
    featuresData,
    loadingFeaturesData,
    featuresLoadingStates,
    handleFeatureActivationChange,
  ];
};

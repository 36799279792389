import { Flex, Loader, Text } from '@mantine/core';
import { IconAsset, IconInfoCircle, IconSocial } from '@tabler/icons-react';
import { ReactNode } from 'react';
import { GiThunderball } from 'react-icons/gi';
import { GrConfigure } from 'react-icons/gr';
import { RiNftLine } from 'react-icons/ri';
import { Campaign } from '../../../../data/campaign';
import { FeatureFlags } from '../../../../data/feature-flags';
import { campaignDataService } from '../../../../services/campaign-data-service';

export interface ErrorByTabElement {
  tab: GamePageTabs;
  section?: string;
  inError: boolean;
}

export enum GamePageTabs {
  BasicInformation = 'basic_information',
  ExtraData = 'extra_data',
  Assets = 'assets',
  Socials = 'socials',
  Rewards = 'rewards',
  Features = 'features',
  NftContracts = 'nft_contracts',
}

const iconStyle = { width: '1.3rem', height: '1.3rem' };

export const tabsRegistry = new Map<
  GamePageTabs,
  {
    title: string;
    icon: ReactNode;
    featureFlag?: FeatureFlags;
    requiredFeature?: string;
  }
>([
  [
    GamePageTabs.BasicInformation,
    {
      title: 'Basic info',
      icon: <IconInfoCircle style={iconStyle} />,
    },
  ],
  [
    GamePageTabs.ExtraData,
    { title: 'Extra data', icon: <GiThunderball style={iconStyle} /> },
  ],
  [
    GamePageTabs.Assets,
    { title: 'Assets', icon: <IconAsset style={iconStyle} /> },
  ],
  [
    GamePageTabs.Socials,
    { title: 'Socials', icon: <IconSocial style={iconStyle} /> },
  ],
  [
    GamePageTabs.Features,
    {
      title: 'Features',
      icon: <GrConfigure style={iconStyle} />,
      featureFlag: FeatureFlags.ShowFeaturesSection,
    },
  ],
  [
    GamePageTabs.NftContracts,
    {
      title: 'NFT Contracts',
      icon: <RiNftLine style={iconStyle} />,
      requiredFeature: 'NFT Multiplier',
    },
  ],
]);

export const getTabTitle = (
  tab: GamePageTabs,
  campaign: Campaign,
  isLoading: boolean
) => {
  const tabsWithErrors =
    campaignDataService.getCampaignPublishableStatus(campaign);
  const color = tabsWithErrors.sectionsInError?.map(x => x).includes(tab)
    ? 'red'
    : '';
  return (
    <Flex gap={'0.3rem'} justify={'center'} align={'center'}>
      <Text c={color}>{tabsRegistry.get(tab)?.title}</Text>
      {isLoading && <Loader size={'xs'}></Loader>}
    </Flex>
  );
};

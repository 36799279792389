import {
  MessageStatus,
  NotifyOptions,
  ToastPositions,
} from '../data/notify-options';
import { sentryService } from './sentry-service';

export class NotificationService {
  private _provider: any;
  public constructor(provider: any) {
    this._provider = provider;
  }

  public notify(
    status: MessageStatus,
    title: string,
    description: string,
    position?: ToastPositions
  ) {
    sentryService.sendSentryEvent({
      status: status,
      title: title,
      description: description,
    });
    this._provider({
      position: position ?? ToastPositions.TopRight,
      title: title,
      description: description,
      status: status,
      duration: 3000,
      isClosable: true,
    });
  }

  private _formatError = (error: any) => {
    if (!error) return undefined;
    return (error as string)
      .replaceAll('_', ' ')
      .replaceAll('-', ' ')
      .replaceAll('  ', ' ');
  };

  public notifyApiError(notifyOptions: NotifyOptions) {
    sentryService.sendSentryEvent(notifyOptions);
    this._provider({
      position: notifyOptions.position ?? ToastPositions.TopRight,
      title: notifyOptions.title,
      description: `${notifyOptions.description}.
       Detail: ${
         this._formatError(notifyOptions.response?.data?.message) ??
         this._formatError(notifyOptions.response?.data?.detail) ??
         this._formatError(notifyOptions.response?.data?.error_msg) ??
         'Unknown error'
       }`,
      status: notifyOptions.status,
      duration: 3000,
      isClosable: true,
    });
  }
}

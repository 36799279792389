import { Flex, ScrollAreaAutosize, Tabs } from '@mantine/core';
import { FunctionComponent } from 'react';
import { Campaign } from '../../data/campaign';
import { CampaignAssetModel } from '../../data/campaign-asset-model';
import NoCampaignEmptyState from '../Campaign/NoCampaignEmptyState/NoCampaignEmptyState';
import BasicInfoSection from './components/BasicAssetsSection';
import { CommonAssetsForm } from './components/CommonAssetsForm';
import {
  GamePageTabs,
  getTabTitle,
  tabsRegistry,
} from './components/data/tabs-data';

import { useFeatureFlagEnabled } from 'posthog-js/react';
import { FeaturesData } from '../../data/features';
import { NftMultiplier } from '../../data/nft-multiplier';
import { ReferralPoints } from '../../data/referral-points';

import { DailyStreak } from '../../data/daily-streak';
import { NftContract, NftContractAdditionData } from '../../data/ntf-contract';
import { SocialMultiplier } from '../../data/social-multiplier';
import { staffCheck } from '../../helper/feature-flags';
import ExtraDataSection from './components/Extra-DataSection';
import FeaturesConfiguration from './components/FeatureConfiguration/FeatureConfiguration';
import NftContractsSection from './components/NftContractsSection/NftContractsSection';
import SocialSection from './components/Social/SocialSection';

export interface GameEditionSectionProps {
  campaign?: Campaign;
  referralPoints?: ReferralPoints;
  loadingReferralPoints: boolean;
  nftMultiplier?: NftMultiplier;
  loadingNftMultiplier: boolean;
  addingDraft?: boolean;
  featuresData?: FeaturesData;
  featuresLoadingStates?: { feature: string; loading: boolean }[];
  socialMultiplier?: SocialMultiplier;
  loadingSocialMultiplier: boolean;
  savingReferralPoints: boolean;
  savingSocialMultiplier: boolean;
  loadingFeaturesData: boolean;
  selectedTab?: GamePageTabs;
  savingCampaign: boolean;
  savingAssets: boolean;
  savingNftMultiplier: boolean;
  dailyStreaks?: DailyStreak;
  savingDailyStreak: boolean;
  loadingDailyStreaks: boolean;
  nftContracts?: NftContract[];
  loadingNftContracts: boolean;
  addingNftContract: boolean;
  removingNftContract: boolean;
  blockchains?: string[];
  loadingBlockchains: boolean;
  handleContractRemove: (contract: NftContract) => void;
  handleNftContractSave: (contract: NftContractAdditionData) => void;
  handleSaveDailyStreaks: (dailyStreaks: DailyStreak) => void;
  onNftMultiplierSave: (nftMultiplier: NftMultiplier) => void;
  handleCampaignSave: (campaign: Campaign) => void;
  addNewDraft?: () => void;
  setSaveEnabled?: (enabled: boolean) => void;
  onFeatureActivationChange: (feature: string, checked: boolean) => void;
  handleReferralPointsSave: (referralPoints: ReferralPoints) => void;
  handleSocialMultiplierSave: (socialMultiplier: SocialMultiplier) => void;
  handelAssetsSave: (assets: CampaignAssetModel) => void;
  setSelectedTab?: (tab: GamePageTabs) => void;
}

const GameEditionSection: FunctionComponent<
  GameEditionSectionProps
> = props => {
  const {
    campaign,
    addingDraft,
    featuresData,
    featuresLoadingStates,
    loadingFeaturesData,
    selectedTab,
    referralPoints,
    loadingReferralPoints,
    savingReferralPoints,
    savingSocialMultiplier,
    socialMultiplier,
    loadingSocialMultiplier,
    nftMultiplier,
    loadingNftMultiplier,
    loadingDailyStreaks,
    dailyStreaks,
    savingDailyStreak,
    savingCampaign,
    savingAssets,
    savingNftMultiplier,
    nftContracts,
    loadingNftContracts,
    addingNftContract,
    removingNftContract,
    blockchains,
    loadingBlockchains,
    handleContractRemove,
    handleNftContractSave,
    onNftMultiplierSave,
    handleCampaignSave,
    setSelectedTab,
    handelAssetsSave,
    onFeatureActivationChange,
    handleReferralPointsSave,
    handleSocialMultiplierSave,
    handleSaveDailyStreaks,
    addNewDraft,
  } = props;

  const tabKeys = Array.from(tabsRegistry.keys()).filter(key => {
    const tab = tabsRegistry.get(key);
    if (tab?.requiredFeature) {
      const isRequiredFeatureAbsent = !featuresData?.available_features
        .map(x => x.feature)
        .includes(tab.requiredFeature);
      if (isRequiredFeatureAbsent) {
        return false;
      }
    }
    return (
      !tab?.featureFlag ||
      useFeatureFlagEnabled(tab.featureFlag) ||
      staffCheck()
    );
  });

  const loadingTabsRegistry = new Map<GamePageTabs, boolean[]>([
    [
      GamePageTabs.Features,
      [
        featuresLoadingStates?.some(x => x.loading) ?? false,
        loadingFeaturesData,
      ],
    ],
    [GamePageTabs.BasicInformation, [savingCampaign]],
    [GamePageTabs.ExtraData, [savingCampaign]],
    [GamePageTabs.Assets, [savingAssets]],
    [GamePageTabs.Socials, [savingCampaign]],
  ]);

  const isTabLoading = (tab: GamePageTabs) => {
    return loadingTabsRegistry.get(tab)?.some(x => x) ?? false;
  };

  const getContent = () => {
    if (!campaign) return;
    const dynamicHeight = 'calc(100vh - 11.063rem)';
    return (
      <Flex
        direction={'column'}
        w={'100%'}
        h={'100%'}
        align={'center'}
        justify={'center'}
      >
        <Tabs
          defaultValue={selectedTab ?? GamePageTabs.BasicInformation}
          w={'100%'}
          onChange={value => setSelectedTab?.(value as GamePageTabs)}
        >
          <Tabs.List>
            {tabKeys.map(value => (
              <Tabs.Tab
                disabled={isTabLoading(value)}
                key={value}
                value={value}
                leftSection={tabsRegistry.get(value)?.icon}
              >
                {getTabTitle(value, campaign, isTabLoading(value))}
              </Tabs.Tab>
            ))}
          </Tabs.List>
          <Tabs.Panel value={GamePageTabs.BasicInformation}>
            <ScrollAreaAutosize h={dynamicHeight}>
              <BasicInfoSection
                title={campaign?.title}
                subtitle={campaign?.subtitle}
                onSave={(title, subtitle) => {
                  const updatedCampaign = {
                    ...campaign,
                    title,
                    subtitle,
                  };
                  handleCampaignSave(updatedCampaign);
                }}
                savingCampaign={savingCampaign}
              />
            </ScrollAreaAutosize>
          </Tabs.Panel>
          <Tabs.Panel value={GamePageTabs.ExtraData}>
            <ScrollAreaAutosize h={dynamicHeight}>
              <ExtraDataSection
                campaign={campaign}
                savingCampaign={savingCampaign}
                onSave={(campaign: Campaign) => {
                  handleCampaignSave(campaign);
                }}
              />
            </ScrollAreaAutosize>
          </Tabs.Panel>
          <Tabs.Panel value={GamePageTabs.Assets}>
            <ScrollAreaAutosize h={dynamicHeight}>
              <CommonAssetsForm
                campaign={campaign}
                savingCampaignAssets={savingAssets}
                onSave={(assets: CampaignAssetModel) => {
                  handelAssetsSave(assets);
                }}
              />
            </ScrollAreaAutosize>
          </Tabs.Panel>
          <Tabs.Panel value={GamePageTabs.Socials}>
            <ScrollAreaAutosize h={dynamicHeight}>
              <SocialSection
                savingCampaign={savingCampaign}
                socials={campaign.extra_data.social}
                onSave={socials => {
                  const updatedCampaign = {
                    ...campaign,
                    extra_data: {
                      ...campaign.extra_data,
                      social: socials,
                    },
                  };
                  handleCampaignSave(updatedCampaign);
                }}
              />
            </ScrollAreaAutosize>
          </Tabs.Panel>
          <Tabs.Panel value={GamePageTabs.Features}>
            <ScrollAreaAutosize h={dynamicHeight}>
              <FeaturesConfiguration
                dailyStreak={dailyStreaks}
                savingDailyStreak={savingDailyStreak}
                onSaveDailyStreaks={handleSaveDailyStreaks}
                featureData={featuresData}
                featuresLoadingStates={featuresLoadingStates}
                onFeatureActivationChange={onFeatureActivationChange}
                referralPoints={referralPoints}
                referralPointsLoading={loadingReferralPoints}
                onReferralPointsSave={handleReferralPointsSave}
                savingReferralPoints={savingReferralPoints}
                socialMultiplier={socialMultiplier}
                loadingSocialMultiplier={loadingSocialMultiplier}
                onSocialMultiplierSave={handleSocialMultiplierSave}
                savingSocialMultiplier={savingSocialMultiplier}
                nftMultiplier={nftMultiplier}
                loadingNftMultiplier={loadingNftMultiplier}
                onNftMultiplierSave={onNftMultiplierSave}
                savingNftMultiplier={savingNftMultiplier}
                loadingDailyStreaks={loadingDailyStreaks}
              />
            </ScrollAreaAutosize>
          </Tabs.Panel>
          <Tabs.Panel value={GamePageTabs.NftContracts}>
            <ScrollAreaAutosize h={dynamicHeight}>
              <NftContractsSection
                nftContracts={nftContracts}
                loadingNftContracts={loadingNftContracts}
                addingContract={addingNftContract}
                handleNftContractSave={handleNftContractSave}
                handleContractRemove={handleContractRemove}
                removingNftContract={removingNftContract}
                blockchains={blockchains}
                loadingBlockchains={loadingBlockchains}
              />
            </ScrollAreaAutosize>
          </Tabs.Panel>
        </Tabs>
      </Flex>
    );
  };
  const getEmptyState = () => {
    return (
      <NoCampaignEmptyState
        addNewDraft={addNewDraft}
        addingDraft={addingDraft}
      />
    );
  };
  return (
    <Flex w={'100%'} h={'100%'}>
      {campaign ? getContent() : getEmptyState()}
    </Flex>
  );
};

export default GameEditionSection;

import { FaDiscord } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import { GiClick, GiHillConquest } from 'react-icons/gi';
import { GrAchievement } from 'react-icons/gr';
import { QuestIcons } from './quest';

export const iconRegistry = new Map<string, JSX.Element>([
  [
    QuestIcons.Discord,
    <FaDiscord style={{ minWidth: '2rem' }} size={'2rem'} />,
  ],
  [
    QuestIcons.Achievement,
    <GrAchievement style={{ minWidth: '2rem' }} size={'2rem'} />,
  ],
  [
    QuestIcons.DailyActiveTweet,
    <FaXTwitter style={{ minWidth: '2rem' }} size={'2rem'} />,
  ],
  [
    QuestIcons.Twitter,
    <FaXTwitter style={{ minWidth: '2rem' }} size={'2rem'} />,
  ],
  [
    QuestIcons.ClickLink,
    <GiClick style={{ minWidth: '2rem' }} size={'2rem'} />,
  ],
]);

export const getIcon = (icon?: string | null) => {
  if (!icon) {
    return <GiHillConquest style={{ minWidth: '2rem' }} size={'2rem'} />;
  }
  return (
    iconRegistry.get(icon) ?? (
      <GiHillConquest style={{ minWidth: '2rem' }} size={'2rem'} />
    )
  );
};

import {
  Button,
  Card,
  CloseButton,
  Flex,
  Image,
  Overlay,
  Paper,
} from '@mantine/core';
import { Dropzone, FileWithPath, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { FaEdit, FaPlusCircle } from 'react-icons/fa';
import './ImageUploader.scss';

export interface ImageUploaderProps {
  internalKey: string;
  actualImage: string | File;
  onImageChange: (image: File[]) => void;
  onRemoveButtonClicked?: () => void;
  imageShape?: 'rounded';
  expectedDimensions?: string;
  withCloseButton?: boolean;
  withRoundedBorders?: boolean;
  allowMultipleImages?: boolean;
  style?: React.CSSProperties;
}

const ImageUploader: FunctionComponent<ImageUploaderProps> = props => {
  const [isPlaceHolder] = useState(props.actualImage === 'noImage');
  const processImage = (image: string | File) => {
    if (image instanceof File) {
      return URL.createObjectURL(image);
    }
    return image;
  };
  const openRef = useRef<() => void>(null);
  const [image, setImage] = useState<string>(processImage(props.actualImage));

  useEffect(() => {
    const image = processImage(props.actualImage);
    setImage(image);
  }, [props.actualImage]);

  const handleUpload = async (files: FileWithPath[]) => {
    props.onImageChange(files);
  };

  const isClickableInput =
    isPlaceHolder || ['noImage', ''].includes(props.actualImage as string);

  const getBorder = () => {
    const borderStyle =
      ['removed', ''].includes(image) && !props.withCloseButton
        ? '1px solid red'
        : 'none';
    return borderStyle;
  };

  return (
    <Flex
      key={`flex-uploader-${props.internalKey}`}
      direction={'column'}
      gap={'0.2rem'}
      h={'100%'}
      style={props.style}
    >
      <Card
        key={`card-uploader-${props.internalKey}`}
        bd={getBorder()}
        h={'100%'}
        shadow="sm"
        radius="md"
        withBorder
        padding={isPlaceHolder ? 'xs' : '0.5rem'}
      >
        <Flex
          key={`flex-close-uploader-${props.internalKey}`}
          align={'center'}
          justify={'flex-end'}
        >
          {props.withCloseButton && (
            <CloseButton
              key={`close-uploader-${props.internalKey}`}
              size={'sm'}
              bg={'transparent'}
              onClick={() => {
                props.onRemoveButtonClicked?.();
              }}
            />
          )}
        </Flex>
        <Paper
          key={`paper-uploader-${props.internalKey}`}
          className={isClickableInput && isPlaceHolder ? 'ClickableImage' : ''}
        >
          <Image
            key={`image-uploader-${props.internalKey}`}
            h={'100%'}
            mah={'25rem'}
            src={image}
            fallbackSrc={`https://placehold.co/${props.expectedDimensions ?? '700x500?text=Upload+new'}`}
            fit="cover"
          />
          <Overlay
            key={`overlay-uploader-${props.internalKey}`}
            className="PlusIcon"
            color="#fff"
            backgroundOpacity={1}
            onClick={() => {
              if (isClickableInput) {
                openRef.current?.();
              }
            }}
          >
            <FaPlusCircle
              key={`plusCircle-uploader-${props.internalKey}`}
              size={'2rem'}
              color="var(--mantine-primary-color-6)"
            />
          </Overlay>
        </Paper>
        <Dropzone
          key={`dropzone-uploader-${props.internalKey}`}
          openRef={openRef}
          multiple={props.allowMultipleImages}
          accept={IMAGE_MIME_TYPE}
          onDrop={(files: FileWithPath[]) => handleUpload(files)}
        />
      </Card>
      <Flex
        key={`flex-noImage-uploader-${props.internalKey}`}
        align={'center'}
        justify={'end'}
      >
        {image !== 'noImage' && (
          <Button
            key={`edit-button-uploader-${props.internalKey}`}
            size="compact-xs"
            leftSection={<FaEdit cursor={'pointer'} />}
            onClick={() => {
              openRef.current?.();
            }}
          >
            Edit
          </Button>
        )}
      </Flex>
    </Flex>
  );
};

export default ImageUploader;

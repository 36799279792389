import {
  Button,
  Flex,
  Image,
  Loader,
  Modal,
  Switch,
  Text,
  Title,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { FunctionComponent } from 'react';
import { LocalImages } from '../../../assets/images';
import { CampaignOption } from '../../../data/campaign-option';

export interface UnPublishCampaignDialogProps {
  campaign: CampaignOption;
  onConfirmUnPublish?: () => void;
  unPublishing?: boolean;
}
const UnPublishCampaignDialog: FunctionComponent<
  UnPublishCampaignDialogProps
> = props => {
  const { campaign, onConfirmUnPublish, unPublishing } = props;
  const [opened, { open, close }] = useDisclosure();
  return (
    <>
      <Modal
        title={<Image w={'8rem'} src={LocalImages.logo} />}
        centered
        zIndex={300}
        opened={opened}
        onClose={close}
      >
        <Flex direction={'column'} gap={'1rem'}>
          <Title order={3}>Unpublishing a live campaign</Title>
          <Flex direction={'column'} gap={'0.5rem'}>
            <Text c={'gray'}>
              {`Are you sure you want to unpublish ${campaign?.title} campaign? Unpublishing will make the campaign unavailable to the public.`}
            </Text>
          </Flex>
          <Flex gap={'1rem'} w={'100%'} justify={'end'}>
            <Button onClick={close}>Cancel</Button>
            <Button
              color="red"
              onClick={() => {
                onConfirmUnPublish?.();
                close();
              }}
            >
              Confirm
            </Button>
          </Flex>
        </Flex>
      </Modal>
      {unPublishing ? (
        <Loader color="white" size={'xs'} />
      ) : (
        <Switch
          checked={campaign.published}
          onChange={open}
          size="sm"
          color="green"
          onLabel={
            <Text p={'0.1rem'} pt={'0.3rem'} size="xs" fw={500}>
              Live
            </Text>
          }
        />
      )}
    </>
  );
};

export default UnPublishCampaignDialog;

import {
  Button,
  Checkbox,
  Flex,
  Modal,
  NumberInput,
  ScrollAreaAutosize,
  Tooltip,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { FunctionComponent, useEffect, useState } from 'react';
import {
  CampaignAccount,
  CampaignAccountDetails,
  CampaignAccountOption,
} from '../../../data/campaign-account';

export interface AccountOptionsEditionProps {
  selectedCampaignId?: string;
  defaultValue?: CampaignAccountDetails;
  saving: boolean;
  onSave: (accounts: CampaignAccount[]) => void;
  loadingData?: boolean;
}

const AccountOptionsEdition: FunctionComponent<
  AccountOptionsEditionProps
> = props => {
  const { defaultValue, saving, onSave, selectedCampaignId, loadingData } =
    props;

  const [options, setOptions] = useState<CampaignAccountOption[]>([]);
  const [accounts, setCampaignAccount] = useState<CampaignAccount[]>([]);
  const [originalAccounts, setOriginalAccounts] = useState<CampaignAccount[]>(
    []
  );
  const [modalOpened, { open, close }] = useDisclosure(false);
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    if (!defaultValue) {
      return;
    }
    if (modalOpened) {
      setOptions(defaultValue.options);
      setCampaignAccount(defaultValue.accounts);
      setOriginalAccounts(defaultValue.accounts);
      setHasChanges(false);
    }
  }, [modalOpened, defaultValue]);

  useEffect(() => {
    const updatedOptions = options.map(option => ({
      ...option,
      active: accounts.some(
        accountOption => accountOption.accountName === option.accountName
      ),
    }));
    setOptions(updatedOptions);
  }, [accounts]);

  const handleSave = () => {
    onSave(accounts);
    close();
    setHasChanges(false);
  };

  const handleCheckboxChange = (index: number) => {
    if (!selectedCampaignId) {
      return;
    }
    const option = options[index];
    let updatedAccounts;
    if (accounts.some(x => x.accountName === option.accountName)) {
      updatedAccounts = accounts.filter(
        x => x.accountName !== option.accountName
      );
    } else {
      const originalAccount = originalAccounts.find(
        x => x.accountName === option.accountName
      );
      updatedAccounts = [
        ...accounts,
        {
          accountName: option.accountName,
          campaignId: selectedCampaignId,
          description: option.title,
          points: originalAccount ? originalAccount.points : option.points,
        },
      ];
    }
    setCampaignAccount(updatedAccounts);
    setHasChanges(true);
  };

  const getPoints = (optionName: string) => {
    if (accounts.some(x => x.accountName === optionName)) {
      return accounts.find(x => x.accountName === optionName)?.points;
    }
    return options.find(x => x.accountName === optionName)?.points;
  };

  const handleNumberChange = (value: number | string, name: string) => {
    const updatedAccounts = accounts.map(account => {
      if (account.accountName === name) {
        return {
          ...account,
          points: +value,
        };
      }
      return account;
    });
    setCampaignAccount(updatedAccounts);
    setHasChanges(true);
  };

  const getLabel = () => {
    if (
      !defaultValue ||
      !defaultValue.accounts ||
      defaultValue.accounts.length === 0
    ) {
      return 'No accounts configured';
    }
    return `${accounts.length} accounts configured`;
  };

  return (
    <>
      <Tooltip label={getLabel()}>
        <Button
          onClick={open}
          loading={saving || loadingData}
          disabled={!defaultValue}
        >
          Manage required accounts
        </Button>
      </Tooltip>
      <Modal
        zIndex={300}
        centered
        opened={modalOpened}
        onClose={close}
        title="Edit Account Options"
      >
        <Flex direction={'column'} gap={'1rem'}>
          <ScrollAreaAutosize mah={'30rem'}>
            <Flex direction={'column'} gap={'0.3rem'}>
              {options.map((option, index) => (
                <Flex direction={'column'} gap={'0.3rem'} key={index}>
                  <Checkbox
                    label={option.title}
                    checked={accounts.some(
                      x => x.accountName === option.accountName
                    )}
                    onChange={() => handleCheckboxChange(index)}
                  />
                  <NumberInput
                    value={getPoints(option.accountName)}
                    label="Points"
                    min={0}
                    disabled={
                      !accounts.some(x => x.accountName === option.accountName)
                    }
                    onChange={value =>
                      handleNumberChange(value, option.accountName)
                    }
                  />
                </Flex>
              ))}
            </Flex>
          </ScrollAreaAutosize>

          <Button onClick={handleSave} disabled={!hasChanges}>
            Save
          </Button>
        </Flex>
      </Modal>
    </>
  );
};

export default AccountOptionsEdition;
